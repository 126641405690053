import { Modal } from '../../atoms';
import { DownloadAppContent, DownloadAppContentMobile } from '../../molecules';

export default function DownloadAppPopup(props) {
  return (
    <>
      <Modal
        size="lg"
        content={
          <>
            <DownloadAppContent />
            <DownloadAppContentMobile {...props} />
          </>
        }
        className="custom-modal-download"
        {...props}
      />
    </>
  );
}
