// @TODO: all on surface variables will be used in dark mood

export const SURFACE = '#FFFFFF';
export const ON_SURFACE = '#000000';
export const ON_SURFACE_DISABLED = 'rgba(0, 0, 0, 0.38)';

export const PRIMARY = {
  50: '#E8F4F6',
  100: '#D0E9EB',
  200: '#9CDADE',
  300: '#68CCD2',
  400: '#34BEC5',
  500: '#00AEB6',
  600: '#00939A',
  700: '#00777C',
  800: '#005A5D',
  900: '#003D3F',
};

export const SECONDARY = {
  50: '#DDDEEC',
  100: '#BCBDD9',
  200: '#939EC3',
  300: '#6B7EAD',
  400: '#425F97',
  500: '#1A4081',
  600: '#16356E',
  700: '#122A5A',
  800: '#0D1E47',
  900: '#091334',
};

export const ON_SURFACE_MEDIUM = 'rgba(0, 0, 0, 0.6)';

export const BORDER = 'rgba(0, 0, 0, 0.12)';

export const DISABLE = `rgba(0, 0, 0, 0.26)`;

export const ON_SURFACE_HIGH = 'rgba(0, 0, 0, 0.87)';

export const BACKGROUND = '#FFFFFF';

export const ON_PRIMARY_BACKGROUND = '#FFFFFF';

export const BOX_SHADOW = 'rgba(0, 0, 0, 0.06)';

export const BOX_SHADOW_2 = 'rgba(0, 0, 0, 0.04)';

export const CARD_HOVER = 'rgba(0, 0, 0, 0.08)';

export const SUPPORT_1 = '#80217F';

export const SUPPORT_2 = '#ED4D41';

export const SUPPORT_3 = '#FF8034';

export const SUPPORT_4 = 'rgba(237, 77, 65, 0.22)';

export const BACKGROUND_SUPPORT = '#E8F4F6';
export const BACKGROUND_SUPPORT_2 = '#fdedeb';

export const ERROR = '#A51D13';

export const SUCCESS = '#00B283';

export const FACEBOOK = '#4267b2';

export const INSTAGRAM = '#d81b60';

export const WHATSAPP = '#25d366';

export const YOUTUBE = '#ff0000';

export const LINKEDIN = '#0077b5';

export const WARNING = '#FFCE00';

export const INFO = '#4D96FF';
export const ON_BACKGROUND = '#000000';
export const ON_SECONDARY_BACKGROUND = '#000000';
export const CIRCLE_BACKGROUND_1 = '#e5f7f2';
export const CIRCLE_BACKGROUND_2 = '#f2e8f2';
export const CIRCLE_BACKGROUND_3 = '#E8F4F6';
export const CIRCLE_BACKGROUND_4 = '#e7ebf2';
export const CIRCLE_BACKGROUND_5 = '#fff2ea';
export const CIRCLE_BACKGROUND_6 = '#d0e9eb';
export const ON_CIRCLE_BACKGROUND_1 = '#00b283';
export const ON_CIRCLE_BACKGROUND_2 = '#80217f';
export const ON_CIRCLE_BACKGROUND_3 = '#E8F4F6';
export const ON_CIRCLE_BACKGROUND_4 = '#1a4081';
export const ON_CIRCLE_BACKGROUND_5 = '#ff8034';
export const ON_CIRCLE_BACKGROUND_6 = '#00aeb6';

export const ON_PRIMARY_HIGH_TEXT = '#FFF'; // @TODO: CHANGE TO RGBA
export const ON_PRIMARY_MEDIUM_TEXT = 'rgba(255, 255, 255, 0.74)';
export const ON_PRIMARY_DISABLED_TEXT = 'rgba(255, 255, 255, 0.38)';
export const ON_PRIMARY_SHADOW = 'rgba(255, 255, 255, 0.4)';
export const DEFAULT_TEXT = 'rgba(0, 0, 0, 0.87)';
export const ICONS_COLOR = '#797979';
export const SECTION_BACKGROUND = '#e1f1f3';
export const SECTION_BORDER = 'rgba(0, 0, 0, 0.06)';
export const BACK_DROP = 'rgba(0, 0, 0, 0.95)';
export const LIGHT_GREEN_BACKGROUND = 'rgba(0, 174, 182, 0.04)';
export const LIGHT_GREEN_BACKGROUND_2 = 'rgba(0, 174, 182, 0.6)';
