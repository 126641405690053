import { useNavigate } from 'react-router-dom';
import { translate } from '../../../app/i18n';
import {
  AddToWatchList,
  LargeTimer,
  OldPriceWithDiscount,
  PostLabel,
  Price,
  Progress,
  Views,
} from '../../atoms';
import LargeTimerHoc from '../../higherOrderComponents/largeTimerHoc';
import { postConditionMapper } from '../../../app/helper/mappers';
import ShareAndBuyNow from './shareAndBuyNow';
import ProgressiveImageLoader from '../progressiveImageLoader/progressiveImageLoader';
import { postRoute } from '../../../app/helper/general';
import { useSelector } from 'react-redux';
import { debounce, some } from 'lodash';
import {
  useAddToWatchlist,
  useRemoveFromWatchlist,
  useWatchlistQuery,
} from '../../../hooks/usePostQuery';
import { cardStyles } from '../../../constants/general';
import { routeConstants } from '../../../constants';
import events from '../../../utils/events';

export default function PostCardHorizontalLarge({
  cardImageObj,
  cardInfoObj,
  postId,
  watchlistId,
  verticalText,
  shareLink,
}) {
  const { imageUrl, totalViews, condition, expiryDate } = cardImageObj;
  const { title, price, discount, availableQuantity, originalQuantity } =
    cardInfoObj;
  const Timer = LargeTimerHoc(LargeTimer);
  const navigateTo = useNavigate();
  const { data: watchList } = useWatchlistQuery();
  const { mutate: addToWatchlist, isLoading: addWatchlistLoading } =
    useAddToWatchlist(watchlistId);
  const { mutate: removeFromWatchlist, isLoading: removeWatchlistLoading } =
    useRemoveFromWatchlist(watchlistId);

  const { isAuthenticated } = useSelector((state) => state.auth);

  const toggleWatchList = () => {
    if (!some(watchList, { _id: watchlistId })) {
      addToWatchlist();
    } else {
      removeFromWatchlist();
    }
  };

  const handleWatchList = () => {
    if (isAuthenticated) {
      toggleWatchList();
    } else {
      navigateTo(routeConstants.REGISTRATION);
    }
  };

  const alreadyInWatchList = some(watchList, { _id: watchlistId });

  const debouncedHandleWatchList = debounce(handleWatchList, 500);

  const handleOnClick = () => {
    navigateTo(postRoute(cardInfoObj.title, postId));
    events.logPostSelect(postId, cardInfoObj);
  };
  return (
    <>
      <div key={postId} className="card-horizontal-lg">
        <div className={'card-horizontal-lg__left'}>
          <div className="card-horizontal-lg__vertical-text-mobile">
            <h2>{verticalText}</h2>
          </div>
          <figure
            className="card-horizontal-lg__figure"
            onClick={handleOnClick}
          >
            <div className="card-horizontal-lg__figure__vertical-text">
              <h2>{verticalText}</h2>
            </div>
            <div className="card-horizontal-lg__figure__header">
              <div className={'group'}>
                <PostLabel postCondition={postConditionMapper(condition)} />
                {totalViews && (
                  <div className="badge badge--card badge--dark badge--left">
                    <Views
                      numberOfViews={totalViews}
                      light={true}
                      fontSize={10}
                      iconSize={12}
                    />
                  </div>
                )}
              </div>
              <AddToWatchList
                onClick={isAuthenticated ? debouncedHandleWatchList : null}
                theme={alreadyInWatchList ? 'filled' : 'outlined'}
                className="watch-list"
                loading={addWatchlistLoading || removeWatchlistLoading}
              />
            </div>
            <ProgressiveImageLoader
              alt="day deal"
              image={imageUrl}
              routeUrl={handleOnClick}
              imageType={'image'}
            />
          </figure>
        </div>
        <div className="card-horizontal-lg__content">
          <div
            className="card-horizontal-lg__content__title"
            onClick={handleOnClick}
          >
            <h3>{title}</h3>
          </div>

          <div className="card-horizontal-lg__price-group">
            <Price
              price={price}
              discount={discount}
              priceClassName="card-horizontal-lg__price"
              currencyClassName="card-horizontal-lg__currency"
            />
            {discount.value && (
              <OldPriceWithDiscount
                price={discount.oldPrice}
                discount_unit={discount.unit}
                discount_value={discount.value}
                size="medium"
                themeColor="light"
                showOldPriceWithPercentage
                showOldPriceWithValue
              />
            )}
          </div>

          {expiryDate && (
            <div className="card-horizontal-lg__content__timer">
              <p className="card-horizontal-lg__content__timer__title">
                {translate('homePage.dayDeal.dontMissOutWillExpiresIn')}
              </p>
              <Timer
                {...{
                  expiryDate,
                  timerUnitClassName: 'card-horizontal-lg__content__timer__box',
                }}
              />
            </div>
          )}
          <div className="card-horizontal-lg__content__quantity">
            <Progress
              label={translate('homePage.dayDeal.qtyText')(availableQuantity)}
              {...{ availableQuantity, originalQuantity }}
              labelPosition="top"
              className="card-horizontal-lg__content__quantity__progress"
            />
          </div>
          <div className="share-and-watchlist">
            <ShareAndBuyNow {...{ shareLink }} />
          </div>
        </div>
      </div>
    </>
  );
}

PostCardHorizontalLarge.prototype.style = cardStyles.STYLE_DAY_DEAL;
