import { translate } from '../../../app/i18n';
import {
  OldPriceWithDiscount,
  PostBids,
  PostQuantity,
  PostTitle,
  Price,
  Share,
} from '../../atoms';

const PostCardInfoHorizontal = ({
  cardInfoObj,
  className,
  themeColor = 'light',
  handleShowPopUp,
}) => {
  return (
    <div className={`post-card-info-horizontal ${className}`}>
      <div className="post-card-info-horizontal__title">
        <PostTitle {...{ themeColor, title: cardInfoObj.title }} />
        <div className="post-card-info-horizontal__title__info">
          <div className="post-card-info-horizontal__title__info__quantityAndBids">
            {cardInfoObj.quantityText && (
              <PostQuantity
                {...{ themeColor, quantityText: cardInfoObj.quantityText }}
              />
            )}
            {cardInfoObj.biddingCount && (
              <PostBids
                {...{ bidsText: cardInfoObj.biddingCount, themeColor }}
              />
            )}
            {cardInfoObj.discount.value && (
              <OldPriceWithDiscount
                price={cardInfoObj.discount.oldPrice}
                discount_unit={cardInfoObj.discount.unit}
                discount_value={cardInfoObj.discount.value}
                size="sm"
                {...{ themeColor }}
              />
            )}
          </div>
          <Share {...{ handleShowPopUp }} />
        </div>
      </div>
      <div className="post-card-info-horizontal__price">
        <div className="post-card-info-horizontal__price__buyNow">
          <Price
            price={cardInfoObj.price}
            position="horizontal"
            label={translate('postCard.buyNow')}
            labelClassName={`post-card-info-horizontal__price__label ${
              themeColor !== 'light' && 'text-light-blue '
            }`}
            currencyClassName={`post-card-info-horizontal__price__currency ${
              themeColor === 'light' ? 'text-secondary' : 'text-white'
            }`}
            priceClassName={`post-card-info-horizontal__price__price ${
              themeColor === 'light' ? 'text-secondary' : 'text-white'
            }`}
          />
        </div>
      </div>
      <div className="post-card-info-horizontal__price__bidNow">
        <Price
          price={cardInfoObj.biddingPrice}
          position="horizontal"
          label={translate('postCard.bidNow')}
          labelClassName={`post-card-info-horizontal__price__label ${
            themeColor !== 'light' && 'text-primary'
          }`}
          currencyClassName={`post-card-info-horizontal__price__currency ${
            themeColor === 'light' ? 'text-primary' : 'text-white'
          }`}
          priceClassName={`post-card-info-horizontal__price__price ${
            themeColor === 'light' ? 'text-primary' : 'text-white'
          }`}
          {...{ discount: cardInfoObj.discount }}
        />
      </div>
    </div>
  );
};

export default PostCardInfoHorizontal;
