import Loader from 'react-loader-spinner';
import { translate } from '../../../../../../../app/i18n';
import { ButtonCancel } from '../../../../../../atoms';
import { Form } from 'react-bootstrap';
import { inputLanguageDetector } from '../../../../../../../app/helper/general';

export default function SearchFormMobile({
  onSearch,
  onChangeHandler,
  onClearSearchInput,
  mobileSearchInputRef,
  searchInput,
  searchDropDownContent,
  mobileContainerRef,
  SearchResultsGroup,
}) {
  return (
    <div className="search-mobile d-mobile-flex">
      <div ref={mobileContainerRef} className="search-mobile__searchbar">
        <div
          className={`search-mobile__input-group ${inputLanguageDetector(
            searchInput,
          )}`}
        >
          {/* search input */}
          <Form onSubmit={onSearch}>
            <input
              type="text"
              name={`keyword`}
              placeholder={translate('searchView.searchFieldPlaceHolder')}
              className="me-2"
              aria-label="text"
              onChange={onChangeHandler}
              value={searchInput}
              ref={mobileSearchInputRef}
            />
          </Form>
          {/* 
            loader icon if exists or
            clear icon if there is a text in search input
           */}
          {searchInput && (
            <>
              {searchDropDownContent.showLoading && searchInput ? (
                <Loader
                  type="TailSpin"
                  className="loader-icon"
                  width={24}
                  height={24}
                  color="#00b0b8"
                />
              ) : (
                <ButtonCancel handleOnClick={onClearSearchInput} size="small" />
              )}
            </>
          )}
        </div>
        {SearchResultsGroup}
      </div>
    </div>
  );
}
