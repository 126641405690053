import styled from 'styled-components';
import { FS_14, FS_24 } from '../../../app/theme/typography';
import { DISABLE, ON_SURFACE, PRIMARY } from '../../../app/theme/colors';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  &[disabled],
  &:disabled {
    background-color: transparent !important;
  }

  span {
    font-size: ${FS_14};
    font-weight: 600;
    color: ${(props) => (props.disabled ? DISABLE : PRIMARY['500'])};
  }

  i {
    font-size: ${FS_24};
    color: ${(props) => (props.disabled ? DISABLE : ON_SURFACE)};
  }
`;
