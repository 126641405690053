import { translate } from '../../../app/i18n';

const PostLabel = ({ postCondition }) => {
  //@TODO: should not use dynamic data from translations file.
  return (
    <div className="post-label">
      <div className={'post-label__condition-wrapper label-' + postCondition}>
        <small className=" post-label__condition-wrapper__post-condition">
          {translate(`productConditions.${postCondition}`)}
        </small>
      </div>
    </div>
  );
};
export default PostLabel;
