import { useSelector } from 'react-redux';
import { PopoverWrapper } from '../../templates';
import { translate } from '../../../app/i18n';
import { getJsonConfig } from '../../../app/helper/firebaseConfigs';
import { useEffect, useState } from 'react';
import { IconAssets } from '../../../assets/assetProvider';
export default function FlagsDropdown({
  onClick,
  open,
  setOpen,
  register,
  value,
}) {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [countries, setCountries] = useState([]);

  const getCountries = () => {
    setCountries(getJsonConfig('country_codes')?.countries);
  };

  useEffect(() => {
    getCountries();
  }, []);

  const selected = countries.filter(
    (country) => country.countryCallCode === value,
  )[0] ||
    countries[0] || {
      countryCallCode: '+20',
      countryISOCode: 'EG',
      countryFlag: '',
    };

  return (
    <div className="flagsDropdown-container">
      <div>
        {selected.countryCallCode ? (
          <div className="flagsDropdown-container_wrapper">
            <div className="code-wrapper">
              <img
                src={selected.countryFlag}
                className="code-wrapper_img"
                alt={selected.countryISOCode}
                onError={getCountries}
              />
              <img className="d-web" src={IconAssets.arrowDropDownIcon} alt={'arrow'} />
            </div>
            <p>{selected?.countryCallCode}</p>{' '}
          </div>
        ) : (
          <div className="d-flex">
            <img className="d-web" src={IconAssets.arrowDropDownIcon} alt='arrow'/>
            <span>{translate('phoneNumberComponent.selectCode')}</span>
          </div>
        )}
      </div>
      {open && (
        <PopoverWrapper show={open} setShow={setOpen} color={'light'}>
          {countries?.map((country, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  register.onChange({
                    name: register.name,
                    value: country.countryCallCode,
                    target: {
                      name: register.name,
                      value: country.countryCallCode,
                    },
                  });
                  setOpen(false);
                  if (onClick) {
                    onClick(country?.countryISOCode);
                  }
                }}
                className="menu-wrapper"
              >
                <img
                  src={country.countryFlag}
                  alt={country.countryISOCode}
                  className="menu-wrapper_img"
                />

                <span className="menu-wrapper_code">
                  {country.countryCallCode}
                </span>
                <span className="menu-wrapper_country-name">
                  {country.countryName[websiteLanguage]}
                </span>
              </div>
            );
          })}
        </PopoverWrapper>
      )}
    </div>
  );
}
