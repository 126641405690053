import config from '../../../config';
import { translate } from '../../../app/i18n';
import { Button } from '../../atoms';
import { ImageAssets } from '../../../assets/assetProvider';
import { useSelector } from 'react-redux';

export default function DownloadAppContentMobile({ onHide }) {
  const { websiteLanguage } = useSelector((state) => state.user);
  return (
    <div className="download-app-mobile">
      <figure className="download-app-mobile__img">
        <img
          loading="lazy"
          src={ImageAssets.downloadPopUpIllustration[websiteLanguage]}
          alt="mobile"
        />
      </figure>
      <div className="download-app-mobile__content">
        <h2 className="title">{translate('downloadPopup.content')}</h2>
        <div className="download-app-mobile__content__buttons">
          <Button
            size="small"
            color="primary"
            fullWidth
            onClick={() => window.open(config.MAZADAT_APP_REDIRECTION)}
            ariaLabel="download"
          >
            {translate('downloadPopup.downloadAppNow')}
          </Button>
          <div>
            <button
              onClick={onHide}
              className="download-app-mobile__close  button-transparent"
            >
              {translate('downloadPopup.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
