import {
  OldPriceWithDiscount,
  PostBids,
  PostQuantity,
  PostTitle,
} from '../../atoms';

const PostCardTitleWithInfo = ({
  title,
  quantityText,
  discount,
  cardClass,
  biddingCount,
  themeColor,
}) => {
  return (
    <div
      className={`post-card-content post-card-content--${themeColor}   ${cardClass}-card-title-info'
      `}
    >
      <div className={`post-card-content__title-container`}>
        <PostTitle {...{ themeColor, title }} />
      </div>
      <div className="post-card-content__availability">
        {quantityText && <PostQuantity {...{ themeColor, quantityText }} />}
        {biddingCount && (
          <PostBids {...{ bidsText: biddingCount, themeColor }} />
        )}
        {!(Object.keys(discount).length === 0) && (
          <OldPriceWithDiscount
            price={discount.oldPrice}
            discount_unit={discount.unit}
            discount_value={discount.value}
            size="sm"
            {...{ themeColor }}
          />
        )}
      </div>
    </div>
  );
};

export default PostCardTitleWithInfo;
