import { translate } from '../../app/i18n';
import services from '../../services';
import {
  anonymousUserFailed,
  anonymousUserSuccess,
  authSuccess,
  destroySession,
  fingerprint,
  loading,
  loginFailed,
  loginSuccess,
  logoutFailed,
} from '../slices/authSlice';
import { snackboxOpen } from './snackboxAction';
import events from '../../utils/events';
import storageService from '../../services/StorageService';
import { fetchUserData } from './userAction';

export const setFingerprint = (uuid) => async (dispatch) => {
  dispatch(fingerprint({ uuid }));
};

export const login = (phone, password) => async (dispatch) => {
  try {
    dispatch(loading());
    const data = await services.authService.login(phone, password);
    dispatch(fetchUserData());
    dispatch(loginSuccess(data));
    dispatch(
      snackboxOpen(
        translate('userFeedback.loginPage.loginSuccessful'),
        'success',
      ),
    );
    events.logLogin();
  } catch ({ message }) {
    dispatch(snackboxOpen(message, 'failure'));
    dispatch(loginFailed({ error: message }));
  }
};

export const resendOtp = (phone, service) => async (dispatch) => {
  try {
    await services.authService.resendPasswordOtp(phone, service);
  } catch ({ message }) {
    dispatch(snackboxOpen(message, 'failure'));
  }
};

export const checkAuth = () => async (dispatch) => {
  try {
    await services.authService.checkAuth();
    dispatch(authSuccess(storageService.get('user')));
  } catch ({ message }) {
    services.authService.removeLocalUser();
    dispatch(destroySession());
  }
};

export const logout = (token) => async (dispatch) => {
  try {
    await Promise.all([
      services.authService.logout(token),
      services.authService.removeLocalUser(),
    ]);
    dispatch(destroySession());
    dispatch(
      snackboxOpen(
        translate('userFeedback.loginPage.logoutSuccessfully'),
        'success',
      ),
    );
  } catch (error) {
    dispatch(snackboxOpen(error, 'failure'));
    dispatch(logoutFailed({ error: error }));
  }
};

export const anonymousUser = (country) => async (dispatch) => {
  try {
    await services.authService.anonymousUser(country);
    dispatch(anonymousUserSuccess());
  } catch (error) {
    dispatch(anonymousUserFailed({ error: error }));
  }
};
