import PropTypes from 'prop-types';

export default function Progressbar({
  availableQuantity,
  originalQuantity,
  label,
  labelPosition,
  className,
}) {
  return (
    <div className={`progressbar ${className && className}`}>
      {label && (
        <label className={`progressbar__${labelPosition}`}>{label}</label>
      )}
      <progress value={availableQuantity} max={originalQuantity}>
        {(availableQuantity / originalQuantity) * 100}
      </progress>
    </div>
  );
}

Progressbar.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelPosition: PropTypes.oneOf(['inside', 'top']),
};
Progressbar.defaultProps = {
  labelPosition: 'top',
};
