import { API } from '../constants';
import config from '../config';
import { PaginationParams } from '../constants/general';

export default function PostService(httpClient) {
  const getAllPosts = (queryObject) => {
    return httpClient.get(API.GET_POSTS, queryObject);
  };

  const getAllPromotionOptions = () => {
    return httpClient.get(API.GET_ALL_PROMOTION_OPTIONS);
  };

  const fetchPostFilters = () => {
    return httpClient.get(API.GET_POST_FILTERS);
  };

  const putPostOffline = (body = {}, id) => {
    return httpClient.put(`${API.PUT_POST_OFFLINE}${id}`, body);
  };

  const putPostArchive = (body = {}, id) => {
    return httpClient.put(`${API.PUT_POST_ARCHIVE}${id}`, body);
  };

  const putPostRepost = (body, id) => {
    return httpClient.put(`${API.PUT_POST_REPOST}${id}`, body);
  };

  const putPostRestock = (body, id) => {
    return httpClient.put(`${API.PUT_POST_RESTOCK}${id}`, body);
  };

  const putPostPromotion = (body, id) => {
    return httpClient.put(`${API.PUT_POST_PROMOTION}${id}`, body);
  };

  const putPostDiscount = (body, id) => {
    return httpClient.post(`${API.PUT_POST_DISCOUNT}${id}`, body);
  };

  const sharePost = (id) => {
    return httpClient.put(API.PUT_SHARE_POST, {
      postId: id,
    });
  };

  const clonePost = (id, location) => {
    return httpClient.post(`${API.CLONE_POST}${id}`, location);
  };

  const fetchPostDetails = (id) => {
    return httpClient.get(API.GET_POST_DETAILS, id);
  };

  const fetchPostQuestions = (id) => {
    return httpClient.get(`${API.GET_POST_QUESTIONS}${id}`);
  };

  const fetchBiddingHistory = (id) => {
    return httpClient.get(API.GET_BIDDING_HISTORY, id);
  };

  const fetchBuyerHistory = (id) => {
    return httpClient.get(`${API.GET_BUYER_HISTORY}`, id);
  };

  const fetchSellerReviews = (id, options) => {
    return httpClient.get(`${API.GET_SELLER_REVIEW}${id}`, options);
  };

  const fetchNumberOfPosts = (id) =>
    httpClient.get(`${API.GET_NUMBER_OF_SELLER_POSTS}${id}`);
  // @TODO should become dynamic and removed from here
  const fetchFeaturedPosts = () => httpClient.get(API.GET_FEATURED_POST_CARDS);
  const fetchDayDealPosts = () => httpClient.get(API.GET_DAY_DEAL_POST_CARDS);
  const getAreaDistance = (id) => httpClient.get(API.GET_AREA_DISTANCE, id);

  const getPostByCategory = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, _path, { parentCategoryId, page, limit, sort }, fields] =
      queryKey;
    return httpClient.post(
      `${
        API.POST_BY_CATEGORY
      }?parentCategoryId=${parentCategoryId}&page=${page}&limit=${limit}${
        sort ? '&sort=' + sort : ''
      }`,
      { fields: fields || {} },
    );
  };

  const searchByKeyword = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, kw, { page, limit, sort }, fields] = queryKey;
    return httpClient.post(
      `${API.POST_BY_KEYWORD}?kw=${encodeURIComponent(
        kw,
      )}&page=${page}&limit=${limit}${sort ? '&sort=' + sort : ''}`,
      { fields: fields || {} },
    );
  };

  const getPostFilterFieldByCategory = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { categoryId }, fields] = queryKey;
    return httpClient.post(
      `${API.POST_FILTER_FIELD_BY_CATEGORY}?parentCategoryId=${categoryId}`,
      { fields: fields || {} },
    );
  };

  const searchFilterFieldByKeyword = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, kw, fields] = queryKey;
    return httpClient.post(
      `${API.POST_FILTER_FIELD_BY_KEYWORD}?kw=${encodeURIComponent(kw)}`,
      {
        fields: fields || {},
      },
    );
  };

  const getDynamicSection = () => {
    return httpClient.get(API.GET_HOME_POSTS);
  };

  const getSectionPost = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, _path, { url }] = queryKey;
    return httpClient.get(config.API_URL + url);
  };

  const getPostsBySectionKey = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, _path, { url, page, limit, sort }] = queryKey;
    // TODO: Limit and Page will be fixed once pagination is available on the endpoint
    const params = { page: page, limit: limit };
    if (sort) {
      params.sort = sort;
    }
    return httpClient.get(config.API_URL + url, params);
  };

  const fetchSimilarPost = (body = {}) => {
    return httpClient.get(API.GET_SIMILAR_POST, body);
  };

  const getCategoryFeaturedPosts = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, _path, { parentCategoryId }] = queryKey;
    return httpClient.get(API.GET_CATEGORY_FEATURED_POST, {
      parentCategoryId,
      ...PaginationParams,
    });
  };

  const getSearchFeaturedPosts = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, _path, { kw }] = queryKey;
    return httpClient.get(API.GET_SEARCH_FEATURED_POST, {
      kw,
      ...PaginationParams,
    });
  };

  const addToWatchList = (id) => {
    return httpClient.put(`${API.ADD_TO_WATCHLIST}/${id}`);
  };

  const removeFromWatchList = (id) => {
    return httpClient.put(`${API.REMOVE_FROM_WATCHLIST}/${id}`);
  };

  const getWatchList = () => {
    return httpClient.get(`${API.GET_WATCHLIST}`);
  };

  const getShoppingCartCount = () => {
    return httpClient.get(`${API.GET_SHOPPING_CART_COUNT}`);
  };

  const getNotificationCount = () => {
    return httpClient.get(`${API.GET_NOTIFICATION_COUNT}`);
  };

  const getActions = () => {
    return httpClient.get(`${API.GET_ACTIONS}`);
  };

  return {
    getCategoryFeaturedPosts,
    getSearchFeaturedPosts,
    getAllPosts,
    fetchPostFilters,
    putPostOffline,
    putPostArchive,
    putPostRepost,
    putPostRestock,
    putPostPromotion,
    getAllPromotionOptions,
    putPostDiscount,
    sharePost,
    clonePost,
    fetchPostDetails,
    fetchPostQuestions,
    fetchBuyerHistory,
    fetchSellerReviews,
    fetchNumberOfPosts,
    fetchBiddingHistory,
    fetchFeaturedPosts,
    fetchDayDealPosts,
    getAreaDistance,
    getPostByCategory,
    searchByKeyword,
    getPostFilterFieldByCategory,
    searchFilterFieldByKeyword,
    getPostsBySectionKey,
    getDynamicSection,
    getSectionPost,
    fetchSimilarPost,
    addToWatchList,
    removeFromWatchList,
    getWatchList,
    getShoppingCartCount,
    getNotificationCount,
    getActions,
  };
}
