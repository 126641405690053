import { translate } from '../../../../../../app/i18n';

const Mazadat = ({ MazadatLinks }) => {
  return (
    <div className="mazadat-wrapper">
      <h5>{translate('footer.mazadat')}</h5>
      <MazadatLinks />
    </div>
  );
}

export default Mazadat;
