import { translate } from '../../../app/i18n';

export default function DontRecieveCodeCounter({
  counter,
  handleResendCode,
  isFormDisabled,
}) {
  return (
    <div className="counter">
      <p className="counter__text">{translate('otpForm.notReceiveCode')}</p>
      {counter !== 0 ? (
        <p className="counter__resend-container">
          <span className="counter__resend">
            {translate('otpForm.resendIn')}
          </span>{' '}
          <span className="counter__number">{counter}</span>s
        </p>
      ) : (
        <button
          className={`counter__button ${
            isFormDisabled === true && 'disabled'
          } button-hyperlink button-hyperlink--`}
          onClick={handleResendCode}
        >
          {translate('otpForm.resendButton')}
        </button>
      )}
    </div>
  );
}
