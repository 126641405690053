import { Modal } from 'react-bootstrap';

export default function CustomModal({ title, content, ...props }) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title && <h3>{title}</h3>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-transparent">{content}</Modal.Body>
    </Modal>
  );
}
