import { useState } from 'react';
import { translate } from '../../../app/i18n';
import {
  ItemsWrapper,
  ItemWrapper,
  LinksContainer,
} from './linksWrapper.styled';

export default function LinksWrapper({ data }) {
  const [active, setActive] = useState();

  return (
    <LinksContainer>
      <p>{translate('legalPages.topics')}</p>
      <ItemsWrapper>
        {data.map((link, index) => {
          return (
            <ItemWrapper
              key={index}
              color={active === link.path ? 'active' : ''}
              className="text-truncate"
            >
              <a  aria-label={link.title} href={link.path} onClick={() => setActive(link.path)}>
                <span>
                  {index + 1}. {link.title}
                </span>
              </a>
            </ItemWrapper>
          );
        })}
      </ItemsWrapper>
    </LinksContainer>
  );
}
