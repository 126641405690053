import { useState } from 'react';
import { postRoute } from '../../../app/helper/general';
import { useNavigate } from 'react-router-dom';
import {
  PostCardImageHorizontal,
  PostCardInfoHorizontal,
} from '../../molecules';
import { SharePopup } from '../../templates';
import { translate } from '../../../app/i18n';
import { cardStyles } from '../../../constants/general';
import events from '../../../utils/events';

function PostCardHorizontal({
  cardImageObj,
  cardInfoObj,
  postId,
  closeOverlay,
  themeColor,
  shareLink,
  cardClassName,
  watchlistId,
}) {
  const navigateTo = useNavigate();
  const handleOnClick = (e) => {
    if (!e.target.classList.contains('icon-share')) {
      navigateTo(postRoute(cardInfoObj.title, postId));
      events.logPostSelect(postId, cardInfoObj);
      if (closeOverlay) closeOverlay();
    }
  };
  const [showSharePopup, setShowSharePopup] = useState(false);
  const handleShowPopUp = () => {
    setShowSharePopup(true);
  };
  const handleClosePopUp = () => setShowSharePopup(false);
  return (
    <>
      <div
        className={`post-card-horizontal post-card-horizontal--${themeColor} ${cardClassName}`}
        onClick={(e) => handleOnClick(e)}
        key={postId}
      >
        <PostCardImageHorizontal
          {...{
            cardImageObj,
            className: 'post-card-horizontal__figure',
            watchlistId,
          }}
        />
        <PostCardInfoHorizontal
          {...{
            cardInfoObj,
            className: 'post-card-horizontal__description',
            handleShowPopUp,
          }}
        />
      </div>
      <SharePopup
        link={shareLink}
        show={showSharePopup}
        onHide={handleClosePopUp}
        title={translate('sharePopup.shareThisPost')}
      />
    </>
  );
}

PostCardHorizontal.prototype.style = cardStyles.STYLE_01;

PostCardHorizontal.defaultProps = {
  themeColor: 'light',
};

export default PostCardHorizontal;
