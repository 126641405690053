import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function useQuery() {
  /**
   * fetch the query params from url
   * this can be only used inside BrowserRouter
   * - to use it :
   * const query = useQuery();
   * const param = query.get('param-name');
   **/
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
