import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, CustomSwitch, Modal } from '../../atoms';
import { Info, PhoneInput, TextField } from '../../molecules';
import services from '../../../services';
import Loader from 'react-loader-spinner';
import OtpForm from '../otpForm/otpForm';
import { AuthWrapper, DiscoverMore, UserNameChickList } from '../../templates';
import {
  formatPhoneNumber,
  passwordStrengthBar,
} from '../../../app/helper/custom-validations';
import { routeConstants } from '../../../constants';
import { translate } from '../../../app/i18n';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../hooks/useQuery';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import config from '../../../config';
import { useForm } from 'react-hook-form';
import { createSchema } from '../../../app/helper/yup-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackboxOpen } from '../../../redux/actions/snackboxAction';
import { ImageAssets } from '../../../assets/assetProvider';

export default function RegistrationForm() {
  const query = useQuery();
  const token = query.get('token');
  const captchaRef = useRef(null);
  const dispatch = useDispatch();
  const formData = services.storageService.get('registerStepOne');
  const [passwordBarColor, setPasswordBarColor] = useState(
    passwordStrengthBar(''),
  );
  const [passwordGuide, setPasswordGuide] = useState(false);
  const [isVerfied, setVerfied] = useState(false);
  const [open, setOpen] = useState(false);
  const [validUserName, setValidUserName] = useState(false);
  const [RecaptchaVerified, setRecaptchaVerified] = useState(false);
  const [showUserNameCheckList, setUserNameCheckList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInvitationCodeTooltip, setInvitationCodeTooltip] = useState(false);
  const [showRegisteredAsBisinessTooltip, setRegisteredAsBisinessTooltip] =
    useState(false);
  const width = useScreenWidth();

  const registerSchemer = createSchema(
    'username',
    'notsearch_password',
    'phone',
  );
  const {
    register,
    watch,
    formState: { isValid, errors, isSubmitting },
    setError,
    setValue,
    handleSubmit,
    reset,
    getFieldState,
  } = useForm({
    resolver: yupResolver(registerSchemer),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      username: '',
      notsearch_password: '',
      markStore: false,
      checkInvitation: !!token,
      phone: '',
      code: '+20',
      country: 'EG',
      invitationToken: token,
    },
    values:
      formData && formData.form
        ? formData.form
        : {
            username: '',
            notsearch_password: '',
            markStore: false,
            checkInvitation: !!token,
            phone: '',
            code: '+20',
            country: 'EG',
            invitationToken: token,
          },
  });

  const phoneState = getFieldState('phone');
  const values = watch();

  useEffect(() => {
    setVerfied(false);
  }, [values.invitationToken, values.checkInvitation]);

  register('username', {
    onChange: () => {
      setUserNameCheckList(true);
    },
    onBlur: () => {
      setUserNameCheckList(false);
    },
  });

  const onHandleScroll = () => {
    window.scrollTo(0, 0);
  };

  const getUniqueUserNameDelay = (value) => {
    setIsLoading(true);
    setValidUserName(false);
    services.registrationService
      .getUniqueUserName(value)
      .then(() => {
        setValidUserName(true);
      })
      .catch(({ message }) => {
        setValidUserName(false);
        setError('username', { message });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setPasswordBarColor(passwordStrengthBar(values.notsearch_password));
  }, [values.notsearch_password]);

  const handleSwitchInvitationCode = (checked) => {
    setValue('checkInvitation', checked);
  };
  const handleRegisteredBusiness = (checked) => {
    setValue('markStore', checked);
  };
  const onVerifyRecaptchaHandler = async () => {
    const recaptchaToken = captchaRef.current?.getValue();
    try {
      const response = await services.verifyRecaptchaTokenService.verifyToken(
        recaptchaToken,
      );
      setRecaptchaVerified(response?.isHuman);
    } catch (error) {
      dispatch(
        snackboxOpen(
          translate('userFeedback.registrationForm.recaptchaError'),
          'failure',
        ),
      );
    }
  };
  const onRecaptchaExpired = () => {
    setRecaptchaVerified(false);
  };
  const isFormDisabled =
    !isValid ||
    isLoading ||
    !RecaptchaVerified ||
    !validUserName ||
    isSubmitting;

  const passwordText = () => {
    if (passwordBarColor.firstBarBackground === 'bg-orange') {
      return translate('passwordStrength.averagePassword');
    }
    if (passwordBarColor.firstBarBackground === 'bg-green') {
      return translate('passwordStrength.greatPassword');
    }
    return translate('passwordStrength.weakPassword');
  };

  const RegistrationFooter = () => (
    <div className="registration-form__footer">
      <div className="already-account">
        <p>
          {translate('registrationForm.alreadyAccount')}{' '}
          <span>
            <Link to={routeConstants.LOGIN}>
              {translate('registrationForm.signIn')}
            </Link>
          </span>
        </p>
      </div>
      <p>
        <span className="by-contine-text">
          {translate('registrationForm.byContinuing')(
            width,
            config.MOBILE_SCREEN,
          )}
        </span>
        <br />
        <Link
          className="text-primary policy-terms-mobile"
          onClick={onHandleScroll}
          to={routeConstants.PRIVACY_POLICY}
        >
          {translate('registrationForm.privacyPolicy')}
        </Link>
        <span>
          {translate('registrationForm.charAnd')} <span></span>
        </span>

        <Link
          className="text-primary policy-terms-mobile"
          onClick={onHandleScroll}
          to={routeConstants.LEGAL_TERMS}
        >
          {translate('registrationForm.termsOfService')(
            width,
            config.MOBILE_SCREEN,
          )}
        </Link>
      </p>
    </div>
  );

  const SubmitButton = () => (
    <div className="submit-button">
      <Button
        type="submit"
        fullWidth
        className="text-white"
        color="primary"
        disabled={isFormDisabled}
        ariaLabel="submit"
      >
        {isSubmitting ? (
          <Loader
            type="TailSpin"
            className="loader-icon"
            width={20}
            height={20}
            color="#1A4083"
          />
        ) : (
          translate('registrationForm.joinUs')
        )}
      </Button>
    </div>
  );

  const handleSubmitForm = async (data) => {
    if (!isFormDisabled) {
      if (!isVerfied) {
        return services.registrationService
          .postValidateRegistrationAndSendOtp({
            service: 'primary',
            userObj: {
              username: data.username,
              phone: {
                number: formatPhoneNumber(data.code + data.phone, data.country),
              },
              password: data.notsearch_password,
              invitationToken: data.checkInvitation
                ? data.invitationToken
                : undefined,
              markStore: data.markStore,
            },
          })
          .then(() => {
            setVerfied(true);
            setOpen(true);
          })
          .catch(({ message }) => {
            dispatch(snackboxOpen(message, 'failure'));
          });
      } else {
        setOpen(true);
      }
    }
    return Promise.resolve();
  };

  const InvitationCodeLabel = () => (
    <span className="registration-discover-more registration-discover-more--invitation">
      <span className="registration-discover-more__label">
        {translate('registrationForm.invitationCode')}
      </span>
      <DiscoverMore
        show={showInvitationCodeTooltip}
        setShow={setInvitationCodeTooltip}
        className="registration-discover-more__content"
      >
        {translate('registrationForm.invitationCodeTooltip')}
      </DiscoverMore>
    </span>
  );
  const RegisteredAdBusinessLabel = () => (
    <span className="registration-discover-more registration-discover-more--registered">
      <span className="registration-discover-more__label">
        {translate('registrationForm.registeredBusiness')}
      </span>
      <DiscoverMore
        show={showRegisteredAsBisinessTooltip}
        setShow={setRegisteredAsBisinessTooltip}
        className="registration-discover-more__content"
      >
        {' '}
        {translate('registrationForm.businessTooltip')}
      </DiscoverMore>
    </span>
  );

  useEffect(() => {
    let checkUniqueUserName;
    if (values.username.length > 0 && !errors.username) {
      checkUniqueUserName = setTimeout(() => {
        getUniqueUserNameDelay(values.username);
      }, 1000);
    } else {
      setValidUserName(false);
    }
    return () => clearTimeout(checkUniqueUserName);
  }, [values.username, errors.username]);

  return (
    <AuthWrapper
      src={ImageAssets.registrationIllustration}
      className={`registration-wrapper`}
      mobileSrc={ImageAssets.registrationMobileIllustration}
    >
      <div className="registration-form">
        <div className="registration-form__header">
          <Info
            title={translate('registrationForm.title')(
              width,
              config.MOBILE_SCREEN,
            )}
          />
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(handleSubmitForm)}>
          <PhoneInput
            phoneValue={values.phone}
            codeValue={values.code}
            phoneError={errors.phone}
            codeError={errors.code}
            register={register}
            isValid={!phoneState.error && phoneState.isDirty}
            onClick={(countryCode) => setValue('country', countryCode)}
          />
          <div className="d-flex p-relative">
            <TextField
              type="text"
              title={translate('registrationForm.username')}
              name="username"
              autoCapitalize="off"
              autoComplete="off"
              placeholder={translate('registrationForm.enterUsername')}
              value={values.username}
              register={register}
              endButton={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    className="loader-icon"
                    width={20}
                    height={20}
                    color="#00b0b8"
                    secondaryColor="#123f87"
                  />
                ) : validUserName ? (
                  <span className="icon-Verified  endLabel-icon text-primary"></span>
                ) : (
                  <span
                    className={`question-mark icon-mark ${
                      errors.username
                        ? 'question-mark--error'
                        : showUserNameCheckList && 'question-mark--active'
                    }`}
                  ></span>
                )
              }
              handleEndButton={() => setUserNameCheckList((prev) => !prev)}
              error={errors['username']}
            />
            {showUserNameCheckList && (
              <UserNameChickList name={values.username} />
            )}
          </div>
          <div className="registration-form_password-wrapper">
            <TextField
              title={translate('registrationForm.password')}
              name="notsearch_password"
              type="password"
              autoComplete="new-password"
              placeholder={translate('registrationForm.passwordCharacters')}
              value={values.notsearch_password}
              register={register}
              error={errors.notsearch_password}
              barColor={passwordBarColor}
            />
            {values.notsearch_password && (
              <DiscoverMore
                show={passwordGuide}
                setShow={setPasswordGuide}
                className={'registration-discover-more__content'}
              >
                {passwordText()}
              </DiscoverMore>
            )}
          </div>
          <CustomSwitch
            name="verification-code"
            onChange={handleSwitchInvitationCode}
            isChecked={values.checkInvitation}
            label={<InvitationCodeLabel />}
          />
          {values.checkInvitation && (
            <TextField
              type="text"
              name="invitationToken"
              placeholder={translate(
                'registrationForm.invitationCodePlaceHolder',
              )}
              value={values.invitationToken}
              register={register}
              endLabel={translate('textFieldComponent.optionalLabel')}
            />
          )}
          <CustomSwitch
            name="registered-business"
            onChange={handleRegisteredBusiness}
            isChecked={values.markStore}
            label={<RegisteredAdBusinessLabel />}
          />
          <div className="recaptcha-reg">
            <ReCAPTCHA
              sitekey={
                // eslint-disable-next-line
                process.env.REACT_APP_RECAPTCHA_SITE_KEY
              }
              ref={captchaRef}
              onChange={onVerifyRecaptchaHandler}
              onExpired={onRecaptchaExpired}
            />
          </div>
          <SubmitButton />
          <Modal
            show={open}
            onHide={() => setOpen(false)}
            content={
              <OtpForm
                mobile={formatPhoneNumber(
                  values.code + values.phone,
                  values.country,
                )}
                setOpen={setOpen}
                form={values}
                resetForm={reset}
                ref={captchaRef}
                {...{ setVerfied }}
              />
            }
            className={'custom-modal-otp'}
          />
        </form>
        <RegistrationFooter />
      </div>
    </AuthWrapper>
  );
}
