import { translate } from '../../../app/i18n';
import { Modal } from '../../atoms';
import { ShareContent } from '../../molecules';

export default function SharePopup({ link, ...props }) {
  return (
    <Modal
      size="md"
      title={translate('sharePopup.shareThisPost')}
      content={<ShareContent link={link} />}
      {...props}
    />
  );
}
