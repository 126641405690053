import { isExpired } from '../../../app/helper/formaters';
import { NotAvailableView, PostTimerSmallView, SoldOutView } from '../index';

export default function MoreInfoView({ postData }) {
  if (postData.status) {
    if (postData.status === 'available' && !isExpired(postData.expiryDate))
      return (
        <div className="card-image-overlay__main-info">
          <PostTimerSmallView
            {...{
              expiryDate: postData.expiryDate,
              newExpiryDate: postData.newExpiryDate,
              theme: 'light',
            }}
          />
        </div>
      );
    else if (postData.status === 'sold' || postData.availableQuantity === 0)
      return (
        <div className="card-image-overlay__main-info">
          <SoldOutView />
        </div>
      );
    else
      return (
        <div className="card-image-overlay__main-info">
          <NotAvailableView />
        </div>
      );
  }
}
