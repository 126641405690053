import { translate } from '../../../../../app/i18n';
import { AppStore, GooglePlay } from '../../../../atoms';

export default function DownloadOurApp() {
  return (
    <div className="footer-mobile__download-apps">
      <div className="footer-mobile__download-apps__title">
        <h3>{translate('footerMobile.downloadApp')}</h3>
      </div>
      <div className="footer-mobile__download-apps__buttons">
        <AppStore />
        <GooglePlay />
      </div>
    </div>
  );
}
