import { PopoverWrapper } from '..';

export default function DiscoverMore({ children, show, setShow, className }) {
  return (
    <div className={`discover-more ${className}`}>
      <button
        type="button"
        onClick={() => setShow((prev) => !prev)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={`question-mark icon-mark ${show && 'question-mark--active'}`}
      ></button>
      {show && (
        <PopoverWrapper
          PopoverWrapper
          color="dark"
          fullWidth
          {...{ show, setShow }}
        >
          {children}
        </PopoverWrapper>
      )}
    </div>
  );
}
