import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  getSellerData: [],
  isDataLoading: false,
  error: '',
};

export const sellerProfileSlice = createSlice({
  name: 'sellerData',
  initialState,
  reducers: {
    loading: (state) => {
      return { ...state, isDataLoading: true };
    },
    fetchSellerDataSuccess: (state, action) => {
      return {
        ...state,
        getSellerData: action.payload,
        isDataLoading: false,
      };
    },
    fetchSellerDataFailure: (state, action) => {
      return {
        ...state,
        isDataLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { loading, fetchSellerDataSuccess, fetchSellerDataFailure } =
  sellerProfileSlice.actions;

export default sellerProfileSlice.reducer;
