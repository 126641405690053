import styled from 'styled-components';
import { BORDER } from '../../../app/theme/colors';

export const Root = styled.div`
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 2px solid ${BORDER};
  overflow: hidden;

  input {
    border: none;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    padding: 12px;
    width: 100%;
  }

  .search-icon,
  .clear-search {
    position: absolute;
    cursor: pointer;
  }

  .search-icon {
    top: 12px;
    right: 23px;

    body[dir='rtl'] & {
      left: 23px;
      right: unset;
    }
  }

  .clear-search {
    right: 20px;
    top: 8px;

    body[dir='rtl'] & {
      left: 20px;
      right: unset;
    }
  }
`;
