export default function Total({ text, number, rightContent }) {
  return (
    <div className="total">
      <div className="total__left-content">
        <small className="total__number-box">{number.toNumberFormat()}</small>
        <small className={`total__label`}>{text}</small>
      </div>
      <div className="total__right-content">{rightContent}</div>
    </div>
  );
}
