import { Link } from 'react-router-dom';
import { translate } from '../../../../app/i18n';
import { contactInfo, routeConstants } from '../../../../constants';
import MazadatInfo from './mazadatInfo/mazadatInfo';
import PaymentSection from './paymentSection/paymentSection';
import FooterMobile from './footerMobile';
import { Banner } from '../../../atoms';
import FixedBottomLayout from '../../fixedBottomLayout/fixedBottomLayout';
import { BottomMenu } from '../../../molecules';
import config from '../../../../config';
import { useScrollDirection } from '../../../../hooks/useScrollDirection';
import { getJsonConfig } from '../../../../app/helper/firebaseConfigs';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  let allBanners = getJsonConfig('website_assets');
  const { footer } = allBanners || {};
  const [fixedBottom, setFixedBottom] = useState(false);
  const { showBottomMenu } = useSelector((state) => state.overlay);

  const scrollDirection = useScrollDirection();

  const onHandleScroll = () => {
    window.scrollTo(0, 0);
  };

  const CustomerServiceLinks = () => (
    <>
      <Link onClick={onHandleScroll} to={routeConstants.LEGAL_TERMS}>
        {translate('footer.termsConditions')}
      </Link>
      <Link onClick={onHandleScroll} to={routeConstants.PRIVACY_POLICY}>
        {translate('footer.privacyPolicy')}
      </Link>
      <Link onClick={onHandleScroll} to={routeConstants.CONTACT_US}>
        {translate('footer.contactUs')}
      </Link>
      <Link onClick={onHandleScroll} to={routeConstants.HOW_IT_WORKS}>
        {translate('footer.howITWorks')}
      </Link>
    </>
  );
  const MazadatLinks = () => (
    <>
      <Link onClick={onHandleScroll} to={routeConstants.ABOUT_US}>
        {translate('footer.aboutUs')}
      </Link>
      <a aria-label='become a business' href={config.B2C}>{translate('footer.becomeBusiness')}</a>
    </>
  );

  const ContactInfoLinks = () => (
    <>
      <p className="phone">&#x200E; {contactInfo.phone}</p>
      <a href={`mailto:${contactInfo.email}`}>
        <span>{contactInfo.email}</span>
      </a>
    </>
  );

  return (
    <footer className="footer-area">
      <Banner
        imagesUrl={footer ? footer.imagesUrl : ''}
        routeUrl={footer ? footer.routeUrl : ''}
        oneImg={false}
        className={'footer'}
      />
      <div className="footer-wrapper d-web">
        <MazadatInfo
          {...{ CustomerServiceLinks, ContactInfoLinks, MazadatLinks }}
        />
        <PaymentSection />
      </div>
      <div className="d-mobile">
        <FooterMobile
          {...{ CustomerServiceLinks, ContactInfoLinks, MazadatLinks }}
        />
      </div>
      <div
        className={!fixedBottom && scrollDirection === 'down' ? 'd-none' : ''}
      >
        {showBottomMenu && (
          <FixedBottomLayout className={'d-mobile'}>
            <BottomMenu setFixedBottom={setFixedBottom} />
          </FixedBottomLayout>
        )}
      </div>
    </footer>
  );
};
export default Footer;
