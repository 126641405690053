import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, InputOtp } from '../../atoms';
import { DontRecieveCodeCounter, Info } from '../../molecules';
import services from '../../../services';
import Loader from 'react-loader-spinner';
import { translate } from '../../../app/i18n';
import { login } from '../../../redux/actions/authActions';
import { snackboxOpen } from '../../../redux/actions/snackboxAction';
import { checkUserPlatform } from '../../../app/helper/general';
import { useForm } from 'react-hook-form';
import { createSchema } from '../../../app/helper/yup-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import events from '../../../utils/events';
import { ImageAssets } from '../../../assets/assetProvider';

export default function OtpForm({
  mobile,
  setOpen,
  form,
  resetForm,
  ref,
  setVerfied,
}) {
  const [counter, setCounter] = useState(
    services.storageService.get('counter') || 60,
  );
  const [service, setService] = useState('secondary');
  const dispatch = useDispatch();

  const formSchema = createSchema('otp');
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  const values = watch();

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
        services.storageService.set('counter', counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const handleChangeNumber = () => {
    setOpen(false);
    setVerfied(false);
    reset();
  };

  const handleResendCode = () => {
    reset();
    try {
      services.registrationService.postResendOtp({
        phone: mobile,
        service: service,
      });
      setCounter(60);
    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
    setService(service === 'primary' ? 'secondary' : 'primary');
  };

  const handleFormSubmit = async (data) => {
    if (isValid) {
      return services.registrationService
        .postOtpSubmit({
          phone: mobile,
          otpCode: data.otp,
        })
        .then(() => {
          let data = {
            userObj: {
              ...form,
              phone: { number: mobile },
              password: form.notsearch_password,
            },
          };
          return services.registrationService
            .postRegistrationData(data)
            .then(() => {
              setOpen(false);
              resetForm();
              dispatch(
                snackboxOpen(
                  translate('userFeedback.otpForm.successSubmission'),
                  'success',
                ),
              );
              events.logSignUp();
              dispatch(login(mobile, form.notsearch_password));
              resetForm();
              ref && ref?.current?.reset();
              setTimeout(() => {
                checkUserPlatform();
              }, 2000);
            })
            .catch(({ message }) => {
              dispatch(snackboxOpen(message, 'failure'));
            });
        })
        .catch((error) => {
          dispatch(snackboxOpen(error, 'failure'));
        });
    }
    return Promise.resolve();
  };
  const Description = () => (
    <div>
      <span className="mb-3 d-inline-block">
        {translate('otpForm.enterTheCode')}
      </span>
      <span className="otp-mobile">
        {mobile && <p className="otp-mobile__text">{mobile}</p>}
        <button
          className="otp-mobile__button button-hyperlink button-hyperlink--primary button-hyperlink--borderd"
          onClick={handleChangeNumber}
          aria-label="change number"
        >
          {translate('otpForm.changeNumber')}
        </button>
      </span>
    </div>
  );
  const SubmitButton = () => (
    <Button
      type="submit"
      className="text-white submit-otp"
      color="primary"
      fullWidth
      disabled={!isValid || isSubmitting}
      ariaLabel="submit otp"
    >
      {isSubmitting ? (
        <Loader
          type="TailSpin"
          className="loader-icon"
          width={20}
          height={20}
          color="#123f87"
        />
      ) : (
        translate('otpForm.verify')
      )}
    </Button>
  );
  return (
    <div className="otp-form">
      <hr />
      <img alt={'otp'} src={ImageAssets.otpPopupIllustration} />
      <div className="otp-form__header">
        <Info
          title={translate('otpForm.title')}
          description={<Description />}
        />
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
        <InputOtp
          register={register('otp')}
          error={errors.otp}
          code={values.otp}
        />
        <SubmitButton />
        <DontRecieveCodeCounter
          {...{
            counter,
            handleResendCode,
            isFormDisabled: isSubmitting,
          }}
        />
      </form>
    </div>
  );
}
