import { API } from '../constants';

export default function SearchService(httpClient) {
  const getSearchResults = (keyWord) => {
    return httpClient.get(`${API.GET_SEARCH_RESULTS}`, keyWord);
  };
  const getAutoComplete = ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, kw] = queryKey;
    return httpClient.get(API.GET_AUTO_COMPLETE, { kw });
  };
  return { getSearchResults, getAutoComplete };
}
