import { translate } from '../../../../../../app/i18n';
import { Collapsible } from '../../../../../atoms';
import { SocialMediaIcons } from '../../../../../molecules';

export default function ContactInfo({ ContactInfoLinks }) {
  return (
    <div className="footer-mobile__info__contact-info footer-mobile__info__section">
      <Collapsible isOpen={true} label={translate('footer.contactInfo')}>
        <div className="footer-mobile__info__contact-info__collapsed footer-mobile__info__collapsed">
          <ContactInfoLinks />
          <SocialMediaIcons className="socialMedia" />
        </div>
      </Collapsible>
    </div>
  );
}
