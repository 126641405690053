import { useQuery } from '@tanstack/react-query';
import config from '../config';
import services from '../services';
import { useDispatch } from 'react-redux';
import { snackboxOpen } from '../redux/actions/snackboxAction';

export function useHomeSectionQuery(url, params) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['homeSections', { url, ...params }],
    queryFn: () =>
      services.homeService.getHomeSections(config.API_URL + url, params),
    enabled: url !== null,
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}
