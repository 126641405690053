import styled from 'styled-components';
import { FS_12, FS_18 } from '../../../app/theme/typography';
import { breakpoints } from '../../../constants/general';
import {
  DISABLE,
  ON_SURFACE_HIGH,
  ON_SURFACE_MEDIUM,
} from '../../../app/theme/colors';

export const Root = styled.div`
  background-color: ${(props) => props.theme.colors.PRIMARY[`50`]};
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  @media screen and ${breakpoints.tablet_lg_max_width} {
    display: none;
  }
`;

export const RootSmall = styled.div`
  background-color: ${(props) => props.theme.colors.PRIMARY[`50`]};
  padding: 10px 20px;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;

  @media screen and ${breakpoints.tablet_lg_max_width} {
    display: grid;
  }

  @media screen and ${breakpoints.mobile_max_width} {
    padding: 20px 0;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 109;
    border-radius: 0;
  }
`;

export const SliderContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and ${breakpoints.tablet_max_width} {
    display: none;
  }

  @media screen and ${breakpoints.mobile_max_width} {
    display: flex;
  }
`;

export const ResultCount = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

export const ResultCountSmall = styled.p`
  font-size: 14px;
  font-weight: 600;
  display: none;

  @media screen and ${breakpoints.tablet_max_width} {
    display: block;
  }

  @media screen and ${breakpoints.mobile_max_width} {
    display: none;
  }
`;

export const Navs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  span {
    cursor: pointer;
    font-size: ${FS_18};
    color: ${ON_SURFACE_MEDIUM};

    &:hover {
      color: ${ON_SURFACE_HIGH};
    }
  }

  span.inactive {
    color: ${DISABLE} !important;
  }
`;

export const SortBy = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  #sort-by_sort-icon {
    color: ${ON_SURFACE_MEDIUM};
    font-size: 21px;
  }

  #sort-control-icons {
    color: ${ON_SURFACE_MEDIUM};
  }

  &:hover {
    #sort-by_sort-icon {
      color: ${ON_SURFACE_HIGH};
    }

    #sort-control-icons,
    #sort-control-icons:hover {
      color: ${ON_SURFACE_HIGH};
    }
  }
`;

export const SortText = styled.p`
  font-size: ${FS_12};

  @media screen and ${breakpoints.mobile_max_width} {
    display: none;
  }
`;

export const SortIcon = styled.img`
  padding: 10px;
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;

  @media screen and ${breakpoints.mobile_max_width} {
    display: none;
  }
`;
