import { Link } from 'react-router-dom';
import { translate } from '../../../../app/i18n';
import { routeConstants } from '../../../../constants';

const AuthFooter = () => {
  const onHandleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="auth-footer-container">
      <div className="auth-footer-container__right-reserved">
        <span className="footer-copyright">
          <b className="mazadatInc">{translate('footer.mazadatInc')}</b>
          &nbsp;
          {translate('footer.allRights')}
        </span>
      </div>

      <div className="auth-footer-container__legal-links">
        <div className="auth-footer-container__legal-links__contactus-page">
          <Link onClick={onHandleScroll} to={routeConstants.CONTACT_US}>
            {translate('footer.contactUs')}
          </Link>
        </div>
        <div className="auth-footer-container__legal-links__terms-page">
          <Link onClick={onHandleScroll} to={routeConstants.LEGAL_TERMS}>
            {translate('footer.termsConditions')}
          </Link>
        </div>
        <div className="auth-footer-container__legal-links__privacy-page">
          <Link onClick={onHandleScroll} to={routeConstants.PRIVACY_POLICY}>
            {translate('footer.privacyPolicy')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
