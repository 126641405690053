import PropTypes from 'prop-types';

export default function ButtonCancel({ handleOnClick, size, className }) {
  return (
    <button
      className={`button-cancel button-cancel--${size} ${
        className && className
      }`}
      onClick={handleOnClick}
      aria-label="cancel"
    >
      <span className="icon-cancel button-cancel__icon" />
    </button>
  );
}
ButtonCancel.propTypes = {
  handleOnClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  className: PropTypes.string,
};
ButtonCancel.defaultProps = {
  size: 'medium',
};
