import { Root } from './PageLoader.styled';
import loading from '../../../assets/images/mazadat-loader.gif';

export default function PageLoader() {
  return (
    <Root>
      <img src={loading} alt={'Loading...'} width={'100px'} />
    </Root>
  );
}
