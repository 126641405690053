import { translate } from '../../../app/i18n';
import { IconAssets } from '../../../assets/assetProvider';
import TooltipComponent from './../../styled-atoms/tooltip/tooltip';

export default function SecureBanner({ className, text, isSecured }) {
  return (
    <>
      {!isSecured ? (
        <div
          className={`security-banner security-banner--not-secure ${
            className && className
          }`}
        >
          <div className="security-banner__container">
            <img
              src={IconAssets.sellerBuyerIcon}
              alt="not secure"
              className="security-banner__icon"
              loading="lazy"
            />
            <p className="security-banner__words">
              <span>{text}</span>
            </p>
          </div>
          <div className="Tooltip_hint">
            <TooltipComponent text={translate('widgets.notSecured')} />
          </div>
        </div>
      ) : (
        <div
          className={`security-banner security-banner--secure ${
            className && className
          }`}
        >
          <div className="security-banner__container">
            <img
              src={IconAssets.secureIcon}
              alt="secure"
              className="security-banner__icon"
              loading="lazy"
            />
            <p className="security-banner__words">
              <span>{text}</span>
            </p>
          </div>
          <div className="Tooltip_hint">
            <TooltipComponent text={translate('widgets.securedItemTooltip')} />
          </div>
        </div>
      )}
    </>
  );
}
