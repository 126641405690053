export default function ListItem({ rightContent, leftContent }) {
  return (
    <>
      <div className="list-item">
        <div className="list-item__right">
          <p>{rightContent}</p>
        </div>
        <div className="list-item__left">
          <p>{leftContent}</p>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
}
