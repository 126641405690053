const PostQuantity = ({ quantityText, themeColor }) => {
  return (
    <>
      <div className={`post-quantity post-quantity--${themeColor} `}>
        <small className="post-quantity__label">{quantityText}</small>
      </div>
    </>
  );
};

export default PostQuantity;
