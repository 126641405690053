import ProgressiveImage from 'react-progressive-graceful-image';
import { ProgressiveImageLoader as Loader } from './styled.progressiveImageLoader';
import PropTypes from 'prop-types';
import { ImageAssets } from '../../../assets/assetProvider';

const ProgressiveImageLoader = ({
  image,
  routeUrl = '',
  className = '',
  alt = '',
  imageType,
}) => {
  const onImageError = (e) => {
    e.target.src =
      imageType === 'thumbnail'
        ? ImageAssets.defaultThumbnail
        : ImageAssets.defaultImage;
  };
  return (
    <ProgressiveImage src={image} placeholder={image} onError={onImageError}>
      {(src, loading) => (
        <Loader
          className={`banner-img ${routeUrl === '' && 'disabled'} ${className}`}
          src={src}
          alt={alt}
          loading={loading.toString()}
        />
      )}
    </ProgressiveImage>
  );
};

ProgressiveImageLoader.propTypes = {
  image: PropTypes.any,
  routeUrl: PropTypes.any,
  className: PropTypes.string,
  alt: PropTypes.string,
  imageType: PropTypes.oneOf(['thumbnail', 'image']),
};

ProgressiveImageLoader.defaultProps = {
  imageType: 'thumbnail',
};

export default ProgressiveImageLoader;
