import { translate } from '../../../app/i18n';
import { FeedbackImg } from '../../atoms';

export default function FeedbackModalContent({ imgSrc, handleClick }) {
  return (
    <div className="feedback__popup-content">
      <FeedbackImg imgSrc={imgSrc} />
      <div className="feedback__popup-content__text">
        <h3>{translate('sendMessageForm.thankYou')}</h3>
        <p className="feedback__popup-content__description">
          {translate('sendMessageForm.successMessageDescription')}
        </p>
      </div>
      <div className="feedback__popup-content__footer">
        <button aria-label="close" onClick={handleClick} className="button-transparent">
          {translate('sendMessageForm.closeNow')}
        </button>
      </div>
    </div>
  );
}
