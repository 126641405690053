// Paths
import { routeConstants } from '../constants';

const publicRoutes = [
  {
    path: routeConstants.HOME,
    async lazy() {
      const { NewHome } = await import('../components/pages/newHome/new-home');
      return { Component: NewHome };
    },
    index: true,
  },
  {
    path: '*',
    async lazy() {
      const { NotFound } = await import(
        '../components/pages/notFound/notFound'
      );
      return { Component: NotFound };
    },
  },
  {
    path: routeConstants.LEGAL_TERMS,
    async lazy() {
      const { TermsandConditionsPage } = await import(
        '../components/pages/legal/termsandConditionsPage'
      );
      return { Component: TermsandConditionsPage };
    },
  },
  {
    path: routeConstants.TERMS_CONDITIONS,
    async lazy() {
      const { TermsAndConditions } = await import(
        '../components/pages/legal/termsAndConditions/termsAndConditions'
      );
      return { Component: TermsAndConditions };
    },
  },
  {
    path: routeConstants.FEES_POLICY,
    async lazy() {
      const { FeesPolicy } = await import(
        '../components/pages/legal/feesPolicy/feesPolicy'
      );
      return { Component: FeesPolicy };
    },
  },
  {
    path: routeConstants.PRIVACY_POLICY,
    async lazy() {
      const { PrivacyPolicyPage } = await import(
        '../components/pages/legal/privacyPolicy/PrivacyPolicyPage'
      );
      return { Component: PrivacyPolicyPage };
    },
  },
  {
    path: routeConstants.POST_WITH_ID_AND_TITLE,
    async lazy() {
      const { PostDetails } = await import(
        '../components/pages/postDetails/postDetails'
      );
      return { Component: PostDetails };
    },
  },
  {
    path: routeConstants.POST_WITH_QUERY_OBJ,
    async lazy() {
      const { PostDetails } = await import(
        '../components/pages/postDetails/postDetails'
      );
      return { Component: PostDetails };
    },
  },
  {
    path: routeConstants.POST_WITH_ID,
    async lazy() {
      const { PostDetails } = await import(
        '../components/pages/postDetails/postDetails'
      );
      return { Component: PostDetails };
    },
  },
  {
    path: routeConstants.POST_LIST_BY_CATEGORY_NAME_AND_ID,
    async lazy() {
      const { PostList } = await import(
        '../components/pages/postList/PostList'
      );
      return { Component: PostList };
    },
  },
  {
    path: routeConstants.POST_LIST_BY_CATEGORY_NAME_AND_QUERY_OBJ,
    async lazy() {
      const { PostList } = await import(
        '../components/pages/postList/PostList'
      );
      return { Component: PostList };
    },
  },
  {
    path: routeConstants.SEARCH_RESULT_AND_KEYWORD,
    async lazy() {
      const { SearchResult } = await import(
        '../components/pages/searchResult/SearchResult'
      );
      return { Component: SearchResult };
    },
  },
  {
    path: routeConstants.SEARCH_RESULT_AND_QUERY_OBJ,
    async lazy() {
      const { SearchResult } = await import(
        '../components/pages/searchResult/SearchResult'
      );
      return { Component: SearchResult };
    },
  },
  {
    path: routeConstants.SECTION_POSTS,
    async lazy() {
      const { SectionPostList } = await import(
        '../components/pages/sectionPostList/SectionPostList'
      );
      return { Component: SectionPostList };
    },
  },
  {
    path: routeConstants.PRODUCT_POLICY,
    async lazy() {
      const { ProductsPolicy } = await import(
        '../components/pages/legal/productsPolicy/productsPolicy'
      );
      return { Component: ProductsPolicy };
    },
  },
  {
    path: routeConstants.ITEM_CONDITION_GUIDE,
    async lazy() {
      const { ItemConditionGuide } = await import(
        '../components/pages/legal/itemConditionGuide/itemConditionGuide'
      );
      return { Component: ItemConditionGuide };
    },
  },
  {
    path: routeConstants.BUSINESS_REGISTRATION_POLICY,
    async lazy() {
      const { BusinessRegistrationPolicy } = await import(
        '../components/pages/legal/businessRegistrationPolicy/businessRegistrationPolicy'
      );
      return { Component: BusinessRegistrationPolicy };
    },
  },
  {
    path: routeConstants.CONTACT_US,
    async lazy() {
      const { ContactUs } = await import(
        '../components/pages/contactUs/contactUs'
      );
      return { Component: ContactUs };
    },
  },
  {
    path: routeConstants.ABOUT_US,
    async lazy() {
      const { AboutUs } = await import('../components/pages/aboutUs/about-us');
      return { Component: AboutUs };
    },
  },
  {
    path: routeConstants.HOW_IT_WORKS,
    async lazy() {
      const { HowItWorks } = await import(
        '../components/pages/howItWorks/index'
      );
      return { Component: HowItWorks };
    },
  },
  {
    path: routeConstants.CATEGORIES,
    async lazy() {
      const { Categories } = await import(
        '../components/pages/categories/categories'
      );
      return { Component: Categories };
    },
  },
  {
    path: routeConstants.SELLER_PROFILE,
    async lazy() {
      const { SellerProfile } = await import(
        '../components/pages/sellerProfile/sellerProfile'
      );
      return { Component: SellerProfile };
    },
  },
  {
    path: routeConstants.COMING_SOON,
    async lazy() {
      const { UnderConstruction } = await import(
        '../components/pages/underConstruction/underConstruction'
      );
      return { Component: UnderConstruction };
    },
  },
];

export default publicRoutes;
