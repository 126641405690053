import { translate } from '../../../app/i18n';
import {
  getDiscount,
  getImageUrl,
  postRoute,
  getPostBiddingPrice,
  getPostPrice,
  isPostUnsecured,
} from '../../../app/helper/general';
import MoreInfoView from '../../atoms/MoreInfoView/MoreInfoView';

export default function PostHoc(WrappedComponent) {
  return function NewComponent({ data, ...props }) {
    const { expiryDate, availableQuantity, originalQuantity } = data;

    const discountObj = getDiscount(data);
    const cardStyle = WrappedComponent.prototype.style;

    const availabilityText = translate('postCard.qtyText')(
      data.availableQuantity,
      data?.auction?.biddingCount,
      discountObj.unit,
      cardStyle,
    );
    const biddingsText = data.auction
      ? translate('postCard.bidsNumberText')(
          data.auction.biddingCount,
          discountObj.unit,
        )
      : '';

    const postCardImageObject = {
      condition: data.condition,
      totalViews: data.totalViewsCounter,
      imageUrl: getImageUrl(data.thumbnail, 'thumbnail'),
      expiryDate: expiryDate,
      isSecured: !isPostUnsecured(data.sellerPostType),
      MoreInfoView: <MoreInfoView postData={data} />,
    };

    const postCardInfoObject = {
      quantityText: availabilityText,
      biddingCount: biddingsText,
      price: getPostPrice(data),
      discount: discountObj,
      biddingPrice: getPostBiddingPrice(data),
      title: data.title,
      isQuantity: data.availableQuantity > 0,
      availableQuantity,
      originalQuantity,
    };
    const shareLink = `${window.location.origin}${postRoute(
      data.title,
      data.esId || data._id,
    )}`;

    return (
      <WrappedComponent
        postId={data.esId || data._id}
        watchlistId={data._id}
        cardInfoObj={postCardInfoObject}
        cardImageObj={postCardImageObject}
        shareLink={shareLink}
        {...props}
      />
    );
  };
}
