import { ContentContainer, Root, SidebarContainer } from './PostListPageWrapper.styled';
import PropTypes from 'prop-types';

/**
 * Filtered Page Wrapper component
 * @param {JSX.Element} filter - filter component
 * @param {React.ReactNode} children - page content
 * @param {string} contentGap - page content gap
 * @returns {React.ReactNode}
 * @constructor
 */
function PostListPageWrapper({ sidebar, children, contentGap }) {
  return (
    <Root>
      <ContentContainer gap={contentGap}>{children}</ContentContainer>
      <SidebarContainer>{sidebar}</SidebarContainer>
    </Root>
  );
}

PostListPageWrapper.propTypes = {
  sidebar: PropTypes.element,
  children: PropTypes.node.isRequired,
  contentGap: PropTypes.string,
};

export default PostListPageWrapper;
