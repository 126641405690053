import { translate } from '../../../../../../app/i18n';
import { AppStore, GooglePlay } from '../../../../../atoms';

const DownloadApp = () => {
  return (
    <div className="downloadApp-wrapper">
      <h5>{translate('footer.downloadApp')}</h5>
      <div className="appstores-logo">
        <AppStore />
        <GooglePlay />
      </div>
    </div>
  );
};

export default DownloadApp;
