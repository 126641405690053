import { useState } from 'react';
import ReviewRatingPoints from './reviewRatingPoints';
import { translate } from '../../../app/i18n';

export default function ReviewRatingMobile({ sellerRating }) {
  const [showRating, setShowRating] = useState(false);
  return (
    <div className="mobile-rating-points">
      {showRating && <ReviewRatingPoints {...{ sellerRating }} />}
      <p
        onClick={() => setShowRating(!showRating)}
        className="view-details-collaspe"
      >
        {translate('ratingView.viewDetails')}{' '}
        <span className={showRating ? 'icon-arrow-top' : 'icon-arrow-bottom'} />
      </p>
    </div>
  );
}
