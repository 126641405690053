import { Share } from '../../atoms';
import PropTypes from 'prop-types';

const CardImageOverlay = ({ cardImageObj, themeColor, handleShowPopUp }) => {
  let { MoreInfoView } = cardImageObj;
  return (
    <div className={`card-image-overlay card-image-overlay--${themeColor}`}>
      {/* @TODO : should be organizm */}
      <div>
        {MoreInfoView}
        <div className="card-image-overlay__share-button">
          <Share
            {...{ handleShowPopUp, shareClass: 'share-button__icon-card' }}
          />
        </div>
      </div>
    </div>
  );
};
CardImageOverlay.propTypes = {
  themeColor: PropTypes.oneOf(['primary', 'secondary', 'light']),
};

CardImageOverlay.defaultProps = {
  themeColor: 'light',
};

export default CardImageOverlay;
