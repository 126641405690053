import Proptypes from 'prop-types';

export default function PostStatusMobile({ topSectionContent }) {
  if (topSectionContent.length === 0) return null;
  return (
    <>
      {topSectionContent.map(({ text, className }, index) => (
        <div
          key={index}
          className={`post-Status-Mobile ${className && className}`}
        >
          <p className="post-Status-Mobile__text">{text}</p>
        </div>
      ))}
    </>
  );
}
PostStatusMobile.propTypes = {
  topSectionContent: Proptypes.array,
};
