import { API } from '../constants';

export default function sellerProfile(httpClient) {
  const getUserPublicDetails = (id) => {
    return httpClient.get(`${API.GET_USER_PUBLIC_DETAILS}/${id}`);
  };
  const getSellerReviewDetails = (id) => {
    return httpClient.get(`${API.GET_SELLER_PROFILE_REVIEWS}/${id}`);
  };

  return {
    getUserPublicDetails,
    getSellerReviewDetails,
  };
}
