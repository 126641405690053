import { createBrowserRouter, Outlet } from 'react-router-dom';
import publicRoutes from './public-routes';
import protectedRoutes from './protected-routes';
import authRoutes from './auth-routes';
import { AuthLayout, Layout } from '../components/organisms';
import { routeConstants } from '../constants';
import PrivateRoute from './PrivateRoute';
import LanguageWrapper from '../app/i18n/LanguageWrapper';
import { NotFound } from '../components/pages/notFound/notFound';

const AppRouter = createBrowserRouter([
  {
    element: <LanguageWrapper />,
    errorElement: (
      <Layout>
        <NotFound />
      </Layout>
    ),
    children: [
      {
        element: (
          <Layout>
            <Outlet />
          </Layout>
        ),
        children: publicRoutes,
      },
      {
        element: (
          <PrivateRoute redirectTo={routeConstants.REGISTRATION}>
            <Layout>
              <Outlet />
            </Layout>
          </PrivateRoute>
        ),
        children: protectedRoutes,
      },
      {
        element: (
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        ),
        children: authRoutes,
      },
    ],
  },
]);

export default AppRouter;
