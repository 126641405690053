import { API } from '../constants';
import { getImageType, imageTypes } from '../constants/general';

export default function CategoryService(httpClient) {
  return {
    getParentCategories: ({ queryKey }) => {
      // eslint-disable-next-line no-unused-vars
      const [_key, { used }] = queryKey;
      return httpClient.get(API.GET_PARENT_CATEGORIES, {
        ...getImageType(imageTypes.SVG),
        used,
      });
    },

    getSubCategories: ({ queryKey }) => {
      // eslint-disable-next-line no-unused-vars
      const [_key, _type, { parentId, used }] = queryKey;
      return httpClient.get(`${API.GET_SUB_CATEGORIES}/${parentId}`, {
        ...getImageType(imageTypes.SVG),
        used,
      });
    },

    getCategoryById: ({ queryKey }) => {
      // eslint-disable-next-line no-unused-vars
      const [_key, { id }] = queryKey;
      return httpClient.get(
        `${API.GET_CATEGORY}/${id}`,
        getImageType(imageTypes.SVG),
      );
    },

    getCategoryFilterFields: ({ queryKey }) => {
      // eslint-disable-next-line no-unused-vars
      const [_key, _path, { id }] = queryKey;
      return httpClient.get(`${API.GET_CATEGORY_FIELDS}/${id}`);
    },

    getCategoryPath: ({ queryKey }) => {
      // eslint-disable-next-line no-unused-vars
      const [_key, _path, { id }] = queryKey;
      return httpClient.get(
        `${API.GET_CATEGORY_PATH}/${id}`,
        getImageType(imageTypes.SVG),
      );
    },

    getAllCategories: () =>
      httpClient.get(API.GET_CATEGORY, getImageType(imageTypes.SVG)),
  };
}
