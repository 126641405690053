import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { Button, Price } from '../../atoms';
import { DownloadPopup } from '../../templates';
import { getPostPrice } from '../../../app/helper/general';

export default function BuyNow({ data, className, isItemAvailable }) {
  const [showDownload, setShowDownload] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);
  const { discount } = data;
  return (
    <section className={`buy-now border-bottom ${className && className}`}>
      <Price
        price={getPostPrice(data)}
        discount={discount}
        label={translate('postCard.buyNow')}
        labelClassName="buy-now__label"
        currencyClassName="buy-now__currency"
        priceClassName="buy-now__price"
      />
      <div className="buy-now__buttons">
        {/* Release #2
           regarding to the design doc :
           this btn will appear only when user add the post into cart 
        */}
        {/* <Button
          onClick={() => setShowDownload(true)}
          disabled={!isItemAvailable}
          color="secondary"
          size="medium"
          className='buy-now__shopping-cart'
          ariaLabel="shopping cart"
        >
          <span className='icon-bag'></span>
        </Button> */}
        <Button
          color="secondary"
          onClick={() => setShowDownload(true)}
          disabled={!isItemAvailable}
          size="medium"
          ariaLabel="buy"
        >
          {translate('postCard.buyNow')}
        </Button>
      </div>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </section>
  );
}
