import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  options: {
    filters: {},
    sort: [],
  },
  queryObject: {
    sort: 'Urgent',
    page: 1,
  },
  isDataLoading: false,
  error: '',
  watchList: [],
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    loading: (state) => {
      return { ...state, isDataLoading: true };
    },
    fetchPostsSuccess: (state, action) => {
      return {
        ...state,
        data: action.payload.posts,
        numberOfResults: action.payload.numberOfResults,
        isDataLoading: false,
      };
    },
    fetchPostsFailure: (state, action) => {
      return { ...state, isDataLoading: false, error: action.payload.error };
    },
    fetchPostFiltersSuccess: (state, action) => {
      return { ...state, options: action.payload, isDataLoading: false };
    },
    fetchPostFiltersFailure: (state, action) => {
      return { ...state, isDataLoading: false, error: action.payload.error };
    },
    seteQueryObject: (state, action) => {
      if (action.payload.name === 'page')
        return {
          ...state,
          queryObject: {
            ...state.queryObject,
            [action.payload.name]: action.payload.value,
          },
        };
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.name]: action.payload.value,
          page: 1,
        },
      };
    },
    resetQueryObject: (state) => {
      return {
        ...state,
        queryObject: { ...initialState.queryObject },
      };
    },
    fetchSectionsSuccess: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isDataLoading: false,
      };
    },
    fetchSectionsFailure: (state, action) => {
      return {
        ...state,
        isDataLoading: false,
        error: action.payload,
      };
    },
    fetchWatchListSuccess: (state, action) => {
      return {
        ...state,
        watchList: action.payload,
      };
    },
    fetchWatchListFailure: (state, action) => {
      return {
        ...state,
        isDataLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  loading,
  fetchPostsSuccess,
  fetchPostsFailure,
  fetchPostFiltersFailure,
  fetchPostFiltersSuccess,
  seteQueryObject,
  resetQueryObject,
  fetchSectionsFailure,
  fetchSectionsSuccess,
  fetchWatchListSuccess,
  fetchWatchListFailure,
} = postsSlice.actions;

export default postsSlice.reducer;
