import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import postsReducer from './postsSlice';
import postReducer from './postSlice';
import userReducer from './userSlice';
import topFiveCategoriesReducer from './topFiveCategoriesSlice';
import activePostsReducer from './activePostsSlice';
import snackboxReducer from './snackboxSlice';
import allCategoriesReducer from './allCategoriesSlice';
import sellerProfileReducer from './sellerProfileSlice';
import watchlistStatusReducer from './watchlistStatusSlice';
import overlaySliceReducer from './overlaySlice';

export const combinedReducer = combineReducers({
  auth: authReducer,
  posts: postsReducer,
  post: postReducer,
  user: userReducer,
  topFiveCategories: topFiveCategoriesReducer,
  activePosts: activePostsReducer,
  snackbox: snackboxReducer,
  allCategories: allCategoriesReducer,
  sellerProfile: sellerProfileReducer,
  watchlistStatus: watchlistStatusReducer,
  overlay: overlaySliceReducer,
});
