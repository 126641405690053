import { translate } from '../../../app/i18n';
import { Progress } from '../../atoms';

export default function Quantity({ availableQuantity, originalQuantity }) {
  return (
    <>
      {availableQuantity !== 1 && (
        <div className="quantity blue-container">
          <div className="quantity__title">
            <p>
              {translate('postDetailsPage.itemsSoon')}
              <br />
              {translate('postDetailsPage.soldOut')}
            </p>
          </div>
          <Progress
            label={
              <>
                <span className="fw-bold text-dark">
                  {availableQuantity.toNumberFormat()}
                </span>
                <span> {translate('general.outOf')} </span>
                <span className="fw-bold text-dark">
                  {originalQuantity.toNumberFormat()}{' '}
                </span>
                <span>{translate('postDetailsPage.itemsAreRemaining')}</span>
              </>
            }
            availableQuantity={availableQuantity}
            originalQuantity={originalQuantity}
            labelPosition="top"
            className="quantity-progress-bar"
          />
        </div>
      )}
    </>
  );
}
