import Styled from 'styled-components';
export const PostSimilarContainer = Styled.div`
display: flex;
flex-direction:row;
align-items: center;
gap: 0 6px;

`
export const Icon = Styled.span`
  color: ${props => props.theme.colors.ICONS_COLOR};
  margin: 0 3px;
  font-size:18px;
`
export const Label = Styled.p`
font-size: ${props => props.theme.typography.FS_12};
color: ${props => props.theme.colors.PRIMARY[500]};
font-weight: 600;
`