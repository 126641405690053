import { formateDateByUnit } from '../../../app/helper/formaters';
import { translate } from '../../../app/i18n';
const PostsSold = ({ postsSold }) => {
  return (
    <>
      {postsSold?.numberOfPosts > 0 && (
        <>
          {translate('aboutSellerView.sold')}{' '}
          <b>{postsSold.numberOfPosts.toNumberFormat()} </b>{' '}
        </>
      )}
      {translate('aboutSellerView.soldItems')(postsSold?.numberOfPosts)}{' '}
      {formateDateByUnit(postsSold?.date, 'MMMM. YYYY')}
    </>
  );
};

export default PostsSold;
