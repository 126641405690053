/* eslint-disable max-lines */
import { ratingKeys } from '../../constants/ratingKeys';
import { translate } from '../i18n';
import { getImageUrl } from './general';

export const imageSliderMapper = (arrayOfImages) => {
  return arrayOfImages.map((imgSrc) => {
    let obj = {};
    obj = {
      original: getImageUrl(imgSrc, 'image'),
      thumbnail: getImageUrl(imgSrc, 'image'),
    };
    return obj;
  });
};
//@TODO: We need mapper for translations for postCard and postDetails.
//@TODO: We need mapper for mapping labels.
export const postConditionMapper = (condition) => {
  if (condition) {
    switch (condition) {
      case 'New and sealed':
      case 'New with open box':
        return 'newText';
      case 'Mint':
        return 'mint';
      case 'Used':
        return 'used';
      case 'Defective':
        return 'defective';
      default:
        return condition;
    }
  } else return '';
};

export const highLightMyWord = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          className={
            part.toLowerCase() === highlight.toLowerCase()
              ? 'highlightedText'
              : ''
          }
          key={i}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const ratingMapper = (TotalRatings) => {
  TotalRatings = TotalRatings ?? {};
  TotalRatings = Object.entries(TotalRatings).filter(
    ([key]) => !ratingKeys.includes(key),
  );
  return TotalRatings.map(([key, value]) => {
    let newValue =
      value['sum'] === 0 && value['count'] === 0
        ? '0.0'
        : (value['sum'] / value['count']).toFixed(1);
    return [translate(`ratingView.${key}`), newValue];
  });
};

export const userRatingMapper = (TotalRatings) => {
  TotalRatings = TotalRatings ?? {};
  TotalRatings = Object.entries(TotalRatings).filter(
    ([key]) => !ratingKeys.includes(key),
  );
  return TotalRatings.map(([key, value]) => {
    return [translate(`ratingView.${key}`), value.toFixed(1)];
  });
};

export const postStatusMapper = (isItemAvailable, status) => {
  let arr = [];
  const arrOfBoxes = [
    {
      key: 'soldOut',
      text: translate('postDetailsPage.itemSoldOut'),
      className: 'darkRed',
    },
    {
      key: 'notAvailable',
      text: translate('postDetailsPage.thisPostNotAvailable'),
      className: 'darkBlue',
    },
  ];

  if (status === 'sold')
    arr.push(arrOfBoxes.find((box) => box.key === 'soldOut'));
  else if (isItemAvailable === false)
    arr.push(arrOfBoxes.find((box) => box.key === 'notAvailable'));

  return arr;
};
