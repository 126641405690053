import { formateDiffTime } from '../../../app/helper/formaters';
import { translate } from '../../../app/i18n';
import { getAvatarUrl } from '../../../app/helper/general';
import config from '../../../config';

export default function QuestionList({ data, lastAnswer, length }) {
  return (
    <div className="answer-list ">
      <div className="answers__container">
        <div
          className={`border-left ${
            length > 1
              ? lastAnswer
                ? 'last-one'
                : ''
              : length === 1
              ? 'only-one'
              : ''
          } `}
        ></div>
        <div className="answers-content">
          <div
            className={`answer-list__avatar  ${
              length === 1 ? 'only-one' : ''
            } `}
          >
            <img
              loading="lazy"
              src={getAvatarUrl(data?.user?.avatar)}
              alt="avatar"
            />
            <span className="answer-list__small-Text__name">
              {data.user
                ? data.user.username
                : translate('postDetailsPage.mazadat')}{' '}
            </span>
          </div>
          <div className="answer-list__container">
            <div className="answer-list__box">
              {data.answer && <p>{data.answer}</p>}
              {data.images &&
                data.images.map((img, index) => (
                  <img
                    src={config.INQUIRIES_IMG_URL + img}
                    key={index}
                    alt="img reply"
                  />
                ))}
            </div>
          </div>
          <small className="answer-list__small-Text">
            <span className="answer-list__small-Text__time">
              {formateDiffTime(data.answerDate)}
            </span>
          </small>
        </div>
      </div>
    </div>
  );
}
