import { AddToWatchList, PostIcon, PostLabel, Views } from '../../atoms';
import { postConditionMapper } from '../../../app/helper/mappers';
import { ProgressiveImageLoader } from '../../organisms';
import {
  useAddToWatchlist,
  useRemoveFromWatchlist,
  useWatchlistQuery,
} from '../../../hooks/usePostQuery';
import { useSelector } from 'react-redux';
import { debounce, some } from 'lodash';

const PostCardImageHorizontal = ({
  cardImageObj,
  className,
  watchlistId,
  handleOnClick,
}) => {
  const { data: watchList } = useWatchlistQuery();
  const { mutate: addToWatchlist, isLoading: addWatchlistLoading } =
    useAddToWatchlist(watchlistId);
  const { mutate: removeFromWatchlist, isLoading: removeWatchlistLoading } =
    useRemoveFromWatchlist(watchlistId);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { condition, totalViews, isSecured, MoreInfoView } = cardImageObj;
  let imageLabel = postConditionMapper(condition);
  const alreadyInWatchList = some(watchList, { _id: watchlistId });

  const toggleWatchList = () => {
    if (!some(watchList, { _id: watchlistId })) {
      addToWatchlist();
    } else {
      removeFromWatchlist();
    }
  };

  const handleWatchList = () => {
    if (isAuthenticated) {
      toggleWatchList();
    }
  };
  const debouncedHandleWatchList = debounce(handleWatchList, 500);

  if (condition) {
    imageLabel = postConditionMapper(condition);
    if (imageLabel.includes('new')) {
      imageLabel = 'newText';
    }
  }
  return (
    <div className={`post-card-image-horizontal ${className}`}>
      <div className="post-card-image-horizontal__header">
        <div className="first-side">
          <PostLabel postCondition={imageLabel} />
        </div>
        <div className="second-side">
          {isSecured && (
            <PostIcon
              iconBackgroundClass={'post-icon-secure'}
              className={'icon-secure'}
            />
          )}
          <AddToWatchList
            onClick={isAuthenticated ? debouncedHandleWatchList : null}
            size="sm"
            theme={alreadyInWatchList ? 'filled' : 'dark'}
            loading={addWatchlistLoading || removeWatchlistLoading}
          />
        </div>
      </div>
      <div className="post-card-image-horizontal__content">
        <ProgressiveImageLoader
          routeUrl={handleOnClick}
          image={cardImageObj.imageUrl}
        />
      </div>
      <div className="post-card-image-horizontal__footer">
        <div className="first-side">{MoreInfoView}</div>
        <div className="second-side">
          <Views
            numberOfViews={totalViews}
            light={true}
            fontSize={10}
            iconSize={10}
          />
        </div>
      </div>
    </div>
  );
};

export default PostCardImageHorizontal;
