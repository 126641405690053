import { useSelector } from 'react-redux';
import config from '../../../config';
import { LogoAssets }  from '../../../assets/assetProvider';

function DownloadAppLink({ url, imgSrc }) {
  const { websiteLanguage } = useSelector((state) => state.user);
  return (
    <a  aria-label="download app" href={url} target="_blank" rel="noreferrer">
      <img alt="app store icon" src={imgSrc[websiteLanguage]} loading="lazy" />
    </a>
  );
}

export const AppStore = () => (
  <DownloadAppLink url={config.APPLE_HREF} imgSrc={LogoAssets.appStoreLogos} />
);
export const GooglePlay = () => (
  <DownloadAppLink url={config.GOOGLE_HREF} imgSrc={LogoAssets.googlePlayLogos} />
);
