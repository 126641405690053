import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { Button, Price } from '../../atoms';
import { DownloadPopup } from '../../templates';

export default function BidNowMobile({
  isItemAvailable,
  nextBid,
  lastBid,
  biddingCount,
  handleViewHistory,
}) {
  const [showDownload, setShowDownload] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);
  return (
    <div className="bidnow-mobile">
      <Price
        price={(isItemAvailable ? nextBid : lastBid).toNumberFormat()}
        label={
          isItemAvailable
            ? translate('biddingHistorySidebar.nextBid')
            : translate('biddingHistorySidebar.lastBid')
        }
        buttonTextBesideLabel={translate('postDetailsPage.bid')(biddingCount)}
        buttonFunction={handleViewHistory}
        labelClassName="bidnow-mobile__label"
        currencyClassName="bidnow-mobile__currency"
        priceClassName="bidnow-mobile__price"
        disabled={!isItemAvailable}
      />
      <div className="bidnow-mobile__button-group">
        <Button
          color="primary"
          onClick={() => setShowDownload(true)}
          disabled={!isItemAvailable}
          ariaLabel="bid"
        >
          {translate('biddingHistorySidebar.bidNow')}
        </Button>
      </div>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </div>
  );
}
