import { MazadatLogo } from '../../../../../atoms';
import { SocialMediaIcons } from '../../../../../molecules';

const SocialMedia = () => {
  return (
    <div className="socialMedia-wrapper">
      <MazadatLogo />
      <SocialMediaIcons className="socialMedia" />
    </div>
  );
};

export default SocialMedia;
