import { translate } from '../../../../../app/i18n';
import { MazadatLogo } from '../../../../atoms';
import { PaymentMethods } from '../../../../molecules';

export default function Payment() {
  return (
    <div className="footer-mobile__payment">
      <div className="footer-mobile__payment__logo">
        <MazadatLogo />
      </div>
      <div className="footer-mobile__payment__types">
        <PaymentMethods className="footer-mobile__payment__types__icons" />
      </div>
      <div className="footer-mobile__payment__text">
        <span>
          <b>{translate('footer.mazadatInc')}</b>
          &nbsp;
          {translate('footer.allRights')}
        </span>
      </div>
    </div>
  );
}
