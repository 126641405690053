import { translate } from '../../../app/i18n';
import PopoverWrapper from '../popoverWrapper/popoverWrapper';
import PropTypes from 'prop-types';

export default function UserNameChickList({ name }) {
  const validationData = [
    {
      text: translate('registrationForm.userNameCheckList.usernameCharLength'),
      validation(username) {
        return username?.length >= 3 && username?.length <= 30;
      },
    },
    {
      text: translate('registrationForm.userNameCheckList.noSpecialChar'),
      validation(username) {
        return (
          username &&
          /^[a-zA-Z\u0621-\u064A]([._-]?[a-zA-Z\u0621-\u064A0-9\u0660-\u0669]+)*$/.test(
            username,
          )
        );
      },
    },
    {
      text: translate('registrationForm.userNameCheckList.noSpaces'),
      validation(username) {
        return username && !/\s/.test(username);
      },
    },
    {
      text: translate('registrationForm.userNameCheckList.noUpperCase'),
      validation(username) {
        return username && !/(?=.*[A-Z])/.test(username);
      },
    },
    {
      text: translate('registrationForm.userNameCheckList.lessThanSix'),
      validation(username) {
        return username && /^(?!(.*[0-9\u0660-\u0669].*){6}).*$/.test(username);
      },
    },
  ];
  return (
    <PopoverWrapper
      className="check-list-group"
      color="dark"
      position="top"
      fullWidth
    >
      {validationData.map((list, index) => (
        <div
          key={index}
          className={`check-list check-list--${
            list.validation(name) ? 'valid' : 'not-valid'
          }`}
        >
          <span className="check-list__icon icon-Verified"></span>
          <small className="check-list__text">{list.text}</small>
        </div>
      ))}
    </PopoverWrapper>
  );
}

UserNameChickList.propTypes = {
  name: PropTypes.string,
};
