import { translate } from '../../../../../../app/i18n';

const ContactInfo = ({ ContactInfoLinks }) => {
  return (
    <div className="contactInfo-wrapper">
      <h5>{translate('footer.contactInfo')}</h5>
      <ContactInfoLinks />
    </div>
  );
};

export default ContactInfo;
