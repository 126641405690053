import styled from 'styled-components';
import { breakpoints } from '../../../constants/general';
import { FS_32, FS_48 } from '../../../app/theme/typography';
import { PRIMARY } from '../../../app/theme/colors';

export const Root = styled.div`
  background-color: ${PRIMARY[`50`]};
  padding: 20px 20px 20px 40px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  justify-content: space-between;

  @media screen and ${breakpoints.mobile_max_width} {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: ${FS_48};
  font-weight: bold;
  color: #000000;
  line-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  @media screen and ${breakpoints.desk_max_width} {
    font-size: ${FS_32};
  }
`;

export const IconImage = styled.img`
  width: 220px;
  height: 220px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
`;
