import { Modal } from '../../atoms';
import { ImageGalleryShowMoreContent } from '../../molecules';

export default function ImageShoreMore(props) {
  return (
    <Modal
      size="lg"
      content={<ImageGalleryShowMoreContent {...props} />}
      {...props}
    />
  );
}
