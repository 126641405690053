import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Collapsible = ({ label, children, isOpen, className }) => {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const toggle = () => setOpen(!open);
  return (
    <div className="collapsible">
      <div className={`collapsible__group ${className && className}`} onClick={toggle}>
        <h5 className="collapsible__group__label">{label}</h5>
        <span className="collapsible__group__icons" >
          {open ? (
            <span className="collapsible__group__icon icon-mins-square"></span>
          ) : (
            <span className="collapsible__group__icon  icon-add-square"></span>
          )}
        </span>
      </div>

      <div>{open && children} </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default Collapsible;
