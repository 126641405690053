import { IconImage, Root, Title } from './CategoryHeader.styled';
import PropTypes from 'prop-types';
import config from '../../../config';
import { categoryDefaultImage } from '../../../constants/general';
import { useState } from 'react';

/**
 * Category Header component
 * @param {string} title - Category title
 * @param {string} imageUrl - Category image url
 * @returns {JSX.Element}
 * @constructor
 */
function CategoryHeader({ title, imageUrl }) {
  const categoryImagePath = 'cat-card/';
  const [image, setImage] = useState(
    config.IMG_CONTAINER_WITHOUT_ENV + categoryImagePath + imageUrl,
  );

  return (
    <Root>
      <Title title={title}>{title}</Title>
      <IconImage
        alt={title}
        src={image}
        onError={() => setImage(categoryDefaultImage)}
      />
    </Root>
  );
}

CategoryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default CategoryHeader;
