import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import services from '../../../../../../services';
import SearchResults from '../searchResults/searchResults';
import SearchFormMobile from './mobile/searchForm-mobile';
import SearchFormWeb from './web/searchForm-web';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../../../constants';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Divider } from '../../../../../atoms';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSearchHistory,
  fetchUserSearchHistory,
} from '../../../../../../redux/actions/userAction';
import HistoryResults from './historyResults/historyResults';
import { useQuery } from '../../../../../../hooks/useQuery';

const SearchForm = ({
  handleSearch,
  closeOverlayHandler,
  onHandleCloseOverlay,
  setShowOverLay,
  setShowCreatePostOptions,
  searchDropDownContent,
  setSearchDropDownContent,
  className,
  mobileContainerRef,
  searchIconRef,
}) => {
  const params = useParams();
  const searchParams = useQuery();
  const keyword = params.keyword || searchParams.get('keyword') || '';
  const searchInputRef = useRef();
  const mobileSearchInputRef = useRef();
  const [searchInput, setSearchInput] = useState(keyword);
  const [searchResults, setSearchResults] = useState([]);
  const queryClient = useQueryClient();
  const loaderNumberOfIterations = 6;
  const { searchHistory } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (keyword) {
      setSearchInput(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    dispatch(fetchUserSearchHistory());
  }, []);

  const handleFetchSearchResults = useCallback(async (searchInputText) => {
    await fetchSearchResults(searchInputText);
    if (!searchInputText) {
      setSearchDropDownContent({
        ...searchDropDownContent,
        showSearchDropDown: true,
      });
    }
  }, []);

  const debouncedHandleSearch = useMemo(
    () => debounce(handleFetchSearchResults, 500),
    [],
  );

  const onChangeHandler = (e) => {
    // when user change text input
    const searchInputText = e.target.value;
    setSearchInput(searchInputText);
    setShowCreatePostOptions(false);
    setSearchDropDownContent({
      showSearchDropDown: true,
      showLoading: true,
      showSearchResults: false,
    });

    debouncedHandleSearch(searchInputText);
  };

  const handleHistoryItemClick = (item) => {
    setSearchInput(item);
    handleSearch(item);
  };

  const handleClearAllSearch = () => {
    dispatch(deleteSearchHistory());
    searchInputRef.current.focus();
    setShowOverLay(true);
    setShowCreatePostOptions(false);
    closeOverlayHandler(false);
    setSearchDropDownContent({
      ...searchDropDownContent,
      showSearchDropDown: true,
    });
  };

  const fetchSearchResults = async (input) => {
    const response = await queryClient.fetchQuery({
      queryKey: queryKeys.getAutoComplete(input),
      queryFn: services.searchService.getAutoComplete,
    });

    if (input === searchInputRef.current.value) {
      setSearchResults(response);
      if (response.length > 0) {
        setSearchDropDownContent({
          showSearchDropDown: true,
          showLoading: false,
          showSearchResults: true,
        });
      } else {
        setSearchDropDownContent({
          showSearchDropDown: true,
          showLoading: false,
          showSearchResults: false,
        });
      }
    }
  };

  const searchBarFocusHandler = () => {
    searchInputRef.current.focus();
    setShowOverLay(true);
    setShowCreatePostOptions(false);
    closeOverlayHandler(false);
    if ((searchResults.length > 0 && searchInput) || searchHistory.length > 0) {
      setSearchDropDownContent({
        ...searchDropDownContent,
        showSearchDropDown: true,
      });
    }
    // show mobile search container group
    mobileContainerRef && mobileContainerRef.current.classList.remove('d-none');
  };

  const onClearSearchInput = () => {
    setSearchInput('');
    setSearchDropDownContent({
      ...searchDropDownContent,
      showSearchDropDown: true,
    });
    setSearchResults([]);
    setShowCreatePostOptions(false);
    // for mobile
    mobileSearchInputRef.current.focus();
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (searchInput) {
      handleSearch(searchInput);
    }
  };

  const SearchResultsGroup = searchDropDownContent.showSearchDropDown && (
    <div className="search-results">
      {searchDropDownContent.showLoading && (
        <div className="search-results__loader-contaner">
          {[...Array(loaderNumberOfIterations)].map((_, i) => {
            return (
              <Fragment key={i}>
                <Skeleton style={{ height: '8px', width: '100%' }} />
                {i < 5 ? (
                  <Divider orientation={'horizontal'} thickness={0.5} />
                ) : null}
              </Fragment>
            );
          })}
        </div>
      )}
      {searchResults.length && !searchDropDownContent.showLoading ? (
        <SearchResults
          searchResults={searchResults}
          onItemClick={handleSearch}
          searchInput={searchInput}
        />
      ) : (
        !searchDropDownContent.showLoading &&
        searchInput.length === 0 && (
          <HistoryResults
            onClick={handleHistoryItemClick}
            historyResults={searchHistory}
            handleClearAllSearch={handleClearAllSearch}
          />
        )
      )}
    </div>
  );

  return (
    <>
      <div className={`search-form ${className && className}`}>
        <SearchFormWeb
          {...{
            onSearch,
            onChangeHandler,
            searchBarFocusHandler,
            onClearSearchInput,
            searchInputRef,
            setShowOverLay,
            searchInput,
            searchDropDownContent,
            SearchResultsGroup,
            closeOverlayHandler,
          }}
        />
        <SearchFormMobile
          {...{
            onSearch,
            onChangeHandler,
            searchBarFocusHandler,
            onClearSearchInput,
            mobileSearchInputRef,
            searchInput,
            searchDropDownContent,
            mobileContainerRef,
            searchIconRef,
            SearchResultsGroup,
            onHandleCloseOverlay,
          }}
        />
      </div>
    </>
  );
};

export default SearchForm;
