import { API } from '../constants';

export default function RegistrationService(httpClient) {
  const postMobileNumberVerifyAndOtp = (body) => {
    return httpClient.post(`${API.POST_MOBILE_NUMBER_VERIFY_AND_OTP}`, body);
  };
  const postValidateRegistrationAndSendOtp = (body) => {
    return httpClient.post(API.POST_VALIDATE_REGISTRATION_AND_SEND_OTP, body);
  };
  const postResendOtp = (body) => {
    return httpClient.post(`${API.POST_RESEND_OTP}`, body);
  };
  const getUniqueUserName = (userName) => {
    return httpClient.get(`${API.GET_UNIQUE_USER_NAME}${userName}`);
  };
  const postRegistrationData = (body) => {
    return httpClient.post(`${API.POST_REGISTRATION_DATA}`, body);
  };
  const postOtpSubmit = (body) => {
    return httpClient.post(`${API.POST_OTP_SUBMIT}`, body);
  };
  const postVerifyEmail = (body) => {
    return httpClient.post(`${API.POST_VERIFY_EMAIL}`, body);
  };
  const postSetUserData = (body) => {
    return httpClient.post(`${API.POST_SET_USER_DATA}`, body);
  };
  const submitEmailOtp = (body) => {
    return httpClient.post(`${API.POST_SUBMIT_EMAIL_OTP}`, body);
  };
  return {
    postValidateRegistrationAndSendOtp,
    postMobileNumberVerifyAndOtp,
    getUniqueUserName,
    postRegistrationData,
    postOtpSubmit,
    postResendOtp,
    postVerifyEmail,
    postSetUserData,
    submitEmailOtp,
  };
}
