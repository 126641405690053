// Paths
import { routeConstants } from '../constants';

const register = async () => {
  const { Registration } = await import(
    '../components/pages/registration/registration'
  );
  return { Component: Registration };
};

const authRoutes = [
  {
    path: routeConstants.REGISTRATION,
    async lazy() {
      return register();
    },
  },
  {
    path: routeConstants.REGISTER_SHAREABLE_LINK,
    async lazy() {
      return register();
    },
  },
  {
    path: routeConstants.REGISTER_PHONE_INVITATION,
    async lazy() {
      return register();
    },
  },
  {
    path: routeConstants.REGISTER_WITH_NO_TOKEN,
    async lazy() {
      return register();
    },
  },
  {
    path: routeConstants.LOGIN,
    async lazy() {
      const { LoginPage } = await import('../components/pages/login/login');
      return { Component: LoginPage };
    },
  },
  {
    path: routeConstants.FORGET_PASSWORD,
    async lazy() {
      const { ForgetPassword } = await import(
        '../components/pages/forgetPassword/forgetPassword'
      );
      return { Component: ForgetPassword };
    },
  },
  {
    path: routeConstants.RESET_PASSWORD,
    async lazy() {
      const { ResetPassword } = await import(
        '../components/pages/resetPassword/resetPassword'
      );
      return { Component: ResetPassword };
    },
  },
];

export default authRoutes;
