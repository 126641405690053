import { API } from '../../constants';
import jwtDecode from 'jwt-decode';

export default function AuthService(httpClient, storageService) {
  const login = async (phone, password) => {
    const data = await httpClient.post(API.LOGIN, {
      phone,
      password,
    });

    storageService.set('user', data);
    return data;
  };

  const forgetPassword = async (phone) => {
    return await httpClient.post(API.FORGET_PASSWORD, { mobile: phone });
  };

  const resetPasswordOtp = async (mobile, otp, isWhatsApp) => {
    return await httpClient.post(API.RESET_OTP, { mobile, otp, isWhatsApp });
  };

  const resendPasswordOtp = async (phone, service) => {
    return await httpClient.post(API.RESEND_OTP, { phone, service });
  };

  const resetPassword = async (mobile, newPassword) => {
    return await httpClient.post(API.RESET_PASSWORD, { mobile, newPassword });
  };

  const anonymousUser = async (country) => {
    await httpClient.post(API.ANONYMOUS_USER, { country });
  };

  const getLocalRefreshToken = () => {
    const user = getCurrentUser();
    return user?.refreshToken;
  };

  const getLocalAccessToken = () => {
    const user = getCurrentUser();
    return user?.token;
  };

  const getCurrentUser = () => {
    return storageService.get('user');
  };

  const updateLocalAccessToken = (token) => {
    const user = getCurrentUser();
    user.token = token;
    storageService.set('user', user);
  };

  const checkAuth = async () => {
    const token = getLocalAccessToken();

    if (!token) return Promise.reject('httpErrorMsg.sessionExpired');
    else {
      const decoded = jwtDecode(token, { complete: true });
      const isExpired = decoded.exp < new Date().getTime() / 1000;

      if (isExpired) {
        const data = await httpClient.post(API.REFRESH_TOKEN, {
          refreshToken: getLocalRefreshToken(),
        });

        updateLocalAccessToken(data.token);
      }

      return Promise.resolve({ token });
    }
  };

  const removeLocalUser = async () => {
    storageService.remove('user');
  };

  const getJwt = async () => {
    return getCurrentUser()?.token;
  };

  const logout = async (logoutToken) => {
    return await httpClient.put(API.LOGOUT, { logoutToken });
  };

  return Object.freeze({
    login,
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getCurrentUser,
    checkAuth,
    removeLocalUser,
    getJwt,
    forgetPassword,
    resetPasswordOtp,
    resetPassword,
    resendPasswordOtp,
    anonymousUser,
    logout,
  });
}
