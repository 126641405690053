import ContactInfo from './contactInfo';
import CustomerService from './customerService';
import MazadatLink from './mazadatLinks';

export default function MazadatInfo({
  CustomerServiceLinks,
  ContactInfoLinks,
  MazadatLinks,
}) {
  return (
    <div className="footer-mobile__info">
      <ContactInfo {...{ ContactInfoLinks }} />
      <CustomerService {...{ CustomerServiceLinks }} />
      <MazadatLink {...{ MazadatLinks }} />
    </div>
  );
}
