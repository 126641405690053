import QRCode from 'react-qr-code';
import config from '../../../config';
import { translate } from '../../../app/i18n';
import { AppStore, GooglePlay } from '../../atoms';
import { ImageAssets } from '../../../assets/assetProvider';
import { useSelector } from 'react-redux';

export default function DownloadAppContent() {
  const { websiteLanguage } = useSelector((state) => state.user);
  return (
    <div className="download-app-web">
      <figure className="download-app-web__img">
        <img
          loading="lazy"
          src={ImageAssets.downloadPopUpIllustration[websiteLanguage]}
          alt="mobile"
        />
      </figure>
      <div className="download-app-web__content">
        <h2 className="title">{translate('downloadPopup.content')}</h2>
        <div className="download-app-web__content__buttons">
          <div className="download-app-web__download-buttons">
            <AppStore />
            <GooglePlay />
          </div>
          <div className="download-app-web__qr">
            <QRCode value={config.MAZADAT_APP_REDIRECTION} size="86" />
          </div>
        </div>
      </div>
    </div>
  );
}
