import { useState } from 'react';
import { ButtonTransparentWithIcon } from '../../molecules';
import { SharePopup } from '../../templates';
import { translate } from '../../../app/i18n';
import { Share } from '..';

export default function ShareIcon({ label, shareLink, shareClass }) {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const handleShowPopUp = () => {
    setShowSharePopup(true);
  };
  const handleClosePopUp = () => setShowSharePopup(false);
  return (
    <>
      {label ? (
        <ButtonTransparentWithIcon
          icon="icon-share"
          text={label}
          onClick={handleShowPopUp}
          ariaLabel="share"
        />
      ) : (
        <Share {...{ handleShowPopUp, shareClass }} />
      )}
      <SharePopup
        link={shareLink}
        show={showSharePopup}
        onHide={handleClosePopUp}
        title={translate('sharePopup.shareThisPost')}
      />
    </>
  );
}
