import { translate } from '../../../app/i18n';

export default function Widgets({ title, src }) {
  //@TODO:  translations should be moved out of the component.
  return (
    <div className="widget-item">
      <img loading="lazy" alt={title} className="" src={src} />

      <div className="widget-item__content">
        <h4>{translate(`widgets.${title}`)}</h4>

        <p>{translate(`widgets.${title}Des`)}</p>
      </div>
    </div>
  );
}
