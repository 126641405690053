import ContactInfo from './contactInfo/contactInfo';
import CustomerService from './customerService/customerService';
import DownloadApp from './downloadApp/downloadApp';
import Mazadat from './mazadat/mazadat';
import SocialMedia from './socilaMedia/socialMedia';

function MazadatInfo({ CustomerServiceLinks, ContactInfoLinks, MazadatLinks }) {
  return (
    <div className="mazadatInfo-wrapper">
      <div className="mazadatInfo-smallWrapper">
        <SocialMedia />
        <ContactInfo {...{ ContactInfoLinks }} />
        <CustomerService {...{ CustomerServiceLinks }} />
        <Mazadat {...{ MazadatLinks }} />
        <DownloadApp />
      </div>
    </div>
  );
}

export default MazadatInfo;
