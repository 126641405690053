import Switch from 'react-switch';

export default function CustomSwitch({ isChecked, onChange, label, name }) {
  return (
    <div className="custom-switch">
      <div className="custom-switch__label">{label}</div>
      <Switch
        name={name}
        className={`custom-switch__input checked--${isChecked}`}
        onChange={onChange}
        checked={isChecked}
        handleDiameter={10}
        uncheckedIcon={false}
        checkedIcon={false}
        height={10}
        width={40}
      />
    </div>
  );
}
