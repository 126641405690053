import { ImageAssets } from '../../../assets/assetProvider';

export default function FeedbackImg({ imgSrc }) {
  return (
    <div className="feedback-Img">
      <div className="feedback-Img__light-overlay">
        <img loading="lazy" src={ImageAssets.lightOverlayIllustration} alt="light overlay" />
        <div className="top-img">
          <img src={imgSrc} alt="feedback" />
        </div>
      </div>
    </div>
  );
}
