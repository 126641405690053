import { API } from '../constants';

export default function VerifyRecaptchaTokenService(httpClient) {
  const verifyToken = (token) => {
    return httpClient.post(API.VERIFY_RECAPTCHA_TOKEN, {
      recaptchaToken: token,
    });
  };
  return { verifyToken };
}
