import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSearch: false,
  showMegaMenu: true,
  showBottomMenu: true,
};

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.showSearch = payload;
    },
    setMegaMenu(state, { payload }) {
      state.showMegaMenu = payload;
    },
    setBottomMenu(state, { payload }) {
      state.showBottomMenu = payload;
    },
  },
});

export const { setBottomMenu, setMegaMenu, setSearch } = overlaySlice.actions;

export default overlaySlice.reducer;
