import PropTypes from 'prop-types';
import { IconAssets } from '../../../assets/assetProvider';

/**
 * Toggle Button
 * @param {('small' | 'medium' | 'large' | 'extra-large')} [size = 'medium'] - button size
 * @param {('button' | 'submit' | 'link')} [type = 'button'] - button type
 * @param {boolean} [fullWidth = false] - button size
 * @param {string} [className] - class name
 * @param {boolean} [disabled] - button state
 * @param {boolean} [isActive] - button state
 * @param {string} label - button label
 * @param {() => void} [onClick] - button click
 * @returns {JSX.Element}
 * @constructor
 */
export default function ToggleButton({
  size,
  type,
  fullWidth,
  className,
  disabled,
  isActive,
  label,
  onClick,
}) {
  return (
    <button
      disabled={disabled}
      className={`
        toggle-button
        toggle-button--${size}
        ${className && className}
        ${fullWidth && 'toggle-button--fullWidth'}
        ${isActive ? `toggle-button--primary` : `toggle-button--inactive`}
      `}
      onClick={onClick}
      type={type}
      aria-label={label}
    >
      {label}
      {isActive && <img alt={`checkmark`} src={IconAssets.checkCircleIcon} />}
    </button>
  );
}

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'link']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  isActive: false,
  type: 'button',
  size: 'small',
  className: '',
  fullWidth: false,
};
