import { Fragment } from 'react';
import { Divider } from '../../../../../atoms';
import { inputLanguageDetector } from '../../../../../../app/helper/general';

function boldWordInString(string, word) {
  const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp('(' + escapedWord + ')', 'gi');

  const boldedString = string.replace(regex, '<b>$1</b>');

  return boldedString;
}

const SearchResults = ({ onItemClick, searchResults, searchInput }) => {
  const handleOnClickItem = (keyword) => {
    onItemClick(keyword);
  };

  return (
    <>
      <div
        className={`search-results-items ${
          searchResults && 'search-results-items-spacing'
        }`}
      >
        {searchResults.map((item, index) => {
          const boldedString = boldWordInString(item, searchInput);

          return (
            <Fragment key={index}>
              <div
                className={`search-item ${inputLanguageDetector(searchInput)}`}
                onClick={() => handleOnClickItem(item)}
                dangerouslySetInnerHTML={{ __html: boldedString }}
              ></div>
              {index < searchResults.length - 1 ? (
                <Divider
                  orientation={'horizontal'}
                  thickness={0.5}
                  spacing={0}
                />
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default SearchResults;
