import styled from 'styled-components';
import { ImageAssets } from '../../../assets/assetProvider';

export const SectionWrapper = styled.div`
  padding: 60px 75px;
  display: flex;
  align-items: center;
  text-align: center;
  background: url(${ImageAssets.letUsTalkBg}) no-repeat,
    -webkit-linear-gradient(180deg, ${(props) => props.theme.colors.ON_CIRCLE_BACKGROUND_4}, ${(
            props,
          ) => props.theme.colors.ON_CIRCLE_BACKGROUND_2}
          100%);
  color: ${(props) => props.theme.colors.BACKGROUND};
  @media screen and (max-width: 912px) {
    padding: 30px;
  }
`;
export const SectionDetails = styled.div`
  width: 100vw;
`;

export const SectionTitle = styled.h2`
  font-size: ${(props) => props.theme.typography.FS_48};
  font-weight: bold;
  margin-bottom: 10px;
  @media screen and (max-width: 912px) {
    font-size: ${(props) => props.theme.typography.FS_24};
  }
`;
export const SectionSubtitle = styled.p`
  font-size: ${(props) => props.theme.typography.FS_18};
  font-weight: 500;
  margin-bottom: 30px;
`;
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;
export const InfoBtn = styled.a`
  color: ${(props) => props.theme.colors.BACKGROUND};
  font-size: ${(props) => props.theme.typography.FS_24};
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: solid 1.5px ${(props) => props.theme.colors.BACKGROUND};
  transition: 200ms;

  &:hover {
    color: ${(props) => props.theme.colors.BACKGROUND};
    border-color: transparent;
  }
  @media screen and (max-width: 560px) {
    font-size: ${(props) => props.theme.typography.FS_18};
  }
`;
export const InquiryBtn = styled.a`
  color: ${(props) => props.theme.colors.BACKGROUND};
  font-size: ${(props) => props.theme.typography.FS_14};
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
