import { translate } from '../../../../../../app/i18n';

const CustomerService = ({ CustomerServiceLinks }) => {
  return (
    <div className='customerService-wrapper'>
      <h5>{translate('footer.customerService')}</h5>
      <CustomerServiceLinks />
    </div>
  );
};

export default CustomerService;
