import { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonTransparentWithIcon } from '../../molecules';
import { DownloadPopup } from '../../templates';
import { translate } from '../../../app/i18n';
import TooltipComponent from './../../styled-atoms/tooltip/tooltip';
import { useNavigate } from 'react-router-dom';
import { IconAssets } from '../../../assets/assetProvider';

export default function SellerList({
  imgSrc,
  name,
  imgSize,
  isVerified,
  id,
  postData,
  ...props
}) {
  const [showDownload, setShowDownload] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);
  const navigate = useNavigate();
  const VerfiedSeller = () =>
    isVerified && (
      <div className="seller-list__Verified">
        <span className="icon-Verified"></span>
        <p>{translate('aboutSellerView.verifiedSeller')}</p>
        <div className="Tooltip_hint">
          <TooltipComponent
            text={translate('aboutSellerView.verifiedSeller')}
          />
        </div>
      </div>
    );

  const ViewProfile = () => (
    <div className="seller-list__actions">
      <ButtonTransparentWithIcon
        onClick={() => navigate(`/sellerProfile/${id}`)}
        className=" seller-list__view-profile"
        icon={'icon-profile'}
        text={translate('aboutSellerView.viewProfile')}
      />
      <span className=" seller-list__Chat">
        <img src={IconAssets.messagesIcon} alt="chatIcon" />
        <ButtonTransparentWithIcon
          onClick={() => setShowDownload(true)}
          icon={''}
          text={translate('aboutSellerView.chatWithSeller')}
        />
      </span>
    </div>
  );
  return (
    <>
      <div className="seller-list">
        <div className="seller-list__container">
          <figure className="seller-list__img-container">
            <img
              loading="lazy"
              className={`img-${imgSize}`}
              src={imgSrc}
              alt="seller"
              onClick={() =>
                navigate(`/sellerProfile/${id}`, {
                  state: { title: postData?.title, esId: postData?.esId },
                })
              }
            />
          </figure>
          <div className="seller-list__content">
            <div className="seller-list__start-container">
              <p
                onClick={() =>
                  navigate(`/sellerProfile/${id}`, {
                    state: { title: postData?.title, esId: postData?.esId },
                  })
                }
                className="seller-list__name"
              >
                {name}
              </p>
              <VerfiedSeller />
            </div>
            <div className="seller-list__end-container">
              <ViewProfile />
              <p className="seller-list__description">{props.children}</p>
            </div>
          </div>
        </div>
      </div>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </>
  );
}

SellerList.propTypes = {
  imgSize: PropTypes.oneOf(['small', 'medium']),
};
