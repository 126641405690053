import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from '../services';
import { useDispatch, useSelector } from 'react-redux';
import { snackboxOpen } from '../redux/actions/snackboxAction';
import { translate } from '../app/i18n';
import { queryKeys } from '../constants';
import { postSort } from '../constants/general';
import events from '../utils/events';
import { addWatchlistStatus } from '../redux/actions/watchlistStatusActions';

export function useFeaturedPostQuery() {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['posts', 'featured'],
    queryFn: () => services.postService.fetchFeaturedPosts(),
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}

export function useHomePostsQuery() {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['home', 'dynamicEndpoint'],
    queryFn: () => services.homeService.getHomePosts(),
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}

export function useWatchlistQuery() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  return useQuery({
    queryKey: ['watchlist'],
    queryFn: () => services.postService.getWatchList(),
    keepPreviousData: true,
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
    enabled: !!isAuthenticated,
  });
}

export function useAddToWatchlist(id) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => services.postService.addToWatchList(id),
    mutationKey: ['watchList', 'add', { id }],
    onSuccess: () => {
      queryClient.invalidateQueries(['watchlist']);
      dispatch(addWatchlistStatus());
      dispatch(
        snackboxOpen(
          translate('userFeedback.watchlist.addedSuccessfully'),
          'success',
        ),
      );
    },
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}

export function useRemoveFromWatchlist(id) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => services.postService.removeFromWatchList(id),
    mutationKey: ['watchList', 'remove', { id }],
    onSuccess: () => {
      queryClient.invalidateQueries(['watchlist']);
      dispatch(
        snackboxOpen(
          translate('userFeedback.watchlist.removedSuccessfully'),
          'success',
        ),
      );
    },
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}

export function useGetPostByCategory(id, limit, page, sort, fields) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.getPostByCategory(
      {
        categoryId: id,
        limit,
        page,
        sort: postSort[sort],
      },
      fields,
    ),
    services.postService.getPostByCategory,
    {
      onSuccess: (response) =>
        response?.results?.length &&
        events.logPostList(page, id, response?.results),
    },
    {
      enabled: !!id,
      keepPreviousData: true,
      onError({ message }) {
        dispatch(
          snackboxOpen(
            message || translate(`userFeedback.httpErrorMsg.somethingWrong`),
            'failure',
          ),
        );
      },
    },
  );
}

export function useGetPostByKeyword(keyword, limit, page, sort, fields) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.searchByKeyword(
      {
        keyword: keyword,
        limit,
        page,
        sort: postSort[sort],
      },
      fields,
    ),
    services.postService.searchByKeyword,
    {
      onSuccess: (response) =>
        response?.results?.length && events.logSearchResults(keyword),
    },
    {
      enabled: !!keyword,
      keepPreviousData: true,
      onError({ message }) {
        dispatch(
          snackboxOpen(
            message || translate(`userFeedback.httpErrorMsg.somethingWrong`),
            'failure',
          ),
        );
      },
    },
  );
}

export function useGetDynamicSections(postKey, setData) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.getDynamicSections(),
    services.postService.getDynamicSection,
    {
      onSuccess: (sectionData) => {
        const section =
          sectionData.filter((data) => data.key === postKey)[0] || null;
        if (section) {
          setData(section);
        } else {
          setData((prev) => ({ ...prev, error: true }));
          dispatch(
            snackboxOpen(
              translate(`userFeedback.httpErrorMsg.somethingWrong`),
              'failure',
            ),
          );
        }
      },
      onError: ({ message }) => {
        dispatch(
          snackboxOpen(
            message || translate(`userFeedback.httpErrorMsg.somethingWrong`),
            'failure',
          ),
        );
      },
    },
  );
}

export function useGetSectionPosts(postKey, url, limit, page) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.getSectionPostByKey(postKey, {
      url,
      limit,
      page,
    }),
    services.postService.getPostsBySectionKey,
    {
      enabled: !!url,
      keepPreviousData: true,
      onError: ({ message }) => {
        dispatch(
          snackboxOpen(
            message || translate(`userFeedback.httpErrorMsg.somethingWrong`),
            'failure',
          ),
        );
      },
    },
  );
}

export function useGetCategoryFeaturedPosts(id) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.getCategoryFeaturedPosts(id),
    services.postService.getCategoryFeaturedPosts,
    {
      onError: ({ message }) => {
        dispatch(snackboxOpen(message, 'failure'));
      },
    },
  );
}

export function useGetSearchFeaturedPosts(keyword) {
  const dispatch = useDispatch();
  return useQuery(
    queryKeys.getSearchFeaturedPosts(keyword),
    services.postService.getSearchFeaturedPosts,
    {
      onError: ({ message }) => {
        dispatch(snackboxOpen(message, 'failure'));
      },
    },
  );
}

export function usePostDetailsQuery(id) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['post', { id }],
    queryFn: () => services.postService.fetchPostDetails({ id }),
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
    enabled: id !== null,
  });
}

export function useGetShoppingCartCount(setCount) {
  const dispatch = useDispatch();
  return useQuery(
    ['shoppingCart', 'count'],
    services.postService.getShoppingCartCount,
    {
      onSuccess: (data) => setCount(data),
      onError: ({ message }) => {
        dispatch(snackboxOpen(message, 'failure'));
      },
    },
  );
}

export function useGetNotificationCount(setCount) {
  const dispatch = useDispatch();
  return useQuery(
    ['notifications', 'count'],
    services.postService.getNotificationCount,
    {
      onSuccess: (data) => setCount(data.filter((item) => !item.read)),
      onError: ({ message }) => {
        dispatch(snackboxOpen(message, 'failure'));
      },
    },
  );
}

export function useGetActions() {
  const dispatch = useDispatch();
  return useQuery(['actions', 'count'], services.postService.getActions, {
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}
