import { Dot, DotWrapper, PaginationWrapper } from './Pager.styled';
import { PagerButton, PagerLabel } from '../../atoms';
import Proptypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { PRIMARY } from '../../../app/theme/colors';

function Pager({
  currentPage,
  pageCount,
  onNext,
  onPrevious,
  hideLabel,
  isLoading,
  isRefetching,
  onPageChange,
  type,
}) {
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < pageCount;

  return isLoading || isRefetching ? (
    <div className={`content-center`}>
      <Loader
        type={'Grid'}
        radius={12}
        width={40}
        height={40}
        color={PRIMARY[`800`]}
        secondaryColor={PRIMARY['200']}
        style={{
          overflow: `hidden`,
          width: `25px`,
          height: `25px`,
        }}
      />
    </div>
  ) : type === 'pager' ? (
    <PaginationWrapper>
      <PagerButton
        action={() => {
          if (onPrevious) onPrevious();
          window.scroll(0, 0);
        }}
        type={`Previous`}
        disable={!hasPrevious}
      />
      {hideLabel ? null : (
        <PagerLabel currentPage={currentPage} pageCount={pageCount} />
      )}
      <PagerButton
        action={() => {
          if (onNext) onNext();
          window.scroll(0, 0);
        }}
        type={`Next`}
        disable={!hasNext}
      />
    </PaginationWrapper>
  ) : (
    <DotWrapper>
      {Array.from(Array(pageCount))
        .map((_, i) => i + 1)
        .map((num, index) => (
          <Dot
            key={index}
            active={num === currentPage}
            onClick={() => {
              if (onPageChange) onPageChange(num);
            }}
          />
        ))}
    </DotWrapper>
  );
}

Pager.propTypes = {
  currentPage: Proptypes.number,
  pageCount: Proptypes.number,
  onNext: Proptypes.func,
  onPrevious: Proptypes.func,
  onPageChange: Proptypes.func,
  hideLabel: Proptypes.bool,
  isLoading: Proptypes.bool,
  isRefetching: Proptypes.bool,
  type: Proptypes.oneOf(['pager', 'dot']),
};

Pager.defaultProps = {
  type: 'pager',
};

export default Pager;
