// eslint-disable-next-line no-extend-native
String.prototype.toCamelCase = function () {
  return this.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};
String.prototype.toNumberFormat = function () {
  return Number(this).toLocaleString('en-US');
};
Number.prototype.toNumberFormat = function () {
  return this.toLocaleString('en-US');
};
