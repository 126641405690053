import { Item } from './SwiperItem.styled';
import PropTypes from 'prop-types';
import { memo } from 'react';

function SwiperItem({ children, width, height, itemStyle }) {
  return (
    <Item
      style={{
        ...itemStyle,
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
      }}
      draggable="false"
    >
      {children}
    </Item>
  );
}

SwiperItem.propTypes = {
  children: PropTypes.element,
  width: PropTypes.number,
  height: PropTypes.number,
  itemStyle: PropTypes.object,
  reRender: PropTypes.bool,
};

export default memo(SwiperItem, (prevProps, nextProps) => {
  if (prevProps?.reRender) return false;
  const equalHeight = prevProps.height === nextProps.height;
  const equalWidth = prevProps.width === nextProps.width;
  return equalWidth && equalHeight;
});
