import AuthHeader from './header/authHeader';
import AuthFooter from './footer/authFooter';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routeConstants } from '../../../constants';

export default function Index({ children }) {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return <Navigate to={routeConstants.HOME} />;
  }

  return (
    <div className="auth-layout">
      <AuthHeader />
      {children}
      <AuthFooter />
    </div>
  );
}

Index.propTypes = {
  children: PropTypes.node,
};
