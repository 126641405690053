import { LogoAssets } from '../../../assets/assetProvider';

export default function PaymentMethods({ className }) {
  return (
    <div className={className && className}>
      <div className="visa-icon payment-icon">
        <img loading="lazy" alt="visa icon" src={LogoAssets.visaLogo} />
      </div>
      <div className="mastercard-icon payment-icon">
        <img loading="lazy" alt="mastercard icon" src={LogoAssets.masterCardLogo} />
      </div>
      <div className="fawry-icon payment-icon">
        <img loading="lazy" alt="fawry icon" src={LogoAssets.fawryLogo} />
      </div>
      <div className="valu-icon payment-icon">
        <img loading="lazy" alt="valu icon" src={LogoAssets.valuLogo} />
      </div>
    </div>
  );
}
