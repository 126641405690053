import { PostCardPrices, PostCardTitleWithInfo } from '../../molecules';
import PropTypes from 'prop-types';

const PostCardInfo = ({ cardInfoObj, cardClass, themeColor }) => {
  return (
    <div
      className={`post-card-info post-card-info--${themeColor} ${cardClass}-card-info`}
    >
      {/* change this name */}
      <PostCardTitleWithInfo
        quantityText={cardInfoObj.quantityText}
        title={cardInfoObj.title}
        discount={cardInfoObj.discount}
        biddingCount={cardInfoObj.biddingCount}
        {...{ themeColor, cardClass }}
      />
      <PostCardPrices
        {...{
          themeColor,
          cardClass,
          biddingPrice: cardInfoObj.biddingPrice,
          cardPrice: cardInfoObj.price,
        }}
      />
    </div>
  );
};

PostCardInfo.propTypes = {
  themeColor: PropTypes.oneOf(['primary', 'secondary', 'light']),
};

PostCardInfo.defaultProps = {
  themeColor: 'light',
};

export default PostCardInfo;
