import toast from 'react-hot-toast';
import services from '../../services';
import {
  loading,
  postActionSuccess,
  postActionFailure,
  getPromotionOptionsSuccess,
  getPromotionOptionsFailure,
  postExpiry,
} from '../slices/postSlice';
import { fetchPosts } from './postsAction';
import { translate } from '../../app/i18n';
export const takePostOffline = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostOffline(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const takePostArchive = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostArchive(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const repost = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostRepost(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const restock = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostRestock(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const updatePromotionPlan = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostPromotion(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const applyDiscount = (body, id) => async (dispatch) => {
  try {
    dispatch(loading());
    let { message } = await services.postService.putPostDiscount(body, id);
    toast.success(translate('postActions.done'));
    dispatch(postActionSuccess(message));
    dispatch(fetchPosts());
  } catch (error) {
    toast.error(error.message);
    dispatch(postActionFailure(error.message));
  }
};

export const fetchAllPromotionOptions = () => async (dispatch) => {
  try {
    dispatch(loading());
    let data = await services.postService.getAllPromotionOptions();
    dispatch(getPromotionOptionsSuccess(data));
  } catch (error) {
    dispatch(getPromotionOptionsFailure(error));
  }
};

export const sharePost = (id) => async (dispatch) => {
  try {
    dispatch(loading());
    const { link } = await services.postService.sharePost(id);
    dispatch(postActionSuccess(link));
  } catch (error) {
    dispatch(postActionFailure(error.message));
  }
};

export const clonePost = (id, location) => async (dispatch) => {
  try {
    dispatch(loading());
    await services.postService.clonePost(id, location);
    dispatch(postActionSuccess());
  } catch (error) {
    dispatch(postActionFailure(error.message));
  }
};

export const setPostExpiry = (isPostExpired) => async (dispatch) => {
  dispatch(postExpiry(isPostExpired));
};
