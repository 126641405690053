export const routeConstants = {
  HOME: '/',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgetPassword',
  RESET_PASSWORD: '/resetPassword',
  SELLER_PROFILE: '/sellerProfile/:id',
  POST_WITH_ID_AND_TITLE: '/post/:title/:id',
  POST_WITH_QUERY_OBJ: '/post',
  POST_WITH_ID: '/post/:id',
  POST_LIST_BY_CATEGORY_NAME_AND_ID: `/category/:name/:id`,
  POST_LIST_BY_CATEGORY_NAME_AND_QUERY_OBJ: `/category/:name`,
  SEARCH_RESULT_AND_KEYWORD: `/search/:keyword`,
  SEARCH_RESULT_AND_QUERY_OBJ: `/search`,
  SECTION_POSTS: '/posts/:postKey',
  LEGAL_TERMS: '/legal/terms',
  // @TODO : needs refactor -> replace all camelCase to separated with -
  // such as .. TermsAndConditions --> terms-and-conditions
  TERMS_CONDITIONS: '/legal/terms/TermsAndConditions',
  FEES_POLICY: '/legal/terms/FeesPolicy',
  PRIVACY_POLICY: '/legal/policy',
  PRODUCT_POLICY: '/legal/terms/productPolicy',
  ITEM_CONDITION_GUIDE: '/legal/terms/itemConditionGuid',
  BUSINESS_REGISTRATION_POLICY: '/legal/terms/BusinessRegistrationPolicy',
  REGISTRATION: '/registration',
  REGISTER_SHAREABLE_LINK: '/registerUnverified',
  REGISTER_PHONE_INVITATION: '/registerByPhone',
  REGISTER_WITH_NO_TOKEN: '/signup',
  CONTACT_US: '/contact-us',
  ABOUT_US: '/about-us',
  HOW_IT_WORKS: '/how-it-works',
  CATEGORIES: '/categories',
  COMING_SOON: '/coming-soon',
  WATCHLIST: '/watchlist',
};
