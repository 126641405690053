import MazadatInfo from './mazadatInfo';
import DownloadOurApp from './downloadOurApp';
import Payment from './payment';

export default function FooterMobile(props) {
  return (
    <div className="footer-mobile">
      <MazadatInfo {...props} />
      <DownloadOurApp />
      <Payment />
    </div>
  );
}
