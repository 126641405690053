import PropTypes from 'prop-types';

export default function ButtonTransparentWithIcon({
  text,
  icon,
  onClick,
  type,
  className,
  disabled,
  ariaLabel,
}) {
  return (
    <button
      disabled={disabled}
      className={`
                button-transparent-with-icon
                ${className && className}
            `}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
    >
      {icon !== '' && <span className={`button-transparent-with-icon__icon ${icon}`}></span>}
      <span className='button-transparent-with-icon__text'>{text}</span>
    </button>
  );
}

ButtonTransparentWithIcon.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'link']),
  onClick: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

ButtonTransparentWithIcon.defaultProps = {
  type: 'button',
  fullWidth: false,
};
