import styled from 'styled-components';
import {
  BACK_DROP,
  BORDER,
  BOX_SHADOW,
  PRIMARY,
  SURFACE,
} from '../../../app/theme/colors';
import { FS_12, FS_14, FS_18 } from '../../../app/theme/typography';
import { breakpoints } from '../../../constants/general';

export const Root = styled.button`
  position: relative;
  text-align: left;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  gap: 8px;
  font-size: ${FS_12};
  background-color: transparent;
  font-weight: 600;
  transition: 200ms;
  text-transform: capitalize;
  padding: 8px 0;

  span.sort-label {
    color: ${PRIMARY['500']};
  }

  img.sort-icon {
    display: none;
  }

  span#sort-control-icons {
    padding: 10px;
  }

  @media screen and ${breakpoints.tablet_max_width} {
    padding: 0;
  }

  @media screen and ${breakpoints.mobile_max_width} {
    span.sort-label,
    span#sort-control-icons {
      display: none;
    }

    img.sort-icon {
      display: block;
    }
  }
`;

export const Icon = styled.span`
  font-size: ${FS_18};
`;

export const BackDrop = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${BACK_DROP};
  z-index: 700;

  @media screen and ${breakpoints.mobile_max_width} {
    display: block;
  }
`;

export const DropDownList = styled.div`
  position: absolute;
  min-width: 200px;
  min-height: 50px;
  background-color: ${SURFACE};
  right: ${(props) => (props.lang === `en` ? `0` : undefined)};
  left: ${(props) => (props.lang === `en` ? undefined : `0`)};
  top: 50px;
  border-radius: 10px;
  z-index: 700;
  box-shadow: 0 0 20px 5px ${BOX_SHADOW};
  padding: 10px 0;

  &::before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: -10px;
    right: ${(props) => (props.lang === `en` ? `20px` : undefined)};
    left: ${(props) => (props.lang === `en` ? undefined : `20px`)};
    border: 5px solid transparent;
    border-top: 0;
    border-bottom: 10px solid ${SURFACE};
  }

  @media screen and ${breakpoints.mobile_max_width} {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: unset;
    border-radius: 10px 10px 0 0;
    padding: 40px 0 20px;
    font-size: ${FS_14};
    font-weight: 500;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      width: 28px;
      height: 4px;
      display: block;
      position: absolute;
      top: 20px;
      left: unset;
      right: unset;
      border-radius: 2px;
      background-color: ${BORDER};
      align-self: center;
      border: unset;
    }
  }
`;
