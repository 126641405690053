import { createSlice } from '@reduxjs/toolkit';
import Fingerprint from '../../services/Fingerprint';

const initialState = {
  id: null,
  refreshToken: null,
  token: null,
  language: 'en',
  error: null,
  isDataLoading: false,
  isAuthenticated: null,
  setAnonymousUserSuccess: false,
  fingerprint: Fingerprint.init(),
  logoutToken: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fingerprint: (state, action) => ({
      ...state,
      fingerprint: action.payload.uuid,
    }),
    loading: (state) => ({ ...state, isDataLoading: true }),
    loginSuccess: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        language: action.payload.language,
        logoutToken: action.payload.logoutToken,
        isAuthenticated: true,
        setAnonymousUserSuccess: false,
        isDataLoading: false,
        error: null,
      };
    },
    loginFailed: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
        isDataLoading: false,
      };
    },
    logoutSuccess: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
    logoutFailed: (state, action) => {
      return {
        ...state,
        isDataLoading: false,
        error: action.payload.error,
      };
    },
    authSuccess: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        language: action.payload.language,
        logoutToken: action.payload.logoutToken,
        isAuthenticated: true,
        isDataLoading: false,
        error: null,
      };
    },
    anonymousUserSuccess: (state) => {
      return {
        ...state,
        ...initialState,
        setAnonymousUserSuccess: true,
      };
    },
    anonymousUserFailed: (state, action) => {
      return {
        ...state,
        ...initialState,
        isDataLoading: false,
        error: action.payload.error,
      };
    },
    destroySession: (state) => {
      return {
        ...state,
        ...initialState,
        isAuthenticated: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loading,
  loginSuccess,
  loginFailed,
  destroySession,
  authSuccess,
  fingerprint,
  logoutFailed,
  anonymousUserSuccess,
  anonymousUserFailed,
} = authSlice.actions;

export default authSlice.reducer;
