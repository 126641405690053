import { translate } from '../../../app/i18n';
import { ShareInput } from '../../atoms';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

export default function ShareContent(props) {
  return (
    <div className="share-content">
      <div className="socials">
        <FacebookShareButton url={props.link}>
          <span className="icon-facebook"></span>
        </FacebookShareButton>
        <TwitterShareButton url={props.link}>
          <span className="icon-twitter"></span>
        </TwitterShareButton>
        <LinkedinShareButton url={props.link}>
          <span className="icon-linkedin"></span>
        </LinkedinShareButton>
        <WhatsappShareButton url={props.link}>
          <span className="icon-whatsapp"></span>
        </WhatsappShareButton>
      </div>
      <div className="share-content__subtitle">
        <p>{translate('sharePopup.copyLink')}</p>
      </div>
      <ShareInput {...props} />
    </div>
  );
}
