import { Fragment, useEffect, useState } from 'react';
import { AnswerList } from '..';
import { formateDiffTime } from '../../../app/helper/formaters';
import { translate } from '../../../app/i18n';
import ButtonTransparentWithIcon from './../../molecules/buttonTransparentWithIcon/buttonTransparentWithIcon';
import DownloadPopup from '../../templates/downloadApp/downloadApp';
import { getAvatarUrl } from '../../../app/helper/general';

export default function QuestionList({ data }) {
  const [answers, setAnswers] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);

  useEffect(() => {
    const { answer } = data;
    if (answer.length <= 1) {
      setAnswers(answer);
    } else if (answer?.length > 1) {
      setAnswers(answer?.slice(0, 1));
    }
  }, [data]);

  return (
    <>
      <div className="question-list">
        <div className="question-list__avatar">
          <img
            src={getAvatarUrl(data.user.avatar)}
            alt="avatar"
            loading="lazy"
          />
          <span className="question-list__small-Text__name">
            {data.user.username}
          </span>
        </div>

        <div className="question-list__container">
          <div className="question-list__box">
            <p>{data.question}</p>
          </div>
          <small className="question-list__small-Text">
            <ButtonTransparentWithIcon
              text={translate('postDetailsPage.reply')}
              onClick={() => setShowDownload(true)}
              type="button"
              className="question-list__reply devider"
              ariaLabel='reply'
            />

            <span className="question-list__small-Text__time">
              {formateDiffTime(data.createdAt)}
            </span>
          </small>
        </div>

        <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
      </div>
      {!answers ? (
        'Loading...'
      ) : (
        <>
          {!showAnswers && answers?.length > 0 && (
            <div className="question-list__answers">
              <div className="question-list__answers--avatar">
                {answers?.map((data, index) => {
                  return index <= 2 ? (
                    <div
                      key={index}
                      className="question-list__avatar reply-border"
                    >
                      <img
                        loading="lazy"
                        src={getAvatarUrl(data?.user?.avatar)}
                        alt="avatar"
                      />
                    </div>
                  ) : null;
                })}
                {answers?.length - 2 > 0 && (
                  <span className="remaining-answers">
                    {' '}
                    {'+'}
                    {Number(answers?.length - 2).toNumberFormat()}
                  </span>
                )}
              </div>
              <ButtonTransparentWithIcon
                text={translate('postDetailsPage.viewReplies')(answers.length)}
                icon=""
                onClick={() => setShowAnswers(true)}
                className="question-list__answers--viewMore"
                ariaLabel='view more'
              />
            </div>
          )}

          {showAnswers && (
            <div className="question-list__answers--list">
              {answers?.map((answer, index) => (
                <Fragment key={index}>
                  <AnswerList
                    data={answer}
                    lastAnswer={answers?.length - 1 === index}
                    length={answers?.length}
                  />
                </Fragment>
              ))}

              <ButtonTransparentWithIcon
                text={translate('postDetailsPage.hideReplies')}
                icon=""
                onClick={() => setShowAnswers(false)}
                className="question-list__answers--hideReplies"
                ariaLabel='hide'
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
