import { routeConstants } from '../constants';

const PrivateRoutes = [
  {
    path: routeConstants.WATCHLIST,
    async lazy() {
      const { Watchlist } = await import(
        '../components/pages/watchlist/Watchlist'
      );
      return { Component: Watchlist };
    },
  },
];

export default PrivateRoutes;
