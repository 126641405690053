import { AddToWatchList, PostIcon, PostLabel, Views } from '../../atoms';
import { postConditionMapper } from '../../../app/helper/mappers';
import { ProgressiveImageLoader } from '../../organisms';
import { useSelector } from 'react-redux';
import { debounce, some } from 'lodash';
import {
  useAddToWatchlist,
  useRemoveFromWatchlist,
  useWatchlistQuery,
} from '../../../hooks/usePostQuery';

const PostCardImage = ({ cardImageObj, watchlistId, handleOnClick }) => {
  let imageLabel = postConditionMapper(cardImageObj.condition);

  const { data: watchList } = useWatchlistQuery();
  const { mutate: addToWatchlist, isLoading: addWatchlistLoading } =
    useAddToWatchlist(watchlistId);
  const { mutate: removeFromWatchlist, isLoading: removeWatchlistLoading } =
    useRemoveFromWatchlist(watchlistId);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const toggleWatchList = () => {
    if (!some(watchList, { _id: watchlistId })) {
      addToWatchlist();
    } else {
      removeFromWatchlist();
    }
  };

  const handleWatchList = () => {
    if (isAuthenticated) {
      toggleWatchList();
    }
  };

  const alreadyInWatchList = some(watchList, { _id: watchlistId });

  const debouncedHandleWatchList = debounce(handleWatchList, 500);

  return (
    <>
      <div className="post-card-image">
        <ProgressiveImageLoader
          routeUrl={handleOnClick}
          image={cardImageObj.imageUrl}
          alt="card-img"
        />
        <div className="post-card-image__labelsIcons-wrapper">
          <div className="post-card-image__labelsIcons-wrapper__label-views-container">
            <PostLabel postCondition={imageLabel} />
            <div className="badge badge--card badge--dark badge--left">
              <Views
                numberOfViews={cardImageObj.totalViews}
                light={true}
                fontSize={10}
                iconSize={12}
              />
            </div>
          </div>
          <div className="post-card-image__labelsIcons-wrapper__icons-container">
            {cardImageObj.isSecured && (
              <PostIcon
                iconBackgroundClass={'post-icon-secure'}
                className={'icon-secure'}
              />
            )}
            <div
              onClick={(e) => {
                // modal onhide does not have event as a param
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <AddToWatchList
                onClick={isAuthenticated ? debouncedHandleWatchList : null}
                size="sm"
                theme={alreadyInWatchList ? 'filled' : 'dark'}
                loading={addWatchlistLoading || removeWatchlistLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCardImage;
