import { socialMedialinks } from '../../../constants';

export default function SocialMediaIcons({ className }) {
  return (
    <div className={className && className}>
      <a
        className="socialMedia-icon"
        href={socialMedialinks.FACEBOOK}
        target="_blank"
        rel="noreferrer"
        aria-label="view mazadat facebook page"
      >
        <span className="icon-facebook"></span>
      </a>
      <a
        className="socialMedia-icon"
        href={socialMedialinks.INSTAGRAM}
        target="_blank"
        rel="noreferrer"
        aria-label="view mazadat instagram"
      >
        <span className="icon-instagram"></span>
      </a>
      <a
        className="socialMedia-icon"
        href={socialMedialinks.YOUTUBE}
        target="_blank"
        rel="noreferrer"
        aria-label="view mazadat youtube channel"
      >
        <span className="icon-youtube"></span>
      </a>
      <a
        className="socialMedia-icon"
        href={socialMedialinks.LINKEDIN}
        target="_blank"
        rel="noreferrer"
        aria-label="view mazadat linkedin"
      >
        <span className="icon-linkedin"></span>
      </a>
    </div>
  );
}
