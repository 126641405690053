import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routeConstants } from '../../../constants';
import { LogoAssets } from '../../../assets/assetProvider';

export default function MazadatLogo() {
  const { websiteLanguage } = useSelector((state) => state.user);

  return (
    <Link onClick={() => window.scrollTo(0, 0)} to={routeConstants.HOME}>
      <img
        src={LogoAssets.mazadatBlackLogos[websiteLanguage]}
        alt="Mazadat"
        width={'120px'}
        loading="lazy"
      />
    </Link>
  );
}
