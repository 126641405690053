import { translate } from '../../../../../app/i18n';
import { PaymentMethods } from '../../../../molecules';

const PaymentSection = () => {
  return (
    <div className="paymentSection">
      <div className="left-side">
        <span> {translate('footer.safePayments')}</span>
        <PaymentMethods className="payments-icons" />
      </div>
      <div className="right-reserved">
        <span>
          <b>{translate('footer.mazadatInc')}</b>
          &nbsp;
          {translate('footer.allRights')}
        </span>
      </div>
    </div>
  );
};

export default PaymentSection;
