import io from 'socket.io-client';
import config from '../config';
import service from '../services';
import { createContext } from 'react';

export const socket = io(`${config.BASE_URL}/api/auction`, {
  timeout: 5000,
  path: `${config.SOCKET_PATH}`,
  withCredentials: true,
  transports: ['websocket'], //@TODO : @Khalifa
  query: {
    'x-auth-token': service.authService.getJwt(),
  },
  reconnectionDelayMax: 1000,
  reconnection: true,
});

export const joinAuction = (auctionId, special) => {
  try {
    socket.emit(
      special ? 'join special auction' : 'join auction',
      {
        auctionId: auctionId,
      },
      (data) => console.log(data, 'join status'),
    );
  } catch ({ message }) {
    //   Pass
  }
};

export const leaveAuction = (auctionId, special) => {
  try {
    this.socket.emit(special ? 'leave special auction' : 'leave auction', {
      auctionId: auctionId,
    });
  } catch ({ message }) {
    //   Pass
  }
};

const onConnect = () => {
  console.log('[Auction Socket] connected...' + socket.id);
};

const disconnect = (err) => {
  console.log('[Auction Socket] error...' + err);
  // .. TODO : Handle error view when socket down
};

export const initSocket = () => {
  socket.connect();
  socket.on('connect', onConnect);
  socket.on('disconnect', disconnect);
};
export const destroySocket = () => {
  socket.off('connect', onConnect);
  socket.off('disconnect', disconnect);
  socket.disconnect();
  socket.close();

  console.log('[Auction Socket] Destroyed...');
};
export const SocketContext = createContext();
