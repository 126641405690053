import { API } from '../constants';
import { getImageType, imageTypes } from '../constants/general';

export default function AllServicePointsService(httpClient) {
  const getServicePoints = (lang) => {
    return httpClient.get(API.GET_SERVICE_POINTS, {
      lang,
      ...getImageType(imageTypes.WEBP),
    });
  };

  const getFavoriteServicePoint = (lang) => {
    return httpClient.get(API.GET_FAVOURITE_SERVICE_POINT, {
      lang,
      ...getImageType(imageTypes.WEBP),
    });
  };

  return { getServicePoints, getFavoriteServicePoint };
}
