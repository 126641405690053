import services from '../../services';
import {
  loading,
  fetchTopFiveCategoriesSuccess,
  fetchTopFiveCategoriesFailure,
} from '../slices/topFiveCategoriesSlice';

export const fetchTopFiveCategories = () => async (dispatch) => {
  try {
    dispatch(loading());
    let categories =
      await services.topFiveCategoriesService.getTopFiveCategories();
    dispatch(fetchTopFiveCategoriesSuccess(categories));
  } catch ({ message }) {
    dispatch(fetchTopFiveCategoriesFailure(message));
  }
};
