import styled from 'styled-components';
import { PRIMARY } from '../../../app/theme/colors';
import { FS_14, FS_16 } from '../../../app/theme/typography';
import { ImageAssets } from '../../../assets/assetProvider';

function getIcon(name) {
  switch (name) {
    case 'watchlist':
      return ImageAssets.watchlistIllustration;
    case 'favorite':
      return ImageAssets.favoriteIllustration;
    default:
      return ImageAssets.emptySearchIllustration;
  }
}

export const Root = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  justify-content: ${(props) =>
    props.direction === 'row' ? 'flex-start' : 'space-between'};
  background-color: ${(props) =>
    props.background === 'fill' ? PRIMARY[50] : 'transparent'};
  padding: ${(props) => (props.noSpace ? '0' : '30px')};
  gap: 15px;
  border-radius: 15px;
  ${(props) => (props.outerSpace && `margin : ${props.outerSpace}`)}
`;

export const Icon = styled.div`
  width: ${(props) => (props.small ? '60px' : '90px')};
  height: ${(props) => (props.small ? '60px' : '90px')};
  background-image: url(${(props) => getIcon(props.src)});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: ${(props) => (props.small ? FS_14 : FS_16)};
  max-width: ${(props) => (props.small ? '250px' : '100%')};
`;
