import { useState } from 'react';
import CategoriesHeader from './categoriesHeader/categoriesHeader';
import SearchHeader from './searchHeader/searchHeader';

const Header = () => {
  const [hideDropDowns, setHideDropDown] = useState(null);
  const closeOverlay = (value) => {
    setHideDropDown(value);
  };
  return (
    <>
      <SearchHeader closeOverlayHandler={closeOverlay} />
      <CategoriesHeader hideSlider={hideDropDowns} />
    </>
  );
};

export default Header;
