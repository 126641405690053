import { Icon, Label, Root } from './EmptyListView.styled';
import Proptypes from 'prop-types';
import { useMediaQuery } from 'react-responsive/src';
import { breakpoints } from '../../../constants/general';

function EmptyListView({
  message,
  type,
  direction,
  background,
  noSpace,
  outerSpace,
}) {
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });

  return (
    <Root {...{ direction, background, noSpace, outerSpace }}>
      <Icon src={type} small={isMobile} />
      <Label small={isMobile}>{message}</Label>
    </Root>
  );
}

EmptyListView.propTypes = {
  message: Proptypes.string,
  type: Proptypes.oneOf(['watchlist', 'default', 'favorite']),
  direction: Proptypes.oneOf(['column', 'row']),
  background: Proptypes.oneOf(['fill', 'transparent']),
  noSpace: Proptypes.bool,
};

EmptyListView.defaultProps = {
  type: 'watchlist',
  direction: 'column',
  background: 'fill',
};

export default EmptyListView;
