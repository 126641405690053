import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DownloadPopup } from '../../templates';
import { fetchUserData } from '../../../redux/actions/userAction';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../hooks/useQuery';

export default function Index({ children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showDownload, setShowDownload] = useState(false);
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth, shallowEqual);
  const searchParams = useQuery();
  const callToAction = searchParams.get('call-to-action');

  const handleCloseDownload = () => {
    setShowDownload(false);
    searchParams.delete('call-to-action');
    navigate({ search: searchParams.toString() });
  };

  /**
   * Handle call to actions
   * @param {'download-popup' | null} action - Call to actions enum
   */
  const handleAction = (action) => {
    switch (action) {
      case 'download-popup':
        setShowDownload(true);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchUserData());
  }, [isAuthenticated]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (callToAction) {
      handleAction(callToAction);
    }
  }, [callToAction]);

  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </div>
  );
}

Index.propTypes = {
  children: PropTypes.node,
};
