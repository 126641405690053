// import { checkMeOut } from '../../../../../../app/helper/general'; {/* TODO IN RELEASE 2 */}

import { Fragment } from 'react';
import { Divider } from '../../../../../../atoms';

const HistoryResults = ({ historyResults, onClick, handleClearAllSearch }) => {
  return (
    <>
      <div className="search-results-items">
        {historyResults.length > 0 && (
          <div className="history-header">
            <p>Recent</p>
            <button onClick={handleClearAllSearch}>Clear all</button>
          </div>
        )}
        {historyResults.map((item, index) => {
          return (
            <Fragment key={index}>
              <div onClick={() => onClick(item)} className={'search-item'}>
                {item}
              </div>
              {index < historyResults.length - 1 ? (
                <Divider
                  orientation={'horizontal'}
                  thickness={0.5}
                  spacing={0}
                />
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default HistoryResults;
