import { useEffect } from 'react';
// This hook is used to close any popup when click outside of its refrence just by passing the refrence and a callback function to set the state to false.
const useOutsideClick = (ref, callback) => {
  const handleClickOutside = (e) => {
    if (!ref?.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
};

export default useOutsideClick;
