export default function NoDataWrapper({ label, icon, padding, className }) {
  return (
    <div
      className={`noData-container content-center ${className}`}
      style={{ padding: padding }}
    >
      {icon && (
        <img
          loading="lazy"
          src={icon}
          alt="no questions"
          className="blue-container__icon"
        />
      )}
      <p className="noData-container__label">{label}</p>
    </div>
  );
}
