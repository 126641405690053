import { BackDrop, DropDownList, Icon, Root } from './DropDownButton.styled';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import DropDownListItem from '../../atoms/dropDownListItem/DropDownListItem';
import { Divider } from '../../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IconAssets } from '../../../assets/assetProvider';
import { useMediaQuery } from 'react-responsive/src';
import { breakpoints } from '../../../constants/general';
import { bottomMenuAction } from '../../../redux/actions/overlayAction';

function getValue(value, options) {
  const val = options.filter((option) => option.value === value);
  return val.length > 0 ? val[0] : { label: value, value };
}

function getDefaultValue(options) {
  return options[0]
    ? { label: options[0].label, value: options[0].value }
    : { label: `Select...`, value: `` };
}

/**
 * Dropdown Button
 * @param {{label: string; value: *; [left]: JSX.Element; [right]: JSX.Element}[]} options - Dropdown options
 * @param {(value: {value: *; label: string}) => void} onChange - On change event
 * @param {string} value - value
 * @param {boolean} showItemArrow - Show or hide right arrow
 * @param {boolean} showActiveCheck - Show or hide right active check mark
 * @param {*} children
 * @returns {JSX.Element}
 * @constructor
 */
function DropDownButton({
  options,
  onChange,
  value,
  showItemArrow,
  showActiveCheck,
  children,
}) {
  const [show, setShow] = useState(false);
  const defaultValue = value
    ? getValue(value, options)
    : getDefaultValue(options);
  const [selected, setSelected] = useState(defaultValue);
  const { websiteLanguage } = useSelector((state) => state.user);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      handleClose();
    }
  }, [location]);

  useEffect(() => {
    handleClose();
  }, [value, selected]);

  /**
   * set dropdown value
   * @param {string} label - Item label
   * @param {*} value - Item value
   */
  const setValue = ({ label, value }) => {
    setSelected({ value, label });
    if (onChange) {
      onChange({ value, label });
    }
  };

  const handleClose = () => {
    setShow(false);
    dispatch(bottomMenuAction(true));
  };

  const handleOpen = () => {
    if (isMobile) {
      dispatch(bottomMenuAction(show));
    }
    setShow((prevState) => !prevState);
  };

  return (
    <Root
      onBlur={handleClose}
      onClick={handleOpen}
      onKeyUp={({ key }) => {
        if (key === `Escape`) {
          handleClose();
        }
      }}
    >
      {children}
      <span className={`sort-label`}>
        {value ? getValue(value, options).label : selected.label}
      </span>
      <span id={`sort-control-icons`}>
        {show ? (
          <Icon className={`icon-arrow-top`} />
        ) : (
          <Icon className={`icon-arrow-down`} />
        )}
      </span>
      <img
        className={`sort-icon`}
        alt={`sort`}
        src={isMobile ? IconAssets.mobileSortIcon : IconAssets.outlineSortIcon}
        width={21}
        height={21}
      />
      {show && (
        <>
          <BackDrop />
          <DropDownList lang={websiteLanguage}>
            {options.map((item, key) => (
              <Fragment key={key}>
                <DropDownListItem
                  label={item.label}
                  left={item.left}
                  showArrow={showItemArrow}
                  active={showActiveCheck ? value === item.value : false}
                  onClick={() => setValue({ ...item })}
                />
                {key < options.length - 1 && (
                  <Divider thickness={0.5} spacing={0} />
                )}
              </Fragment>
            ))}
          </DropDownList>
        </>
      )}
    </Root>
  );
}

DropDownButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  showItemArrow: PropTypes.bool,
  showActiveCheck: PropTypes.bool,
  children: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      left: PropTypes.node,
    }),
  ),
};

DropDownButton.defaultProps = {
  options: [],
};

export default DropDownButton;
