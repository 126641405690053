import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function CustomTabs({ children, tabs = [] }) {
    const navigateTo = useNavigate();

    const activeTab = window.location.href.includes('?')
        ? window.location.href.split('?').pop()
        : tabs[0].name;

    const handleActiveTab = (e) => {
        navigateTo(`?${e.target.getAttribute('name')}`);
    };
    
    useEffect(() => {
        activeTab && 
            document.getElementsByName(`${activeTab}`)[0].click();
    }, [activeTab]);

    return (
        <Tabs className='tab-container'>
            <TabList className='p-3 mb-1'>
                {tabs.map((tab) => (
                    <Tab
                        key={tab.name}
                        onClick={handleActiveTab}
                        name={tab.name}
                        className='tab-container__item'
                    >
                        {tab.label}
                    </Tab>
                ))}
            </TabList>
            {children.map((child, i) => (
                <TabPanel key={i}>
                    <main className='container-fluid'>
                        {child}
                    </main>
                </TabPanel>
            ))}
        </Tabs>
    );
}

CustomTabs.propTypes = {
    children: PropTypes.array,
    tabs: PropTypes.array,
};

export default CustomTabs;
