import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { Button, Modal } from '../../atoms';
import { FeedbackModalContent, TextField } from '../../molecules';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSchema } from '../../../app/helper/yup-schema';
import services from '../../../services';
import { translate } from '../../../app/i18n';
import { ImageAssets } from '../../../assets/assetProvider';
import * as Styles from './sendMessage.styled';
import { snackboxOpen } from '../../../redux/actions/snackboxAction';
import {
  formatPhoneNumber,
  sanitizeIntlPhoneNumber,
} from '../../../app/helper/custom-validations';

export default function SendMessage({ title, contactus }) {
  const { websiteLanguage, email, phone, personalData } = useSelector(
    (state) => state.user,
  );
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const dispatch = useDispatch();

  const contactFormSchema = createSchema(
    'intPhone',
    'name',
    'email',
    'subject',
    'message',
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(contactFormSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      intPhone: '',
      name: '',
      email: '',
      subject: '',
      message: '',
    },
  });

  useEffect(() => {
    setValue('name', personalData?.fullname);
    setValue('email', email?.isVerified && email?.address);
    setValue('intPhone', phone?.number);
  }, [personalData, email, phone]);

  const values = watch();

  const removeEmptyValues = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
    if (obj.intPhone) {
      const phone = sanitizeIntlPhoneNumber(obj.intPhone);
      obj.phone = { number: formatPhoneNumber(phone) };
      delete obj.intPhone;
    }
    return obj;
  };

  const hideThanksMessage = () => setShowThanksMessage(false);

  const handleFormSubmit = async (data) => {
    let handledData = removeEmptyValues(data);
    try {
      await services.contactForm.sendContactForm(handledData);
      setShowThanksMessage(true);
      reset();
      setShowThanksMessage(true);
    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
  };

  return (
    <section id="send-message">
      <Styles.SectionWrapper lang={websiteLanguage}>
        <Styles.ImgWrapper>
          <Styles.SectionImg src={ImageAssets.sendMessageIllustration} />
        </Styles.ImgWrapper>
        <Styles.FormAndTitleWrapper>
          <Styles.SectionTitle>{title}</Styles.SectionTitle>
          <Styles.SectionForm
            contactus={contactus}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <TextField
              title={translate('sendMessageForm.fullName')}
              name="name"
              type="text"
              placeholder={translate('sendMessageForm.fullName')}
              register={register}
              error={errors['name']}
            />
            <TextField
              title={translate('sendMessageForm.emailAddress')}
              name="email"
              type="email"
              placeholder={translate('sendMessageForm.emailAddress')}
              register={register}
              error={errors['email']}
            />
            <TextField
              title={translate('sendMessageForm.mobileNumber')}
              name="intPhone"
              type="tel"
              endLabel={translate('textFieldComponent.optionalLabel')}
              placeholder={translate('sendMessageForm.mobileNumber')}
              register={register}
              error={errors['intPhone']}
            />
            <TextField
              title={translate('sendMessageForm.subject')}
              name="subject"
              type="text"
              placeholder={translate('sendMessageForm.subject')}
              register={register}
              error={errors['subject']}
            />
            <TextField
              title={translate('sendMessageForm.message')}
              name="message"
              type="textarea"
              rows={5}
              placeholder={translate('sendMessageForm.message')}
              register={register}
              error={errors['message']}
              charLength={values.message.length}
              max={300}
            />
            <Styles.MessageButton>
              <Button
                type="submit"
                fullWidth
                color="primary"
                disabled={isSubmitting || !isDirty}
                ariaLabel="submit"
              >
                {isSubmitting ? (
                  <Loader
                    type="TailSpin"
                    className="loader-icon"
                    width={20}
                    height={20}
                    color="white"
                  />
                ) : (
                  translate('sendMessageForm.submitMessage')
                )}
              </Button>
              <Modal
                show={showThanksMessage}
                onHide={hideThanksMessage}
                content={
                  <FeedbackModalContent
                    imgSrc={ImageAssets.smileIllustration}
                    handleClick={hideThanksMessage}
                  />
                }
              />
            </Styles.MessageButton>
          </Styles.SectionForm>
        </Styles.FormAndTitleWrapper>
      </Styles.SectionWrapper>
    </section>
  );
}
