import { translate } from '../../../app/i18n';

export default function LargeTimer({
  date,
  wrapperClassName,
  timerClassName,
  timerUnitClassName,
}) {
  const boxClassName =
    timerClassName === 'blue-container' ? 'text-primary' : 'text-orange';
  return (
    <div className={`large-timer`}>
      {date &&
        Object.entries(date).map(([key, value], index) => (
          <span
            key={index}
            className={`large-timer__list ${timerUnitClassName} ${wrapperClassName}`}
          >
            <span className={`large-timer__list__box ${boxClassName} `}>
              {value}
            </span>
            <div className="clearfix"></div>
            <small className="large-timer__list__label">
              {translate(`postDetailsPage.${key}`)}
            </small>
          </span>
        ))}
    </div>
  );
}
