import styled from 'styled-components';
import {
  ICONS_COLOR,
  ON_SURFACE,
  PRIMARY,
  SURFACE,
} from '../../../app/theme/colors';
import { FS_18 } from '../../../app/theme/typography';

export const Root = styled.div`
  padding: 15px 20px;
  color: ${ON_SURFACE};
  background-color: ${SURFACE};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(8px * 3);
  justify-content: space-between;
  max-width: 100%;

  &:hover {
    background-color: ${PRIMARY['50']};
  }
`;

export const Icon = styled.span`
  color: ${ICONS_COLOR};
  font-size: ${FS_18};
`;

export const Label = styled.div`
  flex: 1;
  color: ${ON_SURFACE};
  text-align: ${(props) => (props.lang === `en` ? `left` : `right`)};
  white-space: nowrap;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;
`;
