import { Breadcrumb } from 'react-bootstrap';
import { translate } from '../../../app/i18n';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * BreadCrumbs can accept list with their respective routes
 * @param {{label: string; [route]: string}[]} list - List of routes
 * @param {JSX.Element} sideContent - Left side component
 * @returns {JSX.Element}
 * @constructor
 */
function Breadcrumbs({ list, sideContent }) {
  const navigate = useNavigate();

  return (
    <div className="bread-crumb">
      <div className="bread-crumb__left-side">
        <Breadcrumb>
          <Breadcrumb.Item href={''} onClick={() => navigate('/')}>
            {translate('breadCrumb.home')}
          </Breadcrumb.Item>
          {list.map(({ label, route }, i) => (
            <Breadcrumb.Item
              href={''}
              onClick={() => {
                if (route) {
                  navigate(route);
                }
              }}
              className={`${!route ? 'disabled' : ''}`}
              key={i}
            >
              {label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <div className="bread-crumb__right-side">{sideContent}</div>
    </div>
  );
}

Breadcrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      route: PropTypes.string,
    }),
  ),
  sideContent: PropTypes.element,
};

export default Breadcrumbs;
