import { translate } from '../../../app/i18n';
import { Price } from '../../atoms';

const PostCardPrices = ({ cardPrice, biddingPrice, themeColor }) => {
  return (
    <div className={`post-card-prices post-card-prices--${themeColor}`}>
      <div className="post-card-prices__list">
        <Price
          price={cardPrice}
          label={translate('postCard.buyNow')}
          labelClassName={`post-card-prices__list__label ${
            themeColor !== 'light' && 'text-light-blue '
          }`}
          currencyClassName={`post-card-prices__list__currency ${
            themeColor === 'light' ? 'text-secondary' : 'text-white'
          }`}
          priceClassName={`post-card-prices__list__price ${
            themeColor === 'light' ? 'text-secondary' : 'text-white'
          }`}
        />
      </div>

      {themeColor === 'light' && (
        <div className="post-card-prices__vertical-separator"></div>
      )}

      <div className="post-card-prices__list">
        <Price
          price={biddingPrice}
          label={translate('postCard.bidNow')}
          labelClassName={`post-card-prices__list__label ${
            themeColor !== 'light' && 'text-primary'
          }`}
          currencyClassName={`post-card-prices__list__currency ${
            themeColor === 'light' ? 'text-primary' : 'text-white'
          }`}
          priceClassName={`post-card-prices__list__price ${
            themeColor === 'light' ? 'text-primary' : 'text-white'
          }`}
        />
      </div>
    </div>
  );
};
export default PostCardPrices;
