import { setBottomMenu, setMegaMenu, setSearch } from '../slices/overlaySlice';

export const searchAction = (showSearch) => (dispatch) => {
  dispatch(setSearch(showSearch));
};

export const megaMenuAction = (showMegaMenu) => (dispatch) => {
  dispatch(setMegaMenu(showMegaMenu));
};

export const bottomMenuAction = (showBottomMenu) => (dispatch) => {
  dispatch(setBottomMenu(showBottomMenu));
};
