import { useEffect, useState } from 'react';

const useScreenWidth = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function handleWindowResize() {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  return windowSize;
};

export { useScreenWidth };
