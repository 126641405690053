import { useEffect, useState, useRef } from 'react';
import { translate } from '../../../../../../app/i18n';
import { AddButton } from '../../../../../atoms';
import { PostType, MazadatValet } from '../../../../../molecules';
import { PopoverWrapper } from '../../../../../templates';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../../../../constants';
import useOutsideClick from '../../../../../../hooks/useOutsideClick';

const CreatePost = ({ showOverLay, setShowOverLay }) => {
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  const handleOptions = () => {
    setShowOverLay(!showOverLay);
    setShowOptions(!showOptions);
  };

  const handleClick = () => {
    setShowOverLay(false);
    setShowOptions(false);
    navigate(routeConstants.COMING_SOON);
  };

  useOutsideClick(ref, () => {
    setShowOptions(false);
  });

  useEffect(() => {
    if (!showOptions) {
      setShowOverLay(false);
    }
  }, [showOptions]);

  return (
    <>
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="create-post"
      >
        <div className="d-web">
          <AddButton
            onClick={handleOptions}
            text={translate('header.createPost')}
            isClicked={showOptions}
          />
        </div>
        <div className="add-post-mobile d-mobile">
          <span className={showOptions ? 'add-icon-rotated' : ''}>
            <span className={`icon-add icon`} />
          </span>
        </div>
        {showOptions && (
          <>
            <MazadatValet type={'web'} onClick={handleClick} />
            <PopoverWrapper
              setShow={setShowOptions}
              className="create-post__popover"
            >
              <PostType
                icon={'icon-Bid'}
                backgroundColor={'bg-primary'}
                title={translate('createPostButton.createAuctionTitle')}
                subtitle={translate('createPostButton.createAuctionSubtitle')}
                onClick={handleClick}
              />
              <PostType
                icon={'icon-bag'}
                backgroundColor={`bg-secondary`}
                title={translate('createPostButton.createDirectSaleTitle')}
                subtitle={translate(
                  'createPostButton.createDirectSaleSubtitle',
                )}
                onClick={handleClick}
              />
            </PopoverWrapper>
          </>
        )}
      </div>
    </>
  );
};

export default CreatePost;
