import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activePostsList: [],
  isDataLoading: false,
  error: '',
};

export const activePostsSlice = createSlice({
  name: 'activePosts',
  initialState,
  reducers: {
    loading: (state) => {
      return { ...state, isDataLoading: true };
    },
    fetchActivePostsSuccess: (state, action) => {
      return {
        ...state,
        activePostsList: action.payload,
        isDataLoading: false,
      };
    },
    fetchActivePostsFailure: (state, action) => {
      return { ...state, isDataLoading: false, error: action.payload };
    },
  },
});

export const { loading, fetchActivePostsSuccess, fetchActivePostsFailure } =
  activePostsSlice.actions;

export default activePostsSlice.reducer;
