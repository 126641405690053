import { useEffect, useState } from 'react';
import {
  calcDiffTime,
  isExpired,
  calcDiffTimeByUnit,
} from '../../app/helper/formaters';
import { generalConstants } from '../../constants/general';

export default function LargeTimerHoc(WrappedComponent) {
  return function NewComponent({ expiryDate, newExpiryDate, ...props }) {
    const [showLastChance, setShowLastChance] = useState(false);
    const [timerClassName, setTimerClassName] = useState('blue-container');
    const [date, setDate] = useState({
      days: '--',
      hours: '--',
      minutes: '--',
      seconds: '--',
    });
    let timer;
    const updateCount = (date) => {
      timer =
        !timer &&
        setInterval(() => {
          setDate(calcDiffTime(date));
          if (isExpired(date)) {
            clearInterval(timer);
          }
        }, 1000);
    };

    useEffect(() => {
      let updateDate = expiryDate;
      let expiryInMs = calcDiffTimeByUnit(expiryDate, 'milliseconds');
      let lastThreeMinutes = expiryInMs - generalConstants.THREE_MINUTES;

      if (newExpiryDate && newExpiryDate !== expiryDate) {
        setShowLastChance(true);
        updateDate = newExpiryDate;
        expiryInMs = calcDiffTimeByUnit(newExpiryDate, 'milliseconds');
        lastThreeMinutes = expiryInMs  - generalConstants.THREE_MINUTES;

        setTimeout(function () {
          setShowLastChance(false);
        }, generalConstants.LAST_CHANCE_DURATION);
      } 

      const LastThreeMinutes = setTimeout(function () {
        setTimerClassName('yellow-container');
        clearTimeout(LastThreeMinutes);
      }, lastThreeMinutes);

      const RefreshThePageWhenExpire = setTimeout(function () {
        /*
        250 = last 250 milliseconds
        to stop entering this function after refresh the page
      */
        if (expiryInMs > 250) {
          location.reload();
          clearTimeout(RefreshThePageWhenExpire);
        }
      }, expiryInMs);

      updateCount(updateDate);
      return () => clearInterval(timer);
    }, [newExpiryDate]);

    return (
      <WrappedComponent
        {...{
          date,
          showLastChance,
          expiryDate,
          timerClassName,
          ...props,
        }}
      />
    );
  };
}
