import StorageService from '../../services/StorageService';
import { setWatchlistStatus } from '../slices/watchlistStatusSlice';

export const addWatchlistStatus = () => (dispatch) => {
  StorageService.set('watchlistStatus', true);
  dispatch(setWatchlistStatus(true));
};

export const removeWatchlistStatus = () => (dispatch) => {
  StorageService.set('watchlistStatus', false);
  dispatch(setWatchlistStatus(false));
};
