import { useState } from 'react';
import { DownloadPopup, SharePopup } from '../../templates';
import { translate } from '../../../app/i18n';
import { Button } from '../../atoms';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { screen_width_700 } from '../../../constants/screenWidth';

export default function ShareAndBuyNow({ shareLink }) {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const handleClosePopUp = () => setShowSharePopup(false);
  const handleCloseDownload = () => setShowDownload(false);

  const screenWidth = useScreenWidth();
  return (
    <div className="card-horizontal-lg__buttons">
      <div className="card-horizontal-lg__buy-now">
        <Button ariaLabel="buy" onClick={() => setShowDownload(true)} color="secondary">
          {translate('postCard.buyNow')}
        </Button>
      </div>
      <div className="card-horizontal-lg__share">
        <Button
          onClick={() => setShowSharePopup(true)}
          color={screenWidth > screen_width_700 ? 'light' : 'dark'}
          size={screenWidth > screen_width_700 ? 'medium' : 'small'}
          ariaLabel="share"
        >
          <span className="icon icon-share"></span>
        </Button>
      </div>
      <SharePopup
        link={shareLink}
        show={showSharePopup}
        onHide={handleClosePopUp}
        title={translate('sharePopup.shareThisPost')}
      />
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </div>
  );
}
