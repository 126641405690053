import styled from 'styled-components';
import { BORDER } from '../../../app/theme/colors';

export const Vertical = styled.div`
  margin: 0
    ${(props) =>
      typeof props.spacing === 'number'
        ? props.spacing * 8 + 'px'
        : props.spacing};
  width: ${(props) => 2 * props.thickness}px;
  height: 100%;
  background-color: ${BORDER};
  z-index: 1;
`;

export const Horizontal = styled.div`
  margin: ${(props) =>
      typeof props.spacing === 'number'
        ? props.spacing * 8 + 'px'
        : props.spacing}
    0;
  background-color: ${BORDER};
  height: ${(props) => 2 * props.thickness}px;
  width: 100%;
  z-index: 1;
`;
