import PropTypes from 'prop-types';

export default function GalleryImageIcons({
  theme = 'light',
  iconSize,
  icon,
  img,
  onClick,
}) {
  return (
    <>
      <button
        onClick={onClick}
        className={`galleryIcon 
           galleryIcon--${theme}
        }`}
      >
        {icon ? (
          <span className={icon} style={{ fontSize: iconSize }}></span>
        ) : (
          <img
            className="icon-image"
            src={img}
            alt=""
            style={{ fontSize: iconSize }}
          />
        )}
      </button>
    </>
  );
}
GalleryImageIcons.propTypes = {
  light: PropTypes.bool,
};
