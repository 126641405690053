import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../app/i18n';
import { useCountdown } from '../../../hooks/useCountDown';
import { generalConstants } from '../../../constants/general';

const PostTimerSmallView = ({
  expiryDate,
  newExpiryDate,
  className,
  theme,
}) => {
  const expiryText = useCountdown(newExpiryDate ? newExpiryDate : expiryDate);
  const [showLastChance, setShowLastChance] = useState(false);
  /*
    this component handle timer mobile view and card 
    by key .. expird or not
    depend on keys here formaters/formateSmallTimer.js
    */
  useEffect(() => {
    // handle last chance view
    if (newExpiryDate && newExpiryDate !== expiryDate) {
      setShowLastChance(true);
      setTimeout(function () {
        setShowLastChance(false);
      }, generalConstants.LAST_CHANCE_DURATION);
    }
  }, [newExpiryDate]);
  return (
    <div
      className={`timer-small timer-small--${theme} ${className && className}`}
    >
      <div>
        {showLastChance ? (
          <p className="timer-small__lastChance">
            {translate('smallPostTimerComponent.timer3MinsRuleText')}
          </p>
        ) : (
          <p className="timer-small__end-in">
            {/*only if post not expired show 'ends in' before text*/}
            {expiryText.key === 'daysCountdown' ? (
              <>
                {translate('smallPostTimerComponent.ends')}
                {translate('smallPostTimerComponent.inText')}{' '}
              </>
            ) : (
              expiryText.key === 'hourCountdown' && (
                <>
                  {translate('smallPostTimerComponent.ends')}{' '}
                  {translate('smallPostTimerComponent.inText')}{' '}
                </>
              )
            )}
            {/* counter or expird text*/}
            <small className={`timer-small__end-in__${expiryText.key}`}>
              {expiryText.text}
            </small>
          </p>
        )}
      </div>
    </div>
  );
};

export default PostTimerSmallView;

PostTimerSmallView.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
};

PostTimerSmallView.defaultProps = {
  theme: 'dark',
};
