import { API } from '../constants';

export default function HomeServices(httpClient) {
  //@TODO : Replace the api
  const getHomePosts = () => {
    return httpClient.get(API.GET_HOME_POSTS);
  };
  const getHomeSections = (url, params) => {
    return httpClient.get(url, params);
  };
  return {
    getHomePosts,
    getHomeSections,
  };
}
