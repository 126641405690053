import { Rating } from 'react-simple-star-rating';
import { translate } from '../../../app/i18n';
import { useScreenWidth } from '../../../hooks/useScreenWidth';

export default function TotalRating({ label, rate, outOf = 5 }) {
  const screenWidth = useScreenWidth();
  return (
    <>
      <div className="total-rating">
        <div className="total-rating__title">
          <p>{label}</p>
        </div>
        <div className="total-rating__icon">
          {screenWidth > 560 && (
            <div className="total-rating__stars">
              <Rating
                allowFraction={true}
                fillIcon={<span className="icon-star_filled"></span>}
                emptyIcon={<span className="icon-star_empty"></span>}
                initialValue={rate}
                fillColor="#ffce00"
                emptyColor="#ffce00"
                readonly
                size={12}
              />
            </div>
          )}
          {screenWidth <= 560 ? (
            <small className="total-rating__label">
              {rate}
              <span className="icon-star_filled"></span>
            </small>
          ) : (
            <small className="total-rating__label">
              {rate + ' ' + translate('general.outOf') + ' ' + outOf}
            </small>
          )}
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
}
