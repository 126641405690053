import { createSlice } from '@reduxjs/toolkit';
import { getWatchlistStatus } from '../../app/helper/general';
import StorageService from '../../services/StorageService';

const initialState = {
  active: getWatchlistStatus(),
};

export const watchlistStatusSlice = createSlice({
  name: 'watchlistStatus',
  initialState,
  reducers: {
    setWatchlistStatus: (state, { payload }) => {
      StorageService.set('watchlistStatus', payload);
      state.active = payload;
    },
  },
});

export const { setWatchlistStatus } = watchlistStatusSlice.actions;

export default watchlistStatusSlice.reducer;
