import { useEffect } from 'react';
import { translate } from '../../../app/i18n';
import { isExpired } from '../../../app/helper/formaters';
import { useDispatch, useSelector } from 'react-redux';
import { setPostExpiry } from '../../../redux/actions/postActions';
import { IconAssets } from '../../../assets/assetProvider';
import { LargeTimer } from '../../atoms';

export default function PostTimer({
  date,
  showLastChance,
  expiryDate,
  timerClassName,
}) {
  /* 
    This component handles the post timer (desktop).
    & last chance , This is evident when:
    In the last three minutes, a user bid.
    So the expiry date will be updated by
    additional 3 minutes in newExpryDate variable.
    and the last chance view will be shown for 3 seconds.
    Then the timer will be updated.
  */
  const dispatch = useDispatch();
  const { postExpiry } = useSelector((state) => state.post);

  useEffect(() => {
    if (isExpired(expiryDate)) {
      dispatch(setPostExpiry(true));
    } else {
      if (postExpiry !== isExpired(expiryDate)) {
        dispatch(setPostExpiry(false));
      }
    }
  }, [expiryDate, postExpiry, dispatch]);
  return (
    <>
      {!date ? (
        //@TODO : needs to be replaced by skeleton
        <div className="post-timer post-timer--loading"></div>
      ) : showLastChance ? (
        <div className={`post-timer orange-container`}>
          <div className="post-timer__last-Chance-title">
            <img
              loading="lazy"
              src={IconAssets.timerStartBulkIcon}
              alt="timer"
            />
            <p className="d-inline-block">
              {' '}
              {translate('smallPostTimerComponent.timer3MinsRuleText')}{' '}
            </p>
          </div>
        </div>
      ) : (
        <div className={`post-timer ${timerClassName}`}>
          <div className="post-timer__title">
            <p>
              {translate('postDetailsPage.dontMissOut')} <br />
              {translate('postDetailsPage.willExpireIn')}
            </p>
          </div>
          <LargeTimer
            {...{
              date,
              timerClassName,
            }}
          />
        </div>
      )}
    </>
  );
}
