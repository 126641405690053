import { Banner, LargeTimer } from '../../atoms';
import LargeTimerHoc from '../../higherOrderComponents/largeTimerHoc';
import { translate } from '../../../app/i18n';
import { checkUserPlatform } from '../../../app/helper/general';
import { getJsonConfig } from '../../../app/helper/firebaseConfigs';

export default function ComingSoon({ startDate }) {
  const Timer = LargeTimerHoc(LargeTimer);
  let allBanners = getJsonConfig('website_assets');
  const { comingSoon } = allBanners?.home || {};

  return (
    <div className="comingsoon-view">
      <span
        // @TODO add dynamic title from content team
        className="comingsoon-view_title"
      >
        {translate('homePage.dayDeal.title')}
      </span>
      <div className="comingSoon-container">
        <div className="comingSoon-wrapper">
          <div className="comingSoon-wrapper_img">
            <Banner
              imagesUrl={comingSoon ? comingSoon.imagesUrl : ''}
              routeUrl={comingSoon ? comingSoon.routeUrl : ''}
              oneImg={true}
            />
          </div>

          <div className="comingSoon-wrapper_right">
            <h1 className="comingSoon-wrapper_right_header">
              {translate('homePage.comingSoon.title')}
            </h1>
            <span className="comingSoon-wrapper_right_text">
              {translate('homePage.comingSoon.weWillNotifyIn')}
            </span>
            <Timer boxClassName={'text-primary'} expiryDate={startDate} />
            <button
              className="comingSoon-wrapper_right_btn"
              onClick={() => checkUserPlatform('newtab')}
            >
              {translate('homePage.comingSoon.downloadAppGetLatest')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
