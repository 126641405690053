import styled from 'styled-components';
import { colors } from '../../../app/theme';
import { breakpoints } from '../../../constants/general';

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media screen and ${breakpoints.mobile_max_width} {
    gap: 20px;
  }
`;

export const DotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? colors.PRIMARY['500'] : colors.BOX_SHADOW};
`;
