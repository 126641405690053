import styled from 'styled-components';
import { BACK_DROP } from '../../../app/theme/colors';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: ${BACK_DROP};

  img {
    width: 100px;
    height: auto;
  }
`;
