import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 30px;
`;

export const SidebarContainer = styled.div`
  width: 270px;
  display: flex;
  min-width: 270px;

  @media screen and (max-width: 912px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || `40px`};
  max-width: calc(100% - 300px);

  @media screen and (max-width: 912px) {
    max-width: 100%;
  }
`;
