import { useState, useRef } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { translate } from '../../../app/i18n';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ShareInput({ link }) {
  const [actionMessage, setActionMessage] = useState();
  const inputRef = useRef(null);
  return (
    <div className="share-wrapper">
      <InputGroup className="share-group">
        <FormControl
          placeholder="Recipient's username"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          defaultValue={link}
          value={link}
          id="shareLinkInput"
          ref={inputRef}
          className="share-group__input"
        />
        <CopyToClipboard
          text={link}
          onCopy={() =>
            setActionMessage(translate('sharePopup.copiedToClipboard'))
          }
        >
          <button aria-label="share" className="share-group__button" id="button-addon2">
            {translate('sharePopup.copy')}
          </button>
        </CopyToClipboard>
      </InputGroup>
      <p className="share-group__message">{actionMessage}</p>
    </div>
  );
}
