import PropTypes from 'prop-types';
import { useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';

export default function PopoverWrapper({
  position,
  color,
  className,
  fullWidth,
  children,
  style,
  setShow,
}) {
  const ref = useRef();

  useOutsideClick(ref, () => {
    setShow(false);
  });

  return (
    <div
      ref={ref}
      {...{ style }}
      className={`popover-wrapper popover--${position}  popover--${color} ${
        fullWidth && 'popover--fullWidth'
      }   ${className}`}
    >
      {children}
    </div>
  );
}
PopoverWrapper.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  color: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.any,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};
PopoverWrapper.defaultProps = {
  position: 'bottom',
  color: 'light',
  className: '',
  fullWidth: false,
};
