import { translate } from '../../../app/i18n';
import { Button } from '../../atoms';
import { IconAssets } from '../../../assets/assetProvider';

const CookiesBanner = ({ setCookie }) => {
  const handle = () => {
    //will expire after a year.
    setCookie('consentCookies', true, { path: '/', maxAge: 31536000 });
  };
  return (
    <>
      <div className="consent-banner">
        <div className="consent-banner__cookiesIcon">
          <img loading="lazy" alt="cookies" src={IconAssets.cookiesIcon} />
        </div>
        <div className="consent-banner__content">
          <h3>{translate('cookies.siteCookies')}</h3>
          <p>{translate('cookies.cookiesDescription')}</p>
          <Button ariaLabel="accept cookies" color="primary" onClick={handle}>
            {translate('cookies.acceptCookies')}
          </Button>
        </div>
      </div>
    </>
  );
};
export default CookiesBanner;
