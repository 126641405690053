import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PostCardImage from '../../molecules/postCardImage/postCardImage';
import PostCardInfo from '../../molecules/postCardInfo/postCardInfo';
import CardImageOverlay from '../../molecules/cardImageOverlay/cardImageOverlay';
import { postRoute } from '../../../app/helper/general';
import { SharePopup } from '../../templates';
import { translate } from '../../../app/i18n';
import { cardStyles } from '../../../constants/general';
import events from '../../../utils/events';

function PostCard({
  cardImageObj,
  cardInfoObj,
  postId,
  watchlistId,
  closeOverlay,
  themeColor,
  cardClassName,
}) {
  const shareLink = `${window.location.origin}${postRoute(
    cardInfoObj.title,
    postId,
  )}`;

  const navigateTo = useNavigate();
  const handleOnClick = (e) => {
    if (!e.target.classList.contains('icon-share')) {
      navigateTo(postRoute(cardInfoObj.title, postId));
      events.logPostSelect(postId, cardInfoObj);
      if (closeOverlay) closeOverlay();
    }
  };

  const [showSharePopup, setShowSharePopup] = useState(false);
  const handleShowPopUp = () => {
    setShowSharePopup(true);
  };
  const handleClosePopUp = () => setShowSharePopup(false);
  return (
    <>
      <div
        onClick={handleOnClick}
        className={`post-card post-card--${themeColor} ${
          cardClassName && cardClassName
        }`}
      >
        {cardImageObj && (
          <PostCardImage {...{ cardImageObj, handleOnClick, watchlistId }} />
        )}
        <CardImageOverlay
          {...{ cardImageObj, handleShowPopUp, themeColor: themeColor }}
        />
        {cardInfoObj && (
          <PostCardInfo cardInfoObj={cardInfoObj} themeColor={themeColor} />
        )}
      </div>
      <SharePopup
        link={shareLink}
        show={showSharePopup}
        onHide={handleClosePopUp}
        title={translate('sharePopup.shareThisPost')}
      />
    </>
  );
}

PostCard.prototype.style = cardStyles.STYLE_02;

export default PostCard;
