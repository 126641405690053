import { translate } from '../../../app/i18n';

export default function NotAvailableView() {
  return (
    <div className="not-available">
      <small>
        {' '}
        {translate('postCard.notAvailableViewComponent.notAvailable')}
      </small>
    </div>
  );
}
