import PropTypes from 'prop-types';

/**
 * Select component
 */
export default function Select({
    name,
    required,
    options,
    onSelect,
    selected = '',
    label,
}) {
    const handleSelect = (val) => {
        onSelect(name, val);
    };

    return (
        <div className='select'>
            <label className='select__label' htmlFor={name}>
                {label}
            </label>
            <select
                className='select__field'
                name={name}
                required={required}
                value={selected}
                onChange={(event) => handleSelect(event.target.value)}
                selected={selected}
            >
                {options &&
                    options.map((option) => (
                        <option key={option}>{option}</option>
                    ))}
            </select>
        </div>
    );
}

Select.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    label: PropTypes.string,
    selected: PropTypes.string,
};

Select.defaultProps = {
    required: false,
};
