import { translate } from '../../../app/i18n';
import { Breadcrumbs } from '../../atoms';
import NoFoundWrapper from '../../templates/noFoundWrapper/noFoundWrapper';
import { ImageAssets } from '../../../assets/assetProvider';

export function NotFound() {
  return (
    <>
      <Breadcrumbs
        list={[{ label: translate('breadCrumb.notFound'), route: '' }]}
      />
      <NoFoundWrapper
        imgSrc={ImageAssets.notFoundIllustration}
        title={translate('notFoundPage.title')}
        description={translate('notFoundPage.description')}
      />
    </>
  );
}
