import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants';

function PostType({ title, subtitle, icon, backgroundColor, onClick }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) onClick();
    else navigate(routeConstants.COMING_SOON);
  };
  return (
    <button
      onClick={handleClick}
      className="button-transparent w-100 post-type-wrapper"
      aria-label="arrow"
    >
      <div className={'icon-circle-container ' + backgroundColor}>
        <span className={icon}></span>
      </div>
      <div className="post-type-details">
        <h3>{title}</h3>
        <span className="subtitle-text">{subtitle}</span>
      </div>
      {document.body.dir == 'rtl' && <span className="icon-arrow-left"></span>}
      {document.body.dir == 'ltr' && <span className="icon-arrow-right"></span>}
    </button>
  );
}

export default PostType;
