import styled from 'styled-components';

export const LinksContainer = styled.div`
  width: 370px;
  background-color: ${(props) => props.theme.colors.BACKGROUND};
  border-radius: 10px;
  position: sticky;
  top: 10px;
  border: solid 1px ${(props) => props.theme.colors.BORDER};
  p {
    font-size: ${(props) => props.theme.typography.FS_16};
    font-weight: 600;
    padding: 20px 0 15px 0;
    margin: 0 20px 20px;
    border-bottom: 2px solid ${(props) => props.theme.colors.BORDER};
  }

  @media screen and (max-width: 1280px) {
    width: 270px;
  }

  @media screen and (max-width: 650px) {
    background-color: $color-white-true;
    width: 100%;
    p {
      padding: 20px 0 15px;
    }
  }
`;
export const ItemsWrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding: 0 10px 10px 10px;
  margin: 0 10px 10px 10px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.BORDER};
    padding: px !important;
    height: 1px !important;
  }
`;
export const ItemWrapper = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    color: ${(props) =>
      props.color === 'active'
        ? props.theme.colors.PRIMARY[500]
        : props.theme.colors.ON_SURFACE_HIGH};
    font-size: ${(props) => props.theme.typography.FS_14};
    font-weight: 600;
  }
`;
