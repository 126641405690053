import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { Button, Price } from '../../atoms';
import { DownloadPopup } from '../../templates';
import { getPostPrice } from '../../../app/helper/general';

export default function BuyNowMobile({ isItemAvailable, data }) {
  const [showDownload, setShowDownload] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);
  const { discount } = data;

  return (
    <div className="buynow-mobile">
      <Price
        price={getPostPrice(data)}
        discount={discount}
        label={translate('postDetailsPage.buyFor')}
        labelClassName="buynow-mobile__label"
        currencyClassName="buynow-mobile__currency"
        priceClassName="buynow-mobile__price"
        disabled={!isItemAvailable}
      />
      <div className="buynow-mobile__button-group">
        <Button
          disabled={!isItemAvailable}
          onClick={() => setShowDownload(true)}
          color="secondary"
          ariaLabel="buy"
        >
          {translate('postCard.buyNow')}
        </Button>
      </div>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </div>
  );
}
