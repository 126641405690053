import PropTypes from 'prop-types';

export default function ButtonSearch({
  themeColor,
  size,
  handleOnClick,
  className,
  buttonRef,
}) {
  return (
    <button
      onClick={handleOnClick}
      ref={buttonRef}
      aria-label="search"
      className={`button-search button-search--${themeColor} button-search--${size} ${className}`}
    >
      <span className="icon-search button-search__icon"></span>
    </button>
  );
}
ButtonSearch.propTypes = {
  themeColor: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['small', 'medium']),
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
  buttonRef: PropTypes.any,
};
ButtonSearch.defaultProps = {
  themeColor: 'light',
  size: 'medium',
};
