import Skeleton from 'react-loading-skeleton';
import './productLoader.scss';

function ProductLoader() {
  return (
    <div className="productLoader-wrapper">
      <Skeleton style={{ height: '32px', width: '42px' }}></Skeleton>
      <div className="productLoader-wrapper__content">
        <Skeleton style={{ height: '8px', width: '153px' }} />
      </div>
    </div>
  );
}

export default ProductLoader;
