export const ratingKeys = [
  'dropOffTime',
  'accurateDescription',
  'speedAndComfort',
  'sellerAverage',
  'trust',
  'buyerAverage',
  'totalAverage',
  'average',
];
