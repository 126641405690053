import { useEffect, useState } from 'react';
import { userRatingMapper } from '../../../app/helper/mappers';

export default function ReviewRatingPoints({ sellerRating }) {
  const [totalRating, setTotalRating] = useState();
  useEffect(() => {
    setTotalRating(userRatingMapper(sellerRating));
  }, [sellerRating]);

  return (
    <div>
      <div className="review-list__reviews-container">
        {sellerRating &&
          totalRating?.map(([key, value]) => (
            <div key={key} className="review-list__reviewer-list">
              <div className="review-list__reviewer-list__label text-gray">
                {key}
              </div>
              <div className="review-list__reviewer-list__rate">{value}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
