import { useQuery } from '../../hooks/useQuery';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import i18n from './i18n';
import { updateUserLanguage } from '../../redux/actions/userAction';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsiteLanguage } from '../../redux/slices/userSlice';
import PageLoader from '../../components/atoms/pageLoader/PageLoader';
import { LANGUAGES } from '../../constants/general';

export default function LanguageWrapper() {
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);
  const { id, isAuthenticated } = useSelector((state) => state.auth);
  const { websiteLanguage, ...user } = useSelector((state) => state.user);
  const query = useQuery();
  const lang = query.get('lang');
  const language = LANGUAGES.includes(lang) ? lang : websiteLanguage;
  moment.locale(language);

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {
      document.body.dir = i18n.dir();
      if (isAuthenticated) {
        dispatch(updateUserLanguage({ id, language }));
      }
      dispatch(updateWebsiteLanguage(language));
    });
  }, [lang]);

  useEffect(() => {
    if (isAuthenticated) {
      setRender(user.language === language);
    } else {
      setRender(true);
    }
  }, [user.language]);

  return render ? <Outlet /> : <PageLoader />;
}
