import PropTypes from 'prop-types';

const CheckboxGroup = ({
    elements,
    onChange,
    name,
    checkedItems = [],
}) => {
    const isChecked = (item) =>
        checkedItems.indexOf(item) === -1 ? false : true;

    const handleCheck = (value) => {
        const currentIndex = checkedItems.indexOf(value);
        const newChecked = [...checkedItems];

        currentIndex === -1
            ? newChecked.push(value)
            : newChecked.splice(currentIndex, 1);

        onChange(name, newChecked);
    };

    return (
        <>
            {elements && (
                <div className='checkbox-group'>
                    {elements.map((item, i) => (
                        <label key={i} className='checkbox-group__label'>
                            <input
                                type='checkbox'
                                className='checkbox-group__input'
                                name={item.value}
                                value={item.value}
                                checked={isChecked(item.value)}
                                onChange={() => handleCheck(item.value)}
                            />
                            {item.label}
                        </label>
                    ))}
                </div>
            )}
        </>
    );
};

CheckboxGroup.propTypes = {
    elements: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    checkedItems: PropTypes.array,
};

export default CheckboxGroup;
