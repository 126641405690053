export default function AuthWrapper({ src, mobileSrc, children, className }) {
  return (
    <div className={`auth-wrapper ${className ? className : ''}`}>
      <figure className="auth-wrapper__img">
        <img loading="lazy" className="d-web" src={src} alt="auth" />
        <img loading="lazy" className="d-mobile" src={mobileSrc} alt="auth" />
      </figure>
      <div className="auth-wrapper__content">{children}</div>
    </div>
  );
}
