import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getBannerLink, getBannerName } from '../../../app/helper/general';
import { useSelector } from 'react-redux';
import { ProgressiveImageLoader } from '../../organisms';

const Banner = ({ imagesUrl, routeUrl, oneImg, className }) => {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  let imageName = getBannerName(websiteLanguage, windowSize, oneImg);
  const { search } = useLocation();

  function handleWindowResize() {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  return (
    <div>
      {routeUrl ? (
        <Link to={getBannerLink(search, routeUrl)}>
          <ProgressiveImageLoader
            image={imagesUrl[imageName]}
            routeUrl={routeUrl}
            className={className}
            alt="banner"
            imageType={'image'}
          />
        </Link>
      ) : (
        <ProgressiveImageLoader
          image={imagesUrl[imageName]}
          routeUrl={routeUrl}
          className={className}
          alt="banner"
          imageType={'image'}
        />
      )}
    </div>
  );
};

export default Banner;
