import { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Form, FormControl } from 'react-bootstrap';
import { ButtonCancel, ButtonSearch } from '../../../../../../atoms';
import { translate } from '../../../../../../../app/i18n';
import { inputLanguageDetector } from '../../../../../../../app/helper/general';

export default function SearchFormWeb({
  onChangeHandler,
  searchBarFocusHandler,
  onClearSearchInput,
  searchInputRef,
  setShowOverLay,
  searchInput,
  searchDropDownContent,
  SearchResultsGroup,
  closeOverlayHandler,
  onSearch,
}) {
  const [isInputFocued, setInputFocused] = useState(false);
  return (
    <div className="d-web-flex w-100 justify-content-center">
      <div className={`searchBar ${inputLanguageDetector(searchInput)}`}>
        {searchDropDownContent.showLoading && searchInput ? (
          <Loader
            type="TailSpin"
            className="loader-icon"
            width={32}
            height={32}
            color="#00b0b8"
          />
        ) : searchInput === '' ? (
          <ButtonSearch
            handleOnClick={searchBarFocusHandler}
            className={`search-icon ${isInputFocued && 'text-primary'}`}
          />
        ) : (
          <ButtonCancel
            handleOnClick={onClearSearchInput}
            className="clear-search"
          />
        )}
        <Form onSubmit={onSearch}>
          <FormControl
            onFocus={() => {
              setInputFocused(true);
              searchBarFocusHandler();
            }}
            onBlur={() => {
              setInputFocused(false);
              closeOverlayHandler(null);
              setShowOverLay(false);
            }}
            type="text"
            name={`keyword`}
            autoComplete="off"
            placeholder={translate('searchView.searchFieldPlaceHolder')}
            className="me-2"
            aria-label="Search"
            onChange={onChangeHandler}
            value={searchInput}
            ref={searchInputRef}
          />
        </Form>
      </div>
      {SearchResultsGroup}
    </div>
  );
}
