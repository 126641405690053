import Proptypes from 'prop-types';

export default function AddButton({
  onClick,
  isClicked,
  text,
  themeColor,
  className,
}) {
  return (
    <button
      type="button"
      className={`add-button add-button--${themeColor} ${className}`}
      onClick={onClick}
      aria-label="add"
    >
      <span className={`add-button__icon-container ${isClicked && 'blue'}`}>
        <span className={`icon-add icon ${isClicked && 'add-rotated'}`} />
      </span>
      <span className="add-button__text">{text}</span>
    </button>
  );
}
AddButton.propTypes = {
  onClick: Proptypes.func,
  isClicked: Proptypes.bool,
  text: Proptypes.string,
  themeColor: Proptypes.oneOf(['light', 'primary']),
  className: Proptypes.string,
};
AddButton.defaultProps = {
  themeColor: 'light',
  isClicked: false,
};
