import { Root } from './SearchInput.styled';
import { useState } from 'react';
import { ButtonCancel, ButtonSearch } from '../../atoms';
import { translate } from '../../../app/i18n';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

function SearchInput({ onChange, value }) {
  const [isFocused, setFocus] = useState(false);

  return (
    <Root>
      {value === '' ? (
        <ButtonSearch
          handleOnClick={() => {}}
          className={`search-icon ${isFocused ? 'text-primary' : ``}`}
        />
      ) : (
        <ButtonCancel
          handleOnClick={() => onChange && onChange(``)}
          className="clear-search"
        />
      )}
      <FormControl
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        type="text"
        placeholder={translate('filters.filterSearch')}
        className="me-2"
        aria-label="Search"
        onChange={(e) => {
          const val = e.target.value;
          if (onChange) {
            onChange(val);
          }
        }}
        value={value}
      />
    </Root>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SearchInput;
