import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { DownloadPopup } from '../../templates';
import { Button, Price } from '../../atoms';

export default function BidNow({
  isItemAvailable,
  nextBid,
  lastBid,
  handleViewHistory,
  className,
  auction,
}) {
  const [showDownload, setShowDownload] = useState(false);
  const handleCloseDownload = () => setShowDownload(false);

  return (
    <section className={`bid-now ${className && className}`}>
      {/*TODO : check if the user's bid is leading --> after login */}
      {/* <div className='bid-now__title'>
        <p>{translate('postDetails.yourBidIsLeading')}</p>
      </div> */}
      <div className="bid-now__content">
        <Price
          price={(isItemAvailable ? nextBid : lastBid).toNumberFormat()}
          label={
            isItemAvailable
              ? translate('biddingHistorySidebar.nextBid')
              : translate('biddingHistorySidebar.lastBid')
          }
          labelClassName="bid-now__label"
          currencyClassName="bid-now__currency"
          priceClassName="bid-now__price"
        />
        <div className="bid-now__group">
          <div className="view-bidding-history">
            <small>
              {translate('postDetailsPage.bid')(auction?.biddingCount)}
            </small>
            <button
              className="button-hyperlink button-hyperlink--primary"
              onClick={handleViewHistory}
              aria-label="view history"
            >
              &ldquo;{translate('biddingHistorySidebar.viewHistory')}&ldquo;
            </button>
          </div>
          <div className="bid-now__content__buttons">
            <Button
              onClick={() => setShowDownload(true)}
              color="primary"
              disabled={!isItemAvailable}
              ariaLabel="bid"
            >
              {translate('biddingHistorySidebar.bidNow')}
            </Button>
          </div>
        </div>
      </div>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </section>
  );
}
