import { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import EmptyListView from '../../atoms/emptyListView/EmptyListView';
import { PagerButton, SwiperItem } from '../../atoms';
import { Pager, Swiper } from '../../molecules';
import { useMediaQuery } from 'react-responsive/src';
import { breakpoints } from '../../../constants/general';
import { getSwiperItemWidth } from '../../../app/helper/general';

export default function CardSliderWrapper({
  data,
  isDataLoading,
  children,
  spaceBetween,
  className,
  spinnerClassName,
  zerostateText,
  showCardOnlyIfAvailable,
  itemWidth,
  itemHeight,
  innerContainerStyle,
  swiperContainerClassName,
  hideDots,
  hideZeroState,
  swiperSizing,
  reRenderItem,
}) {
  const [swiper, setSwiper] = useState({});
  const isTablet = useMediaQuery({ query: breakpoints.tablet_lg_max_width });
  const isPortrait = useMediaQuery({ query: breakpoints.tablet_max_width });
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });
  const [swiperSize, setSwiperSize] = useState({ width: 0 });

  const getBreakpointWidth = (width) => {
    if (isMobile || isPortrait) {
      return width;
    }
    return swiperSizing === 'dynamic'
      ? getSwiperItemWidth(width, spaceBetween, swiperSize.width)
      : width;
  };

  const showCard = (status) => {
    return !showCardOnlyIfAvailable ? true : status === 'available';
  };

  useEffect(() => {
    if (swiper.slideTo) {
      swiper.slideTo(1);
    }
  }, [swiper.pages]);

  return (
    <div className={className}>
      {isDataLoading ? (
        <div className={`content-center ${spinnerClassName}`}>
          <Loader
            type="TailSpin"
            className="cards-zero-state"
            width={30}
            height={30}
            color="#00b0b8"
          />
        </div>
      ) : data?.length > 0 ? (
        <div className="card-slider-wrapper">
          <Swiper
            spacing={spaceBetween}
            handleChange={setSwiper}
            innerContainerStyle={innerContainerStyle}
            innerContainerClassName={swiperContainerClassName}
            containerStyle={{ alignSelf: 'flex-start' }}
            onResize={setSwiperSize}
          >
            {data?.map((post, key) => {
              if (showCard(post.status)) {
                return (
                  <SwiperItem
                    key={key}
                    width={getBreakpointWidth(itemWidth)}
                    height={itemHeight}
                    itemStyle={{ paddingBottom: '18px' }}
                    reRender={reRenderItem}
                  >
                    {cloneElement(children, {
                      data: post,
                    })}
                  </SwiperItem>
                );
              }
              return null;
            })}
          </Swiper>
          {swiper.hasPrevious || swiper.hasNext ? (
            <div className="gap-5 d-flex-only-web">
              <PagerButton
                type={'Previous'}
                action={swiper.previous}
                disable={!swiper.hasPrevious}
              />
              <PagerButton
                type={'Next'}
                action={swiper.next}
                disable={!swiper.hasNext}
              />
            </div>
          ) : null}
          {!isPortrait && !hideDots && isTablet && swiper.pages > 1 && (
            <Pager
              type={'dot'}
              isLoading={isDataLoading}
              currentPage={swiper.currentPage}
              pageCount={swiper.pages}
              onPageChange={swiper.slideTo}
            />
          )}
        </div>
      ) : !hideZeroState ? (
        <div className="no-cards-wrapper">
          <EmptyListView message={zerostateText} />
        </div>
      ) : null}
    </div>
  );
}

CardSliderWrapper.propTypes = {
  showCardOnlyIfAvailable: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.element,
  spaceBetween: PropTypes.number,
  className: PropTypes.string,
  spinnerClassName: PropTypes.string,
  zerostateText: PropTypes.string,
  itemWidth: PropTypes.number,
  itemHeight: PropTypes.number,
  innerContainerStyle: PropTypes.object,
  swiperContainerClassName: PropTypes.string,
  swiperSizing: PropTypes.oneOf(['dynamic', 'static']),
  hideDots: PropTypes.bool,
  hideZeroState: PropTypes.bool,
  reRenderItem: PropTypes.bool,
};

CardSliderWrapper.defaultProps = {
  showCardOnlyIfAvailable: false,
  isDataLoading: false,
  hideZeroState: false,
  swiperSizing: 'dynamic',
};
