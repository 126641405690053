import PropTypes from 'prop-types';

export default function AvatarList({
  imgSrc,
  name,
  title,
  description,
  imgSize,
}) {
  //@TODO : should be in molecules
  return (
    <>
      <div className="avatar-list">
        <figure className="avatar-list__img-container">
          <img
            loading="lazy"
            className={`img-${imgSize}`}
            src={imgSrc}
            alt="avatar"
          />
        </figure>
        <div className="avatar-list__description-container">
          <p className="avatar-list__name">{name}</p>
          <p className="avatar-list__title">{title}</p>
          <p className="avatar-list__description">{description}</p>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
}

AvatarList.propTypes = {
  imgSize: PropTypes.oneOf(['small', 'medium']),
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
