import TextField from '../textField/textField';
import { translate } from '../../../app/i18n';

export default function PhoneInput({
  isValid,
  codeValue,
  phoneValue,
  codeError,
  phoneError,
  onClick,
  register,
}) {
  return (
    <div className="phone-wrapper">
      <TextField
        type={'code'}
        title={translate('phoneNumberComponent.code')}
        name="code"
        value={codeValue}
        register={register}
        className={`code-input`}
        error={codeError}
        {...{ onClick }}
      />
      <TextField
        type="tel"
        title={translate('phoneNumberComponent.phoneNumber')}
        name="phone"
        className={'phone-input'}
        placeholder={translate('phoneNumberComponent.phoneNumber')}
        value={phoneValue}
        register={register}
        autoComplete="off"
        endLabel={
          isValid && (
            <span className="icon-Verified endLabel-icon text-primary"></span>
          )
        }
        error={phoneError}
      />
    </div>
  );
}
