import { useState } from 'react';
import PropTypes from 'prop-types';
import Proptypes from 'prop-types';
import { DownloadPopup } from '../../templates';
import Loader from 'react-loader-spinner';
import { colors } from '../../../app/theme';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants';

export default function AddToWatchList({
  className,
  size,
  theme,
  onClick,
  loading,
}) {
  // @TODO : id will be used later

  const navigate = useNavigate();

  const handleWatchlist = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick();
    else navigate(routeConstants.REGISTRATION);
  };

  const [showDownload, setShowDownload] = useState(false);
  const loaderSize = size === 'sm' ? '18' : '31';

  const handleCloseDownload = () => {
    setShowDownload(false);
  };
  return (
    <>
      <button
        disabled={loading}
        onClick={handleWatchlist}
        aria-label="watchList"
        className={`add-to-watch-list add-to-watch-list--${size} add-to-watch-list--${theme} ${
          className || ''
        }`}
      >
        {!loading ? (
          <span className="icon-heart-filled icon"></span>
        ) : (
          <Loader
            type={'TailSpin'}
            height={loaderSize}
            width={loaderSize}
            color={colors.PRIMARY['500']}
            secondaryColor={colors.BORDER}
            radius={0}
          />
        )}
      </button>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </>
  );
}

AddToWatchList.propTypes = {
  size: PropTypes.oneOf(['medium', 'sm']),
  theme: PropTypes.oneOf(['light', 'filled', 'dark', 'outlined']),
  id: PropTypes.string,
  className: Proptypes.string,
  loading: Proptypes.bool,
  onClick: Proptypes.func,
};

AddToWatchList.defaultProps = {
  size: 'medium',
  theme: 'light',
};
