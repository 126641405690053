import PropTypes from 'prop-types';

const ErrorMessage = ({ error, name = '' }) => {
  if (!error) return null;

  return (
    <div className="error-message">
      <div
        className="error-message__text"
        aria-label={name ? `${name} error message` : 'error message'}
      >
        {error.message ? error.message : error}
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.any,
  name: PropTypes.string,
};

export default ErrorMessage;
