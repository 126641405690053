const queryKeys = {
  /**
   * Get a category by id
   * @param {string} id - any unique identifier
   * @returns {[string, {id: string}]}
   * @constructor
   */
  getCategoryById: (id) => ['category', { id }],

  /**
   * Get post filter fields by category
   * @returns {[string,{categoryId: string}]}
   * @param {string} categoryId -
   * @param {object} fields -
   */
  getPostFilterFieldsByCategory: (categoryId, fields) => [
    `filters`,
    { categoryId },
    fields,
  ],

  /**
   * Get post filter fields by keyword
   * @returns {[string,{categoryId: string}]}
   * @param {string} keyword -
   * @param {object} fields -
   */
  getPostFilterFieldsByKeyword: (keyword, fields) => [
    `filters`,
    keyword,
    fields,
  ],

  /**
   * Get dynamic sections
   * @returns {[string]}
   */
  getDynamicSections: () => [`dynamicSections`],

  /**
   * Get a dynamic section post by section key
   * @param {string} key - section key
   * @param {string} url - section url
   * @param {number} [page=1] - page number
   * @param {number} [limit=12] - page limit
   * @param {string} [sort] - product sort method
   * @param {object} [fields] -
   * @returns {[string,string]}
   */
  getSectionPostByKey: (key, { page, url, limit, sort }, fields) => [
    `dynamicSections`,
    key,
    { url: url.split('?')[0], page, limit, sort },
    fields,
  ],

  /**
   * Get category path from root
   * @param {string} id - category ID
   * @returns {[string,string,{id: string}]}
   */
  getCategoryPath: (id) => [`category`, `path`, { id }],

  /**
   * Get parent categories
   * @param {boolean} [used=true] - Category usage status
   * @returns {[string,string,{used: boolean}]}
   * @constructor
   */
  getParentCategories: ({ used }) => {
    return [
      'categories',
      'parents',
      {
        used: used === undefined ? true : used,
      },
    ];
  },

  /**
   * Get all categories both parent and children
   * @returns {[string]}
   */
  getAllCategories: () => [`categories`],

  /**
   * Get Sub Categories
   * @param {string} parentId - parent category id
   * @param {boolean} [used=true] - category usage status
   * @returns {[string,string,{parentId: string, used: boolean}]}
   */
  getSubCategories: ({ parentId, used }) => {
    return [
      'categories',
      'children',
      {
        parentId,
        used: used === undefined ? true : used,
      },
    ];
  },

  getFeaturedPosts: () => ['posts', `featuredPosts`],

  /**
   * Get post list by category
   * @param {string} categoryId - category id
   * @param {number} [page=1] - page number
   * @param {number} [limit=12] - page limit
   * @param {string} [sort] - product sort method
   * @param {object} fields -
   * @param {string[]} [Category] - category filter
   */
  getPostByCategory: ({ categoryId, page, limit, sort }, fields) => {
    return [
      `posts`,
      `filterWithCategory`,
      {
        parentCategoryId: categoryId,
        page: page || 1,
        limit: limit || 12,
        sort,
      },
      fields,
    ];
  },

  /**
   * Search by keyword
   * @param {string} keyword - category id
   * @param {number} [page=1] - page number
   * @param {number} [limit=12] - page limit
   * @param {string} [sort] - product sort method
   * @param {object} fields -
   * @param {string[]} [Category] - category filter
   */
  searchByKeyword: ({ keyword, page, limit, sort }, fields) => {
    return [
      `search`,
      keyword,
      {
        page: page || 1,
        limit: limit || 12,
        sort,
      },
      fields,
    ];
  },

  /**
   * search autoComplete
   * @param {string} keyword - search keyword
   * @returns {string[]}
   */
  getAutoComplete: (keyword) => [`auto_complete`, keyword],

  /**
   * Get category featured posts
   * @param {string} parentCategoryId
   * @returns {[string,string,{parentCategoryId: string}]}
   */
  getCategoryFeaturedPosts: (parentCategoryId) => [
    'featured',
    'category',
    { parentCategoryId },
  ],

  /**
   * Get search featured posts
   * @param {string} kw
   * @returns {[string,string,{kw: string}]}
   */
  getSearchFeaturedPosts: (kw) => ['featured', 'search', { kw }],
};

export default queryKeys;
