import { useEffect, useState } from 'react';
import { HalfAndHalfBlueWrapper } from '../../../templates';
import { PostCardHorizontalLarge } from '../../../organisms';
import PostHoc from '../../../higherOrderComponents/postHoc/postHoc';
import Service from '../../../../services';
import { translate } from '../../../../app/i18n';
import { ComingSoon, Pager, Swiper } from '../../../molecules';
import { getSwiperItemWidth, isEmpty } from '../../../../app/helper/general';
import config from '../../../../config';
import { useDispatch } from 'react-redux';
import { snackboxOpen } from '../../../../redux/actions/snackboxAction';
import { SwiperItem } from '../../../atoms';

export default function DayDeal({ url }) {
  const [swiper, setSwiper] = useState({});
  const [swiperSize, setSwiperSize] = useState({ width: 0 });
  const [data, setData] = useState({});
  const { runningPosts, waitingStartPosts } = data;
  const dispatch = useDispatch();
  const filteredPosts = runningPosts?.flatMap((element) => {
    if (element.status.toLowerCase() === 'started') return element.post;
    return [];
  });
  const Card = PostHoc(PostCardHorizontalLarge);
  const getDayDeal = async () => {
    try {
      const response = await Service.homeService.getHomeSections(
        config.API_URL + url,
      );
      setData(response);
    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
  };

  useEffect(() => {
    if (swiper.slideTo) {
      swiper.slideTo(1);
    }
  }, [swiper.pages]);

  useEffect(() => {
    getDayDeal();
  }, []);

  return (
    <>
      {filteredPosts?.length ? (
        <HalfAndHalfBlueWrapper>
          <Swiper spacing={0} handleChange={setSwiper} onResize={setSwiperSize}>
            {filteredPosts.map((deal, index) => (
              <SwiperItem
                key={index}
                width={getSwiperItemWidth(
                  swiperSize.width,
                  0,
                  swiperSize.width,
                )}
                itemStyle={{ paddingBottom: '30px' }}
              >
                <Card
                  data={deal}
                  verticalText={translate(
                    'homePage.dayDeal.title',
                  ).toUpperCase()}
                />
              </SwiperItem>
            ))}
          </Swiper>
          {filteredPosts.length > 1 && (
            <Pager
              type={'dot'}
              currentPage={swiper.currentPage}
              pageCount={filteredPosts.length}
              onPageChange={swiper.slideTo}
            />
          )}
        </HalfAndHalfBlueWrapper>
      ) : (
        waitingStartPosts &&
        !isEmpty(waitingStartPosts) && (
          <ComingSoon startDate={waitingStartPosts?.startDate} />
        )
      )}
    </>
  );
}
