import { AvatarList, TotalRating } from '../../atoms';
import { formateDiffTime } from '../../../app/helper/formaters';
import { translate } from '../../../app/i18n';
import ReviewRatingPoints from './reviewRatingPoints';
import ReviewRatingMobile from './reviewRatingMobile';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { getAvatarUrl } from '../../../app/helper/general';

export default function ReviewList({
  reviewer,
  sellerRating,
  text,
  createdAt,
}) {
  const screenWidth = useScreenWidth();
  return (
    <>
      <div className="review-list">
        <div className="review-list__avatar">
          <AvatarList
            imgSrc={getAvatarUrl(reviewer.avatar)}
            name={reviewer.username}
            title={formateDiffTime(createdAt)}
            imgSize="small"
          />
          <div>
            {text ? (
              <div className="review-list__content">
                <p>{text}</p>
              </div>
            ) : (
              <div className="review-list__content review-list__zero-state">
                <p>{translate('postDetailsPage.reviewZeroState')}</p>
              </div>
            )}
          </div>
        </div>
        <div className="review-list__container">
          <div>
            <div className="review-list__rate">
              {sellerRating && (
                <TotalRating rate={`${sellerRating.average.toFixed(1)}`} />
              )}
            </div>
            {screenWidth > 560 && <ReviewRatingPoints {...{ sellerRating }} />}
          </div>
        </div>
      </div>
      {screenWidth <= 560 && <ReviewRatingMobile {...{ sellerRating }} />}
    </>
  );
}
