import { ErrorMessage } from '../../atoms';
import { translate } from '../../../app/i18n';

export default function InputOtp({ register, error, code }) {
  return (
    <div className="input-group-otp">
      <input
        className={`input-otp ${error && 'input-otp-error'}`}
        type="number"
        min={0}
        max={9999}
        value={code}
        {...register}
        placeholder={translate('otpForm.enterTheCode')}
      />
      <ErrorMessage {...{ error }} />
    </div>
  );
}
