import { API } from '../constants';

export default function AllCategoriesService(httpClient) {
  const getAllCategories = () => {
    return httpClient.get(API.GET_ALL_CATEGORIES);
  };
  const addToFavourites = (CategoryId) => {
    return httpClient.put(`${API.CATEGORY_ADD_TO_FAVOURITES}/${CategoryId}`);
  };
  const removeFromFavourites = (CategoryId) => {
    return httpClient.put(
      `${API.CATEGORY_REMOVE_FROM_FAVOURITES}/${CategoryId}`,
    );
  };
  const getFavourites = () => {
    return httpClient.get(`${API.GET_FAVOURITES_CATEGORIES}`);
  };
  return {
    getAllCategories,
    addToFavourites,
    removeFromFavourites,
    getFavourites,
  };
}
