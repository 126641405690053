import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCategoriesList: [],
  isDataLoading: false,
  filteredCategories: [],
  error: '',
  favourites: [],
};

export const allCategoriesSlice = createSlice({
  name: 'allCategories',
  initialState,
  reducers: {
    loading: (state) => {
      return { ...state, isDataLoading: true };
    },
    fetchAllCategoriesSuccess: (state, action) => {
      return {
        ...state,
        allCategoriesList: action.payload,
        isDataLoading: false,
      };
    },
    fetchFilteredCategories: (state, action) => {
      return {
        ...state,
        filteredCategories: action.payload,
        isDataLoading: false,
      };
    },
    fetchAllCategoriesFailure: (state, action) => {
      return {
        ...state,
        isDataLoading: false,
        error: action.payload,
      };
    },

    fetchFavourites: (state, action) => {
      return {
        ...state,
        favourites: action.payload,
      };
    },
  },
});

export const {
  loading,
  fetchAllCategoriesSuccess,
  fetchAllCategoriesFailure,
  fetchFilteredCategories,
  fetchFavourites,
} = allCategoriesSlice.actions;

export default allCategoriesSlice.reducer;
