import PropTypes from 'prop-types';

export default function Button({
  children,
  size,
  onClick,
  type,
  fullWidth,
  className,
  disabled,
  color,
  ariaLabel
}) {
  return (
    <button
      disabled={disabled}
      className={`
                button
                button--${color}
                button--${size}
                ${className && className}
                ${fullWidth && 'button--fullWidth'}
            `}
      size={size}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'link']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondary-bordered',
    'primary-bordered',
    'primary-medium',
    'secondary-medium',
    'main',
    'support-primary',
    'support-secondary',
    'light',
    'dark',
  ]),
  onClick: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  color: 'primary',
  className: '',
  fullWidth: false,
};
