import PropTypes from 'prop-types';
import { formatViews } from '../../../app/helper/formaters';

export default function Views({ numberOfViews, light, fontSize, iconSize }) {
  return (
    <div className={`views ${light ? 'views--light' : 'views--dark'}`}>
      <span className="icon-eye" style={{ fontSize: iconSize }}></span>
      <div className="views__value" style={{ fontSize: fontSize }}>
        <p>{formatViews(numberOfViews)}</p>
      </div>
    </div>
  );
}
Views.propTypes = {
  light: PropTypes.bool,
};
