import * as yup from 'yup';
import {
  confirmPassword,
  email,
  intPhone,
  message,
  name,
  otp,
  notsearch_password,
  phone,
  subject,
  username,
} from './yup-constants';

const schema = {
  name,
  email,
  phone,
  subject,
  message,
  intPhone,
  notsearch_password,
  username,
  confirmPassword,
  otp,
  phoneNotRequired: true,
};

const createSchema = (...params) => {
  let tempObject = {};

  params.forEach((param) => {
    tempObject[param] = schema[param];
  });
  return yup.object().shape(tempObject);
};

export { createSchema };
