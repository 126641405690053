import { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, PasswordStrengthBar } from '../../atoms';
import { translate } from '../../../app/i18n';
import FlagsDropdown from '../flagsDropdown/flagsDropdown';

export default function TextField({
  register,
  error,
  className,
  endLabel,
  title,
  endButton,
  handleEndButton,
  endButtonDisabled,
  barColor,
  type,
  endLabelTop,
  charLength,
  max,
  onClick,
  isShowBar = true,
  ...props
}) {
  const CustomInput = type === 'textarea' ? 'textarea' : 'input';
  const [passwordShown, setPasswordShown] = useState(false);
  const [open, setOpen] = useState(false);
  const handleTogglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const ToggleViewPassword = () => (
    <button
      className="text-field__toggle-password"
      onClick={handleTogglePassword}
      type="button"
    >
      <span
        className={passwordShown ? 'icon-item_viewed' : 'icon-hide_eye'}
      ></span>
    </button>
  );

  const PasswordStrengthText = () => {
    let strengthObj = { text: '', color: '' };
    if (barColor?.firstBarBackground === 'bg-error') {
      strengthObj.text = translate('passwordStrength.weakPassword');
      strengthObj.color = 'text-error';
    } else if (barColor?.firstBarBackground === 'bg-orange') {
      strengthObj.text = translate('passwordStrength.averagePassword');
      strengthObj.color = 'text-orange';
    } else if (barColor?.firstBarBackground === 'bg-green') {
      strengthObj.text = translate('passwordStrength.greatPassword');
      strengthObj.color = 'text-green';
    }

    return (
      <p
        className={`text-field__underline__strength-password ${strengthObj.color}`}
      >
        {strengthObj.text}
      </p>
    );
  };
  return (
    <div
      className={`text-field  text-field--${
        className && className
      } text-field--${type}`}
    >
      {endLabelTop && (
        <div className="text-field__end-label-top">
          <small>{endLabelTop}</small>
        </div>
      )}
      <fieldset
        className={`border ${error && 'fieldset-error'} `}
        onClick={() => setOpen(!open)}
      >
        {title && <legend className="legend-placeholder">{title}</legend>}
        {type === 'code' && (
          <FlagsDropdown
            {...{
              onClick,
              open,
              setOpen,
              value: props.value,
              register: register(props.name),
            }}
          />
        )}
        {type === 'tel' ? (
          <input
            pattern="[+0-9]*"
            className={`text-field__input`}
            type="tel"
            {...register(props.name)}
            {...props}
          />
        ) : (
          <CustomInput
            aria-label={props.placeholder || props.name}
            className={`text-field__input ${!title && 'no-title'}`}
            type={
              type === 'password' ? (passwordShown ? 'text' : 'password') : type
            }
            draggable="false"
            {...(register && { ...register(props.name) })}
            {...props}
            maxLength={max}
          />
        )}
        {type === 'password' && (
          <>
            {isShowBar && <PasswordStrengthBar {...barColor} />}
            <ToggleViewPassword />
          </>
        )}
        {endLabel && (
          <small className={`text-field__end-label ${!title && 'no-title'}`}>
            {endLabel}
          </small>
        )}
        {endButton && (
          <button
            className="text-field__end-button"
            disabled={endButtonDisabled}
            onClick={handleEndButton}
            type="button"
          >
            {endButton}
          </button>
        )}
      </fieldset>
      <div className="text-field__underline">
        <ErrorMessage error={error} name={props.name} />
        {type === 'password' && <PasswordStrengthText />}
      </div>
      {type === 'textarea' && (
        <span className="text-field__counter">
          {`${charLength} / ${max} ${translate(
            'textFieldComponent.characters',
          )}`}
        </span>
      )}
    </div>
  );
}

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  max: PropTypes.any,
  error: PropTypes.any,
  endLabel: PropTypes.any,
  title: PropTypes.string,
  barColor: PropTypes.object,
  register: PropTypes.func,
  onChange: PropTypes.func,
  endButtonDisabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf([
    'on',
    'username',
    'email',
    'new-password',
    'off',
  ]),
};
