import { translate } from '../../../app/i18n';
import { useSelector } from 'react-redux';
import { LinksWrapper } from '../../molecules';
import {
  ButtonWrapper,
  LegalContainer,
  LegalContainerLinks,
} from './legalWrapper.styled';
import { useScrollDirection } from '../../../hooks/useScrollDirection';

export default function LegalWrapper({ data, children }) {
  const { websiteLanguage } = useSelector((state) => state.user);
  const scrollDirection = useScrollDirection();
  const onHandleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <LegalContainer lang={websiteLanguage}>
        <LegalContainerLinks lang={websiteLanguage}>
          <LinksWrapper data={data} />
        </LegalContainerLinks>
        {children}
        {scrollDirection === 'down' && (
          <ButtonWrapper aria-label="top" lang={websiteLanguage}>
            <button  aria-label="top" onClick={onHandleScroll}>
              <div>
                <span className="icon-arrow-top"></span>
                <p>{translate('legalPages.top')}</p>
              </div>
            </button>
          </ButtonWrapper>
        )}
      </LegalContainer>
    </>
  );
}
