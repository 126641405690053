import { translate } from '../../../../../../app/i18n';
import { Collapsible } from '../../../../../atoms';

export default function MazadatLink({ MazadatLinks }) {
  return (
    <div className="footer-mobile__info__customer-service footer-mobile__info__section">
      <Collapsible isOpen={true} label={translate('footer.mazadat')}>
        <div className="footer-mobile__info__customer-service__collapsed footer-mobile__info__collapsed">
          <MazadatLinks />
        </div>
      </Collapsible>
    </div>
  );
}
