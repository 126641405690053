import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { snackboxClose } from '../../../redux/actions/snackboxAction';

const Snackbox = ({ timeout }) => {
  const dispatch = useDispatch();

  // select the UI states from the redux store
  const {
    toggleSnackbox: show,
    snackboxMessage: content,
    snackboxType: type,
  } = useSelector((state) => state.snackbox);
  // convert the timeout prop to pass into the styled component
  let TIME = (timeout - 400) / 1000 + 's';
  let TIMER;

  function handleTimeout() {
    TIMER = setTimeout(() => {
      dispatch(snackboxClose());
    }, timeout);
  }

  //@TODO : dismiss button function, may be add later
  // function handleClose() {
  //   clearTimeout(TIMER);
  //   dispatch(snackboxClose());
  // }

  useEffect(() => {
    if (show) {
      handleTimeout();
    }
    return () => {
      clearTimeout(TIMER);
    };
  }, [show, TIMER]);

  return (
    show &&
    content && (
      <Container time={TIME} className={`snackbox snackbox--${type}`}>
        <p>{typeof content === 'object' ? content.message : content}</p>
      </Container>
    )
  );
};

const fadein = keyframes`
  from {
    top: -70px;
    opacity: 0;
  }
  to {
    top: 0rem;
    opacity: 1;
  }
`;
const fadeout = keyframes`
  from {
    top: 0rem;
    opacity: 1;
  }
  to {
    top: -70px;
    opacity: 0;
  }
`;
const Container = styled.div`
  top: 0rem;
  animation: ${fadein} 0.5s, ${fadeout} 0.5s ${(props) => props.time};
`;

Snackbox.prototypes = {
  type: PropTypes.oneOf(['info', 'success', 'warn', 'failure']),
};

export default Snackbox;
