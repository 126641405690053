import { PaginationLabel } from './PagerLabel.styled';
import Proptypes from 'prop-types';
import { translate } from '../../../app/i18n';

function PagerLabel({ currentPage, pageCount }) {
  return (
    <PaginationLabel>
      {translate('pagination.pageText')}&nbsp;{currentPage.toNumberFormat()}
      &nbsp;
      {translate('general.ofText')}&nbsp;
      {pageCount.toNumberFormat()}
    </PaginationLabel>
  );
}

PagerLabel.propTypes = {
  currentPage: Proptypes.number,
  pageCount: Proptypes.number,
};

export default PagerLabel;
