import { useSelector } from 'react-redux';

export default function TooltipComponent({ text }) {
  const { websiteLanguage } = useSelector((state) => state.user);
  return (
    <>
      <div className="tooltip-content">
        <span
          className={`question-mark  tooltip-content__icon ${
            websiteLanguage === 'ar' ? 'icon-mark_ar' : 'icon-mark' //@TODO : to be handled on future
          }`}
        >
          <p className="tooltiptext">{text}</p>
        </span>
      </div>
    </>
  );
}
