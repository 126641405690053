import PropTypes from 'prop-types';
import { Horizontal, Vertical } from './Divider.styled';

/**
 * Divider component
 * @param {('horizontal' | 'vertical')} orientation
 * @param {(number | string)} spacing
 * @param {number} thickness
 * @returns {JSX.Element}
 * @constructor
 */
function Divider({ orientation, spacing, thickness }) {
  return orientation === `vertical` ? (
    <Vertical spacing={spacing} thickness={thickness} />
  ) : (
    <Horizontal spacing={spacing} thickness={thickness} />
  );
}

Divider.propTypes = {
  orientation: PropTypes.oneOf([`vertical`, `horizontal`]),
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.number,
};

Divider.defaultProps = {
  orientation: `horizontal`,
  spacing: 1,
  thickness: 1,
};

export default Divider;
