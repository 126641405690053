import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { DownloadPopup } from '../../templates';
import { useNavigate } from 'react-router-dom';

export default function ImageGalleryShowMoreContent({ ...props }) {
  const [showDownload, setShowDonwload] = useState(false);
  const handleCloseDownload = () => setShowDonwload(false);
  const navigate = useNavigate();
  return (
    <>
      <ul className="show-more">
        <li className="show-more__list">
          <button aria-label="backToHome" onClick={() => navigate('/')}>
            {translate('postDetailsPage.backToHome')}
          </button>
        </li>
        {/* <li className="show-more__list">
          <button onClick={() => setShowDonwload(true)}>
            {translate('postDetails.postSimilar')}
          </button>
        </li> */}
        <li className="show-more__list">
          <button aria-label="reportAProblem" onClick={() => setShowDonwload(true)}>
            {translate('postDetailsPage.reportAProblem')}
          </button>
        </li>
        <li className="show-more__list">
          <button aria-label="cancel" onClick={() => props.onHide()}>
            {translate('postDetailsPage.cancel')}
          </button>
        </li>
      </ul>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </>
  );
}
