import { useNavigate } from 'react-router-dom';
import NeedHelp from '../../styled-molecules/needHelp/needHelp';
import { translate } from '../../../app/i18n';
import { Button } from '../../atoms';
import { routeConstants } from '../../../constants';

export default function NoFoundWrapper({
  imgSrc,
  title,
  description,
  children,
}) {
  const navigate = useNavigate();
  return (
    <>
      {children ? (
        <div>{children}</div>
      ) : (
        <div className="no-found-wrapper">
          <img src={imgSrc} alt="not found" />
          <div className="no-found-content">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="no-found-buttons">
              <Button
                ariaLabel="back to home"
                onClick={() => navigate(routeConstants.HOME)}
              >
                {translate('notFoundPage.backToHome')}
              </Button>
              <Button
                onClick={() => navigate(routeConstants.CONTACT_US)}
                color="primary-bordered"
                ariaLabel="contact us"
              >
                {translate('notFoundPage.contactUs')}
              </Button>
            </div>
          </div>
        </div>
      )}
      <NeedHelp />
    </>
  );
}
