import { useState } from 'react';
import { translate } from '../../../app/i18n';
import { DownloadPopup } from '../../templates';

export default function ShowMoreContent({ showShare, ...props }) {
  const [showDownload, setShowDonwload] = useState(false);
  const handleCloseDownload = () => setShowDonwload(false);
  return (
    <>
      <ul className="show-more">
        {/* @TODO : when the user own the post */}
        {/* <li className='show-more__list'>
          <button onClick={() => setShowDonwload(true)}>
            {translate('breadCrumb.editPost')}
          </button>
        </li>
        <li className='show-more__list'>
          <button onClick={() => setShowDonwload(true)}>
            {translate('breadCrumb.similarPost')}
          </button>
        </li> */}
        <li className="show-more__list">
          <button
            aria-label="share"
            onClick={() => {
              props.onHide();
              showShare();
            }}
          >
            {translate('breadCrumb.sharePost')}
          </button>
        </li>
        <li className="show-more__list">
          <button aria-label="addToWatchList" onClick={() => setShowDonwload(true)}>
            {translate('breadCrumb.addToWatchList')}
          </button>
        </li>
        <li className="show-more__list">
          <button aria-label="reportAbuse" onClick={() => setShowDonwload(true)}>
            {translate('breadCrumb.reportAbuse')}
          </button>
        </li>
      </ul>
      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </>
  );
}
