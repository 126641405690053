import { translate } from '../../app/i18n';

export const HTTPErrorHandler = (error) => {
  const errorMsg = error?.data?.message || error?.data?.error;

  if (errorMsg) return { status: error?.status, message: errorMsg };
  else if (error?.status.in([500, 501, 502, 503, 504]))
    return {
      status: error?.status,
      message: translate('httpErrorMsg.pleaseTryAgain'),
    };
  else
    return {
      status: error?.status,
      message: translate('httpErrorMsg.somethingWrong'),
    };
};
