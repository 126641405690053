import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleSnackbox: false,
  snackboxMessage: null,
  snackboxType: 'info',
};

export const snackboxSlice = createSlice({
  name: 'snackbox',
  initialState,
  reducers: {
    open: (state, { payload }) => {
      return {
        ...state,
        toggleSnackbox: true,
        snackboxMessage: payload.message,
        snackboxType: payload.type ? payload.type : state.snackboxType,
      };
    },
    close: (state) => {
      return {
        ...state,
        toggleSnackbox: false,
        snackboxMessage: null,
      };
    },
  },
});

export const { open, close } = snackboxSlice.actions;

export default snackboxSlice.reducer;
