import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { RouterProvider } from 'react-router-dom';
import 'moment/locale/ar';
import '../app/helper/prototypes';
import {
  anonymousUser,
  checkAuth,
  setFingerprint,
} from '../redux/actions/authActions';
import { fetchTopFiveCategories } from '../redux/actions/topFiveCategoriesAction';
import { snackboxOpen } from '../redux/actions/snackboxAction';
import services from '../services';
import { CookiesBanner } from '../components/templates';
import { Snackbox } from '../components/molecules';
import { useNetwork } from '../hooks/useNetwork';
import { translate } from './i18n';
import MetaPixel from '../utils/metaPixel';
import Fingerprint from '../services/Fingerprint';
import AppRouter from '../routes/AppRouter';
import PageLoader from '../components/atoms/pageLoader/PageLoader';
import { Helmet } from 'react-helmet';
import Proptypes from 'prop-types';
import { AppServiceWorker } from 'react-service-worker';
import { Modal } from '../components/atoms';
import SelectCountry from '../components/molecules/selectCountry/selectCountry';

function App({ appServiceWorker }) {
  const dispatch = useDispatch();
  const { fingerprint, isAuthenticated, setAnonymousUserSuccess } = useSelector(
    (state) => state.auth,
  );
  const { websiteLanguage, websiteCountry } = useSelector(
    (state) => state.user,
  );
  const [cookies, setCookie] = useCookies(['consentCookies']);
  const [open, setOpen] = useState(!websiteCountry ? true : false);
  const [selectedIndex, setSelectedIndex] = useState();

  const { isOnline } = useNetwork();

  useEffect(() => {
    dispatch(checkAuth());
    dispatch(fetchTopFiveCategories());
    appServiceWorker.onInstalled(() =>
      dispatch(
        snackboxOpen(translate('serviceWorker.installedMessage'), 'success'),
      ),
    );
    appServiceWorker.onUpdateFound(() =>
      dispatch(snackboxOpen(translate('serviceWorker.updateMessage'), 'info')),
    );
  }, []);

  useEffect(() => {
    if (!fingerprint) {
      Fingerprint.getFingerprint().then((uuid) => {
        dispatch(setFingerprint(uuid));
      });
    }
  }, [fingerprint]);

  useEffect(() => {
    if (!services.authService.getLocalAccessToken()) {
      dispatch(anonymousUser(websiteCountry));
    }
  }, [services.authService.getLocalAccessToken()]);

  let clientId = null;

  window.ga((tracker) => {
    clientId = tracker.get('clientId');
  });

  useEffect(() => {
    if (
      clientId &&
      (isAuthenticated || (!isAuthenticated && setAnonymousUserSuccess))
    ) {
      services.updateInstanceService.putUpdateInstance({
        deviceId: fingerprint,
        platform: 'web',
        instanceId: clientId,
      });
    }
  }, [isAuthenticated, setAnonymousUserSuccess, clientId]);

  if (!isOnline) {
    dispatch(
      snackboxOpen(translate('httpErrorMsg.connectionError'), 'failure'),
    );
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: websiteLanguage }} />
      <Snackbox timeout={5000} />
      <MetaPixel />
      {
        <Modal
          show={open}
          content={
            <SelectCountry {...{ setOpen, selectedIndex, setSelectedIndex }} />
          }
          className="custom-modal-country"
        />
      }
      {!cookies.consentCookies && <CookiesBanner setCookie={setCookie} />}
      <RouterProvider router={AppRouter} fallbackElement={<PageLoader />} />
    </>
  );
}

App.propTypes = {
  appServiceWorker: Proptypes.instanceOf(AppServiceWorker),
};

export default App;
